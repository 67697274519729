import { render, staticRenderFns } from "./FileInput.vue?vue&type=template&id=2da1460b&scoped=true&"
import script from "./FileInput.vue?vue&type=script&lang=js&"
export * from "./FileInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2da1460b",
  null
  
)

export default component.exports