<template>
  <v-text-field @input="setValue" :value="price_input ? $global.NumberWithCommas((+value).toFixed(2)) : value"
    :solo="solo" outlined :reverse="reverse" :dense="dense" :name="name" :autofocus="autofocus"  
    @keydown.enter="keydownEnter()" @keydown="keydown" :class="classes" :disabled="disabled" :readonly="readonly"
    :rules="required == true ? acceptZero ? $global.requiredNumberAcceptZeroRule : $global.requiredNumberRule
      : $global.notRequiredRule" :loading="isLoading" rounded-md :color="color" :background-color="background"  :append-icon="icon"
    @focusin="() => price_input = false" @focusout="price_input = true"
    :hide-details="hideDetails == true ? 'auto' : false" :type="price_input ? 'text' : 'number'"
    :placeholder="$i18n.t(placeholder)" :error-messages="$i18n.t(errorMessage)" :persistent-hint="hint ? true : false" :hint="$i18n.t(hint)">
    <template v-slot:label>
      <label v-if="inputDesign == 'default'">
        {{ $t(label) }}
        <span class="error--text" v-if="required == true && label">*</span>
      </label>
    </template>
    <template v-slot:append-outer>
      <slot name="append-outer"></slot>
    </template>
    <template v-slot:append>
      <slot name="append"></slot>
    </template>
    <template v-slot:prepend-inner>
      <slot name="prepend-inner"></slot>
    </template>
    <template v-slot:prepend>
      <slot name="prepend"></slot>
    </template>
  </v-text-field>
</template>


<script>
export default {
name: "FloatInput",
props: {
  name: { default: null },
  label: { default: null },
  type: { default: null },
  value: { default: null },
  required: { default: false },
  readonly: { default: false },
  autofocus: { default: false },
  acceptZero: { default: false },
  dense: { default: true },
  onChange: { type: Function },
  focusout: { default: Function },
  focusin: { default: Function },
  isLoading: { default: null },
  disabled: { default: null },
  color: { default: "primary" },
  background: { default: null },
  icon: { default: "" },
  hideDetails: { default: false },
  inputDesign: { default: false },
  clearable: { default: false },
  hideImage: { default: false },
  onClickOuter: { type: Function },
  keydownEnter: { type: Function },
  tabKey: { default: Function },
  placeholder: { default: null },
  solo: { default: false },
  classes: { default: null },
  dir: { default: null },
  errorMessage: { default: null },
    hint: { default: null },
  reverse: { default: false }
},
data: () => ({
  price_input: true,
}),
computed: {
  input: {
    get() {
      return this.value || 0;
    },
    set(val) {
      this.$emit("input", +val);
    },
  },
  floatNumber() {
    return this.$global.NumberWithCommas(this.input.toFixed(2));
  }
},
watch: {},
mounted() {
},
beforeDestroy: function () {
},
methods: {
  keydown(e) {
    if (e.code == "Tab") {
      this.tabKey(true)
    }
  },
  focusinInput() {
    console.log('ssss');
    if (!this.readonly) {
      this.focusin()
    }
  },
  setValue(value) {
    this.input = +value
  }
},
components: {}
};
</script>



<style  lang="scss">
.v-price-active.v-input input:not(.inputPrice) {
opacity: 1 !important;
}

.v-price.v-input input {
opacity: 0;
}

.v-price .v-input__prepend-outer {
margin-left: 0;
}

.inputPrice {
position: absolute;
z-index: 2;
left: 10px;
top: 2px;

&.active {
  opacity: 1 !important;
}
}

.v-application--is-rtl {
.inputPrice {
  left: unset;
  right: 10px;
}
}
</style>
