<template>
   <section>
      <v-container fluid class="pa-5">
         <v-row align="center">
            <v-col cols="6" class="text-h6 font-weight-bold">{{ $t('the requests') }}</v-col>
            <v-col cols="6" class="text-h6 font-weight-bold text-end">
               <!-- <v-btn depressed @click="exitDialog = true"
                  class="my-2 mx-2 rounded-lg" text plain :height="40" color="error">
                  {{ $t('cancel') }}
               </v-btn> -->
               <v-btn depressed @click="save" :disabled="!valid" :min-width="148" class="my-2 mx-2 rounded-lg white--text"
                  :height="40" color="success">
                  {{ $t('save') }}</v-btn>
            </v-col>
         </v-row>
         <v-row align="center">
            <v-scroll-x-transition>
               <v-col cols="12" class="d-flex align-start text-body-2">
                  <v-icon color="info">mdi-alert-circle-outline</v-icon>
                  <div class="mx-2">
                     {{ $t('request message1') }}
                     {{ $t('request message2') }}
                     {{ $t('request message3') }}
                     {{ $t('request message4') }}
                  </div>
               </v-col>
            </v-scroll-x-transition>
         </v-row>

         <!-- Form -->
         <v-form ref="form" v-model="valid">
            <v-card class="transparent py-5 px-1" elevation="0">
               <v-row align="center">

                  <v-col cols="12" class="pa-0 mt-5">
                     <v-divider></v-divider>
                  </v-col>

                  <!-- early leave -->
                  <v-col cols="6" class="font-weight-bold">
                     {{ $t('early leave') }}
                  </v-col>
                  <v-col cols="6" class="d-flex align-center justify-end">
                     <GenericInput type="select" :value="form.early_leave"
                        @input="form.early_leave = $event" icon="mdi-chevron-down" selected_prop="id"
                        label="Final approval" :lookups="calendar" selected_label="name" :required="true"
                        :isLoading="pageData.isLoading" :cols="[12, 9, 9]">
                     </GenericInput>
                  </v-col>
                  <!--End early leave -->

                  <v-col cols="12" class="pa-0">
                     <v-divider></v-divider>
                  </v-col>

                  <!-- delay request -->
                  <v-col cols="6" class="font-weight-bold">
                     {{ $t('delay request') }}
                  </v-col>
                  <v-col cols="6" class="d-flex align-center justify-end">
                     <GenericInput type="select" :value="form.delay_request"
                        @input="form.delay_request = $event" icon="mdi-chevron-down" selected_prop="id"
                        label="Final approval" :lookups="calendar" selected_label="name" :required="true"
                        :isLoading="pageData.isLoading" :cols="[12, 9, 9]">
                     </GenericInput>
                  </v-col>
                  <!--End delay request -->

                  <v-col cols="12" class="pa-0">
                     <v-divider></v-divider>
                  </v-col>

                  <!-- vacation request -->
                  <v-col cols="6" class="font-weight-bold">
                     {{ $t('vacation request') }}
                  </v-col>
                  <v-col cols="6" class="d-flex align-center justify-end">
                     <GenericInput type="select" :value="form.vacation_request"
                        @input="form.vacation_request = $event" icon="mdi-chevron-down" selected_prop="id"
                        label="Final approval" :lookups="calendar" selected_label="name" :required="true"
                        :isLoading="pageData.isLoading" :cols="[12, 9, 9]">
                     </GenericInput>
                  </v-col>
                  <!-- End vacation request -->


                  <v-col cols="12" class="pa-0">
                     <v-divider></v-divider>
                  </v-col>

                  <!-- request increase -->
                  <v-col cols="6" class="font-weight-bold">
                     {{ $t('request increase') }}
                  </v-col>
                  <v-col cols="6" class="d-flex align-center justify-end">
                     <GenericInput type="select" :value="form.request_increase"
                        @input="form.request_increase = $event" icon="mdi-chevron-down" selected_prop="id"
                        label="Final approval" :lookups="calendar" selected_label="name" :required="true"
                        :isLoading="pageData.isLoading" :cols="[12, 9, 9]">
                     </GenericInput>
                  </v-col>
                  <!-- End request increase -->

                  <v-col cols="12" class="pa-0">
                     <v-divider></v-divider>
                  </v-col>

                  <!-- allowance request -->
                  <v-col cols="6" class="font-weight-bold">
                     {{ $t('allowance request') }}
                  </v-col>
                  <v-col cols="6" class="d-flex align-center justify-end">
                     <GenericInput type="select" :value="form.allowance_request"
                        @input="form.allowance_request = $event" icon="mdi-chevron-down" selected_prop="id"
                        label="Final approval" :lookups="calendar" selected_label="name" :required="true"
                        :isLoading="pageData.isLoading" :cols="[12, 9, 9]">
                     </GenericInput>
                  </v-col>
                  <!-- End allowance request -->
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>

export default {
   name: "RequestsSettings",

   data: () => ({
      pageData: {
         screen_code: "01-007",
         url: null,
         controlRoute: "/settings_hr/requests_settings",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      viewForm: false,
      summery: [],
      valid: false,
      employees: [],
      vacations: [],
      employee_data: [],
      calendar: [],
      openDialog: false,
      form: {
         early_leave: null,
         delay_request: null,
         vacation_request: null,
         request_increase: null,
         allowance_request: null,
      },
      exitDialog: false,
   }),
   components: {
   },
   computed: {
   },
   mounted() {
      this.calendar = [
         {
            id: 1,
            name: 'المستوي الاول ( مجلس الإدارة )'
         },
         {
            id: 2,
            name: 'المستوي الثاني ( مدير الإدارة )'
         },
         {
            id: 3,
            name: 'المستوي الثالث ( مدير القسم )   '
         }
      ]
      this.pageData.isLoading = false;
      this.pageMainData()
      // this.getData()

   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id && !this.openProfileForm) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`vacation_action/${this.$route.params.id}`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.employees = response.data.employees;
                     this.vacations = response.data.vacations;
                     console.log("response", response);
                     // this.form = response.data.data;
                     !this.openProfileForm ? this.form = response.data.data : ""

                     if (this.openProfileForm) {
                        this.form.employee_id = +this.$route.params.id
                     }

                     this.viewSummery = true;
                     // this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`vacation_action/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.employees = response.data.employees;
                     this.vacations = response.data.vacations;

                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`vacation_action/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`vacation_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      save_employee() {
         this.form.amount = this.form.increase_type == 2 ? this.$global.ConvertFromPercentage(this.increase_value, this.form.salary_fixed) : this.increase_value
         this.form.salary_after_increase = this.totalAmount
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.$api.POST_METHOD(`vacation_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     // this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                     this.closeProfileForm()
                     this.refreshTable()
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`vacation_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`vacation_action/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
   watch: {
      "form.employee_id"() {
         this.pageData.isLoading = true;
         this.$api.GET_METHOD(`get_employee_vacation/${this.form.employee_id}`).then((response) => {
            if (response.check) {
               console.log("get_employee_vacation", response.data);
               this.pageData.isLoading = false;
               this.employee_data = response.data.vacation_balance;
               this.vacation_summary_name = this.$global.FilterArrayOfObjectElement(this.employee_data, 'id',
                  this.form.vacation_system_d_id, 'name')
            }
         })
      },
      valid() {
         if (this.openProfileForm) {
            return this.saveEmployeeData(this.form, this.valid)
         }
      }
   }
   ,
   props: {
      openProfileForm: { default: false },
      saveEmployeeData: { type: Function }
   }
};
</script>

<style lang="scss" scoped>
div#divider_ver {
   height: 200px !important;
}
</style>
