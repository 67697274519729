// Reports Routes:-


import Request from "@/views/HRModule/Requests/Request/Request.vue"


import FollowRequest from "@/views/HRModule/Requests/FollowRequest/FollowRequest.vue"
// Routes
export const RequestsRoutes = [
  // request
  {
    path: "/requests/request",
    name: "Request",
    component: Request,
    meta: {
      screen_code: "09-010",
    },
  },


  // Follow Request

  {
    path: "/requests/follow_request",
    name: "FollowRequest",
    component: FollowRequest,
    meta: {
      screen_code: "09-011",
    },
  },


];