<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" actionBtnText="sort"
            :actionBtnClick="openSort" :actionBtn="true" :actionBtnValid="true" actionBtnColor="white"
            actionBtnClass="secondary--text" actionBtnIcon="mdi-sort-numeric-ascending" />
         <!-- End Of Header -->
         <v-row>
            <v-col cols="12" sm="12">
               <DataTableVue :isLoading="pageData.isLoading" :options="pageData.options" :data="pageData.rows"
                  :header="pageData.tableHeader" :backValueMethod="setClickRow" :editValueMethod="edit"
                  :deleteValueMethod="deleteMethod" deleteProperty="sub_id" :restoreValueMethod="restoreMethod"
                  :pageData="pageData"></DataTableVue>
            </v-col>
         </v-row>
         <v-row v-if="!pageData.isLoading">
            <v-col cols="12" sm="12">
               <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
                  :isLoading="pageData.isLoading"></Pagination>
            </v-col>
         </v-row>

         <SubMenusRearrangementDialogVue v-if="pageData.rearrangementDialog == true" :dialog="pageData.rearrangementDialog"
            :backValueMethod="rearrangement" :isLoading="pageData.isLoading">
         </SubMenusRearrangementDialogVue>
      </v-container>
   </section>
</template>



<script>
import DataTableVue from "@/components/DataTable.vue";
import Pagination from "@/components/ui/Pagination.vue";
import SubMenusRearrangementDialogVue from "./SubMenusRearrangementDialog.vue";
import EntityHeader from "@/components/ui/EntityHeader.vue";
export default {
   name: "SubMenusEntity",

   data: () => ({
      pageData: {
         screen_code: "01-003",
         url: null,
         controlRoute: "main/screen_sub-control",
         addControlRouteName: "SubMenusControl",
         editControlRouteName: "SubMenusControlEdit",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isTrashed: 1,
         rowsNumber: 15,
         tableHeader: [],
         search: '',
         rows: [],
         options: {},
         page: 1,
         pagination: {},
         rearrangementDialog: false,
         queryParam: '?'
      },
   }),
   components: {
      DataTableVue,
      Pagination,
      SubMenusRearrangementDialogVue,
      EntityHeader
   },
   computed: {},
   watch: {
      $route() {
         this.pageData.queryParam = '?';
         this.queryParams();
         this.pageMainData();
         this.getData();
      },
   },
   mounted() {
      this.queryParams();
      this.pageMainData();
      this.getData();
   },
   methods: {
      queryParams() {
         this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
         this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
         this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
         this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
         this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
      },
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.entityName = this.$store.state.activeScreen.sub_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.tableHeader = [
            {
               text: '#',
               sortable: true,
               value: 'screen_code',
            },
            {
               text: this.$i18n.t('main image'),
               sortable: true,
               value: 'main_image',
            },
            {
               text: this.$i18n.t('main title'),
               sortable: true,
               value: 'main_title',
            },
            {
               text: this.$i18n.t('category title'),
               sortable: true,
               value: 'cat_title',
            },
            {
               text: this.$i18n.t('title'),
               sortable: true,
               value: 'sub_title',
            },

            { text: this.$i18n.t('actions'), value: 'actions', sortable: false },

         ]
         this.pageData.options = {
            print: this.$global.CheckAction(this.pageData.screen_code, 5),
            delete: this.$global.CheckAction(this.pageData.screen_code, 4),
            edit: this.$global.CheckAction(this.pageData.screen_code, 3),
            restore: this.$global.CheckAction(this.pageData.screen_code, 4),
            view: this.$global.CheckAction(this.pageData.screen_code, 1),
            switch: false,
            selectRow: false,
            sortBy: 'id',
            tableHeader: false,
            searchInput: false,
            printButton: false,
            handleClickRow: false,
         }
      },
      getSearch(word) {
         this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${1}&word=${word || ''}`
         this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
      },
      getData() {
         if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
            this.pageData.isLoading = true;
            this.$api.GET_METHOD(`screen_sub${this.pageData.queryParam}`).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  this.pageData.entityName = this.pageData.isTrashed == 0 ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title : this.$store.state.activeScreen.sub_title;
                  this.pageData.rows = response.data.items.data;
                  this.pageData.page = response.data.items.current_page;
                  this.pageData.pagination = {
                     page: response.data.items.current_page,
                     totalPages: response.data.items.last_page,
                     per_page: response.data.items.per_page,
                     totalRows: response.data.items.total,
                  }

               }
            })

         }
         else {
            this.$router.push('/')
         }

      },
      openSort() {
         this.pageData.rearrangementDialog = true
      },
      changePage(page, limit) {
         this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
         this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
      },
      deleteMethod(id) {
         console.log(id);
         this.$api.POST_METHOD(`screen_sub/${id}`, { _method: 'delete' }).then((response) => {
            if (response.check) {
               this.getData()
            }
         })
      },
      restoreMethod(row) {
         this.$api.POST_METHOD(`screen_sub_toggle_active/${row.sub_id}`, null).then((response) => {
            if (response.check) {
               this.getData()
            }
         })
      },
      setClickRow() {
         // this.$router.push(`/main/screen_sub/${row.sub_id}`)
      },
      // edit(row) {
      //    this.$router.push(`/${this.pageData.controlRoute}/` + row.sub_id)
      // },
      edit(row) {
         this.$router.push({
            name: this.pageData.editControlRouteName,
            params: {
               page: this.pageData.page || 1,
               isTrashed: this.pageData.isTrashed || 1,
               rowsNumber: this.pageData.rowsNumber || 10,
               word: this.pageData.search || '',
               id: row.sub_id || 0
            },
         });
      },
      rearrangement(newDataOrder, status) {
         if (status == true) {
            console.log("rearrangement data", newDataOrder);
            this.$api.POST_METHOD(`screen_sub_sort`, { items: newDataOrder }).then((response) => {
               if (response.check) {
                  this.getData()
                  this.pageData.rearrangementDialog = false
               }
            })
         } else {
            this.pageData.rearrangementDialog = false
         }

      }
   },
};
</script>
