<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader v-if="!openProfileForm" :pageData="pageData" :form="form" :save="save" :valid="valid"
            :deleteMethod="deleteMethod" :actionBtn="false" actionBtnClass="light text-decoration-underline blue5--text"
            :actionBtnClick="() => attachmentDialog = true" :actionBtnValid="true" actionBtnText="attachments"
            :editForm="pageData.isEdit && !viewForm" :viewForm="() => viewForm = true">
            <template v-slot:leftSide>
               <v-btn @click="printMethod" v-if="pageData.isEdit" :loading="pageData.printLoading.loading" color="primary"
                  depressed height="40">{{
                     $t('print') }}</v-btn>
            </template>
         </ControlHeader>
         <!-- End Of Header -->

         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading"
            v-if="pageData.isEdit && !viewForm && !openProfileForm" />
         <!-- End of Summery -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="mt-5" v-if="viewForm || openProfileForm">
            <v-card class="overflow-hidden py-5 px-1 transparent" elevation="0">
               <v-row>
                  <GenericInput v-if="!pageData.isEdit" type="date" :value="form.date = $global.GetCurrentDate()"
                     @input="form.date = $event" label="date" :required="true" :disabled="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="select" v-if="!openProfileForm" :value="form.employee_id"
                     @input="form.employee_id = $event" label="choose the employee" selected_prop="id" :lookups="employees"
                     selected_label="employee_name" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 4]">
                     <template v-slot:append>
                        <v-icon>mdi-magnify</v-icon>
                     </template>
                  </GenericInput>

                  <GenericInput type="float" :value="Math.abs(form.covenant_amount)" @input="form.covenant_amount = $event"
                     label="value of the covenant" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="arabic name"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="english name"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="textarea" :value="form.notes" @input="form.notes = $event" label="details"
                     :rowsNumber="6" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 12]">
                  </GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>

      <CovenantPrint :printAction="() => print = false" :print="print" :printData="printData"
      :printStaticData="printStaticData" />
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlSummery from "@/components/ui/ControlSummery.vue";
import CovenantPrint from "./CovenantPrint.vue";
export default {
   name: "CovenantControl",

   data: () => ({
      pageData: {
         screen_code: "09-018",
         url: null,
         controlRoute: "/transactions/covenant_transaction-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
         printLoading: {
            loading: false,
            id: null,
            key: 'id'
         },
      },
      viewForm: false,
      summery: [],
      employees: [],
      valid: false,
      tab: 0,
      sales_areas: [],
      sales_agents: [],
      account_trees: [],
      currencies: [],
      countries: [],
      cities: [],
      openDialog: false,
      attachmentDialog: false,
      employee_profile_id: "",
      form: {
         date: null,
         employee_id: null,
         name_en: null,
         name_ar: null,
         notes: null,
         covenant_amount: null,
      },
      printData: [],
      printStaticData: Object,
      print: false,
      printResponse: {},
   }),
   components: {
    GenericInput,
    ControlHeader,
    ControlSummery,
    CovenantPrint
},
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id && !this.openProfileForm) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`covenant/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.printResponse = response.data;
                     this.employees = response.data.employees;
                     // this.form = response.data.data;
                     !this.openProfileForm ? this.form = response.data.data : ""

                     if (this.openProfileForm) {
                        this.form.employee_id = +this.$route.params.id
                     }

                     this.summery = [
                        {
                           label: 'the employee',
                           value: response.data.data.name_employees || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'date',
                           value: response.data.data.date || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'covenant name',
                           value: response.data.data.name_ar || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'english name',
                           value: response.data.data.name_en || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'covenant value',
                           value: response.data.data.covenant_amount || 0,
                           type: 'float',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        },

                        {
                           label: 'details',
                           value: response.data.data.notes || '-',
                           class: '',
                           cols: null, sm: null, md: 6, lg: 6, xl: 6,
                        }
                     ]
                     this.viewSummery = true;
                     // this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`covenant/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.employees = response.data.employees;

                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`covenant/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`covenant`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`covenant/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      printMethod() {
         this.pageData.printLoading.loading = true;
         this.pageData.printLoading.id = this.$route.params.id;
         this.printData = [this.printResponse.data];
         this.printStaticData.master = this.printResponse.data;
         this.print = true;
         setTimeout(() => {
            this.printData = [];
            this.printStaticData = Object;
            this.print = false;
            this.pageData.printLoading.loading = false;
         }, 1000);
      },
   },
   watch: {
      valid() {
         if (this.openProfileForm) {
            return this.saveEmployeeData(this.form, this.valid)
         }
      }
   },
   props: {
      openProfileForm: { default: false },
      saveEmployeeData: { type: Function }
   }
};
</script>
