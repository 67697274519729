<template>
  <v-container>
    <h1 class="text-center">404 Page</h1>

    <v-btn color="primary" @click="$router.go(-1)" class="mt-2" elevation="0">{{ $t('back previous page') }}</v-btn>
  </v-container>
</template>

<script>
export default {
  name: "Error404",
};
</script>

<style scoped lang="scss">

</style>