<template>
    <section class="scroll-container">
        <v-container fluid>
            <div class="container_table">
                <table>
                    <thead>
                        <tr>
                            <td rowspan="2">{{ $t('Employee number') }}</td>
                            <td colspan="3" rowspan="2">{{ $t('Employee name') }}</td>
                            <td colspan="2" rowspan="2">{{ $t('The Basic salary') }}</td>
                            <td rowspan="2">{{ $t('workdays') }}</td>
                            <td rowspan="2">{{ $t('Housing Allowance') }}</td>
                            <td v-if="employeeData?.allowances?.length" :colspan="employeeData?.allowances?.length">{{
                                $t('allowance') }}</td>
                            <td colspan="2" rowspan="2">{{ $t('total Salary') }}</td>
                            <td colspan="2">{{ $t('Deductions') }}</td>
                            <td colspan="2" rowspan="2">{{ $t('Total Deductions') }}</td>
                            <td colspan="2" rowspan="2">{{ $t('Net Salary') }}</td>
                        </tr>
                        <tr style="background-color:  transparent !important ;">
                            <td v-for="item in employeeData?.allowances" :key="item.salary_effect_id">{{
                                item.salary_effect_name }}</td>
                            <td>{{ $t('advance') }}</td>
                            <td>{{ $t('deduction') }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="result_row">
                            <!-- <td>1</td> -->
                            <td>{{ employeeData?.id }}</td>
                            <td colspan="3">{{ employeeData?.employee_name || "-" }}</td>
                            <td colspan="2" class="bak_effect">{{ employeeData?.fixed || 0 | float }}</td>
                            <td>{{ employeeData?.working_days || "-" }}</td>
                            <td>{{ employeeData?.housing_allowance || "-" }}</td>
                            <td v-for="(item) in employeeData?.allowances" :key="item.salary_effect_id">{{ item.amount || 0
                                | float }}</td>
                            <td colspan="2" class="bak_effect">{{ employeeData?.amount_with_additions || 0 | float }}</td>
                            <td>{{ employeeData?.advance_count || 0 | float }}</td>
                            <td>{{ employeeData?.deduction_count || 0 | float }}</td>
                            <td colspan="2" class="bak_effect">{{ employeeData?.total_deductions || 0 | float }}</td>
                            <td colspan="2" class="bak_effect">{{ employeeData?.total_amount || 0 | float }}</td>
                        </tr>
                        <tr class="total">
                            <td colspan="4">{{ $t('the total') }}</td>
                            <td colspan="4" class="bak_effect">{{ employeeData?.total_amount || 0 | float }}</td>
                            <td v-for="(item, i) in employeeData?.allowances" :key="item + i">{{ '-' }}</td>
                            <td colspan="2" class="bak_effect">{{ '-' }}</td>
                            <td>{{ '-' }}</td>
                            <td>{{ '-' }}</td>
                            <td colspan="2" class="bak_effect">{{ '-' }}</td>
                            <td colspan="2" class="bak_effect">{{ '-' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </v-container>
    </section>
</template>

<script>

export default {
    name: "SalaryReportTable",

    data: () => ({
        allowanceArray: [],
        deductionsArray: []
    }),
    props: {
        employeeData: { default: Object },
    },
    components: {

    },

    computed: {


    },
    watch: {

    },
    mounted() {
    },
    methods: {

    },
};
</script>

<style lang="scss" scoped>
.container_table {
    thead td {
        background-color: #dcdcdc !important;
    }

    tr:nth-child(even) {
        background-color: white;
    }

    .bak_effect {
        background-color: #dcdcdc;
    }

    table {
        border: 2px solid black !important;
    }

    table {
        text-align: center;
        margin: auto;
        width: 100%;
        border-spacing: 0;
        overflow: hidden;
    }

    td {
        padding: 2px;
        border: 0.1px solid #000000ba !important;
    }

    tbody .result_row:nth-child(1) td {
        border-top: 3px solid black !important;
    }

    @media print {

        html,
        body {
            width: 210mm;
            height: 297mm;
        }
    }
}

.scroll-container {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
}</style>