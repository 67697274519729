<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader
            :pageData="pageData"
            :form="form"
            :save="save"
            :valid="valid"
            :deleteMethod="deleteMethod"
         />
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <GenericInput
                     type="text"
                     :value="form.screen_cat_title_en"
                     @input="form.screen_cat_title_en = $event"
                     label="name_en"
                     :required="true"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]"
                  ></GenericInput>
                  <GenericInput
                     type="text"
                     :value="form.screen_cat_title_ar"
                     @input="form.screen_cat_title_ar = $event"
                     label="name_ar"
                     :required="true"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]"
                  ></GenericInput>
                  <GenericInput
                     type="select"
                     :lookups="main_screens"
                     :value="form.screen_main_id"
                     @input="form.screen_main_id = $event"
                     label="main screen"
                     :multi="false"
                     :required="true"
                     selected_label="title"
                     selected_prop="id"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]"
                  ></GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>

import ControlHeader from "../../../components/ui/ControlHeader.vue";
import GenericInput from '../../../components/ui/GenericInput.vue';

export default {
   name: "CategoriesMenuControlVue",

   data: () => ({
      pageData: {
         screen_code: "01-002",
         url: null,
         controlRoute: "/main/screen_cat-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },

      valid: false,
      main_screens: [],
      form: {
         screen_cat_title_en: null,
         screen_cat_title_ar: null,
         screen_main_id: null,
      },
   }),
   components: {
      GenericInput,
      ControlHeader
   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t('edit') + ' ' + this.$i18n.t('category') : this.$i18n.t('add category');
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`screen_cat/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  if (response.check) {
                     this.pageData.isLoading = false;
                     console.log("response", response);
                     this.form = response.data.data;
                     this.main_screens = response.data.mains;
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`screen_cat/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.main_screens = response.data.mains;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`screen_cat/${this.form.screen_cat_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push('/main/screen_cat')
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`screen_cat`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push('/main/screen_cat')
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`screen_cat/${this.form.screen_cat_id}`, { _method: 'delete' }).then(() => {
               this.$router.push('/main/screen_cat');
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
