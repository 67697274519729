<template>
  <v-container fluid class="">
      <h1>Forget Password</h1>
  </v-container>
</template>

<script>
export default {
  name: "ForgetPasswordPage",
  computed: {
  
  },
  watch: {

  },
  mounted() {

  },
  components: {
  },
  data: () => ({
   
  }),

  methods: {

  },

};
</script>

<style scoped lang="scss">

</style>
