<template>
  <v-img :src="src" :lazy-src="loading" :width="width" :height="height" :max-width="max_width" :max-height="max_height"
    :class="classes" :aspect-ratio="aspect" contain>
    <template v-slot:placeholder>
      <img :src="error" />
    </template>
  </v-img>
</template>


<script>
export default {
  name: "LazyImage",
  props: {
    max_width: { default: "" },
    max_height: { default: "" },
    width: { default: "" },
    height: { default: "" },
    aspect: { default: null },
    classes: { default: null },
    src: { default: null },
    loading: { default: null },
    error: { default: null },
  },
  computed: {

  }


};
</script>



<style scoped lang="scss">

</style>
