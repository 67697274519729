<template>
   <v-dialog v-model="openDialog" persistent width="1340">
      <v-card min-height="765">
         <v-card-actions class="sticky">
            <v-card-text class="font-weight-bold text-h6 text-capitalize secondary--text">
               <ExportBtn :exportData="pageData.rows" v-if="pageData.rows.length" :header="pageData.tableHeader"
                  :footerData="[]" :groupHeader="[]" :attachmentFileName="pageData.entityName"
                  :printTitle="pageData.entityName" :classes="'primary'" :width="106">
                  <template v-slot:td="{ header, row }">
                     <div class="input_container" v-if="header.key === 'payment_method'">
                        <div>{{ $global.FilterArrayOfObjectElement(payment_method, "id", row.payment_method, 'name') }}
                        </div>
                     </div>
                  </template>
               </ExportBtn>
            </v-card-text>

            <v-btn color="red15" depressed :width="106" height="48" @click="closeDialog"
               class="font-weight-bold rounded-lg  red4--text" :loading="false">
               {{ $t('back') }}
            </v-btn>
         </v-card-actions>
         <v-card-text>
            <v-row>
               <v-col cols="12" sm="12">
                  <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
                     :option="pageData.options" :pageData="pageData">
                     <template v-slot:td="{ header, row }">
                        <div class="input_container" v-if="header.key === 'payment_method'">
                           <div>{{ $global.FilterArrayOfObjectElement(payment_method, "id", row.payment_method, 'name') }}
                           </div>
                        </div>
                     </template>
                  </DynamicTable>
               </v-col>
            </v-row>

         </v-card-text>
      </v-card>
   </v-dialog>
</template>

<script>
import DynamicTable from '@/components/DynamicTable.vue';
export default {
   name: "SalaryCalculationShowDialog",
   data: () => ({
      pageData: {
         screen_code: "09-019",
         url: null,
         controlRoute: "transactions/salary_calculator_transaction-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isTrashed: 1,
         rowsNumber: 15,
         tableHeader: [],
         search: "",
         rows: [],
         options: {},
         page: 1,
         pagination: {},
         queryParam: '?',

      },
      apiUrl: "",
      payment_method: []
   }),
   props: {
      openDialog: { default: false },
      closeDialog: { type: Function },
      data: { default: Object },
   },
   computed: {},
   mounted() {
   },
   watch: {
      openDialog() {
         if (this.openDialog) {
            this.handelTable()
            this.getData();
         }
      }
   },
   methods: {
      handelTable() {
         this.pageData.tableHeader = [
            { text: "#", key: "id", type: 'text', classes: "" },
            { text: "employee name", key: "employee_name", type: 'text', classes: "" },
            { text: "basic salary", key: "salary_fixed", type: 'float', classes: "" },
            { text: "total deductions", key: "total_deduction_actions", type: 'float', classes: "" },
            { text: "total benefits", key: "total_benefit_actions", type: 'float', classes: "" },
            { text: "the total amount", key: "total_amount", type: 'float', classes: "" },
            { text: "exchange type", key: "payment_method", type: 'slot', classes: "" },
         ];
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
         this.payment_method = [
            {
               id: 1,
               name: this.$i18n.t('Bank')
            },
            {
               id: 2,
               name: this.$i18n.t('cash')
            },
            {
               id: 3,
               name: this.$i18n.t('the transfer')
            }
         ]
      },
      getData() {
         if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
            this.pageData.isLoading = true;
            this.$api.GET_METHOD(`payment_salary/${this.data.id}`)
               .then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.pageData.rows = response.data.details;
                  }
               });
         }
      },
   },

   components: {
      DynamicTable,
      //  Pagination 
   }
}
</script>

<style lang="scss" scoped>
.sticky {
   position: sticky;
   z-index: 999;
   background: white;
   top: 0px;
}
</style>
