import Vue from 'vue';

// Main Components
import DynamicTable from "@/components/DynamicTable.vue";
import DataTable from "@/components/DataTable.vue";
import Pagination from "@/components/ui/Pagination.vue";
import EntityHeader from "@/components/ui/EntityHeader.vue";
import ControlHeader from "@/components/ui/ControlHeader.vue";
import ControlSummery from "@/components/ui/ControlSummery.vue";

// Inputs
import GenericInput from "@/components/ui/GenericInput.vue";
import FloatInput from "@/components/inputs/FloatInput.vue";
import DropZone from "@/components/inputs/DropZone.vue";
import AutoCompleteInput from "@/components/inputs/AutoCompleteInput.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import NumberInput from "@/components/inputs/NumberInput.vue";
import DateInput from "@/components/inputs/DateInput.vue";
import TimeInput from "@/components/inputs/TimeInput.vue";
import EmailInput from "@/components/inputs/EmailInput.vue";
import TextAreaInput from "@/components/inputs/TextAreaInput.vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import MobileInput from "@/components/inputs/MobileInput.vue";
import FileInput from "@/components/inputs/FileInput.vue";
import CheckBoxInput from "@/components/inputs/CheckBoxInput.vue";
import RadioInput from "@/components/inputs/RadioInput.vue";
import SwitchInput from "@/components/inputs/SwitchInput.vue";
import TextEditor from "@/components/inputs/TextEditor.vue";
import PercentageInput from "@/components/inputs/PercentageInput.vue";

// Dialogs
import AttachmentDialog from "@/components/modals/AttachmentDialog.vue";
import AddLockupsDialog from '@/components/modals/AddLockupsDialog.vue';
import CustomModal from '@/components/modals/CustomModal.vue';

// UI
import PrintPDF from "@/components/PrintPDF.vue";
import ExportBtn from "@/components/ui/ExportBtn.vue";
import PrintBtn from "@/components/ui/PrintBtn.vue";
import PrintInvoice from "@/components/ui/PrintInvoice.vue";
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import DeleteConfirmation from '@/components/modals/DeleteConfirmation.vue';





// Components Register

Vue.component("DynamicTable", DynamicTable);
Vue.component("DataTable", DataTable);
Vue.component("Pagination", Pagination);
Vue.component("EntityHeader", EntityHeader);
Vue.component("ControlHeader", ControlHeader);
Vue.component("ControlSummery", ControlSummery);

Vue.component("GenericInput", GenericInput);
Vue.component("FloatInput", FloatInput);
Vue.component("DropZone", DropZone);
Vue.component("AutoCompleteInput", AutoCompleteInput);
Vue.component("TextInput", TextInput);
Vue.component("NumberInput", NumberInput);
Vue.component("DateInput", DateInput);
Vue.component("TimeInput", TimeInput);
Vue.component("EmailInput", EmailInput);
Vue.component("TextAreaInput", TextAreaInput);
Vue.component("PasswordInput", PasswordInput);
Vue.component("MobileInput", MobileInput);
Vue.component("FileInput", FileInput);
Vue.component("CheckBoxInput", CheckBoxInput);
Vue.component("RadioInput", RadioInput);
Vue.component("SwitchInput", SwitchInput);
Vue.component("TextEditor", TextEditor);
Vue.component("PercentageInput", PercentageInput);

Vue.component("AttachmentDialog", AttachmentDialog);
Vue.component("AddLockupsDialog", AddLockupsDialog);
Vue.component("CustomModal", CustomModal);

Vue.component("AnimatedLoading", AnimatedLoading);
Vue.component("DeleteConfirmation", DeleteConfirmation);
Vue.component("PrintPDF", PrintPDF);
Vue.component("ExportBtn", ExportBtn);
Vue.component("PrintBtn", PrintBtn);
Vue.component("PrintInvoice", PrintInvoice);



export default ({
});