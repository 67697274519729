<template>
    <v-container fluid>
        <v-row class="personal_data">
            <v-col cols="12">
                <h3 class="secondary--text">{{ $t("personal data") }}</h3>
            </v-col>

            <v-col cols="6">
                <div class="d-flex ">
                    <span class="font-weight-bold flex-shrink-0">{{ $t("arabic name") }}</span>
                    <span class="mx-2">{{ employeeData.employee_name_ar }}</span>
                </div>
            </v-col>
            <v-col cols="6">
                <div class="d-flex ">
                    <span class="font-weight-bold flex-shrink-0">{{ $t("english name") }}</span>
                    <span class="mx-2">{{ employeeData.employee_name_en }}</span>
                </div>
            </v-col>
        </v-row>
        <v-row class="address_contact">
            <v-col cols="12">
                <h3 class="secondary--text">{{ $t("address / contact") }}</h3>
            </v-col>

            <v-col cols="auto">
                <div>
                    <p class="font-weight-bold">{{ $t("the address") }}</p>
                    <p class="font-weight-bold">{{ $t("mobile number") }}</p>
                </div>
            </v-col>
            <v-col cols="auto">
                <div>
                    <p>{{ employeeData.address }}</p>
                    <p>{{ employeeData.phone }}</p>
                </div>
            </v-col>
        </v-row>
        <v-row class="contract_details">
            <v-col cols="12">
                <h3 class="secondary--text">{{ $t("contract details") }}</h3>
            </v-col>

            <v-col cols="6">
                <div class="d-flex ">
                    <span class="font-weight-bold flex-shrink-0">{{ $t("fixed salary") }}</span>
                    <span class="mx-2">{{ employeeData.salary_fixed || 0 }}</span>
                </div>
            </v-col>
            <v-col cols="6">
                <div class="d-flex ">
                    <span class="font-weight-bold flex-shrink-0">{{ $t("total salary") }}</span>
                    <span class="mx-2">{{ employeeData.salary_total || 0 }}</span>
                </div>
            </v-col>
        </v-row>
        <v-row class="marital_status">
            <v-col cols="12">
                <h3 class="secondary--text">{{ $t("marital status") }}</h3>
            </v-col>

            <v-col cols="4">
                <div class="d-flex ">
                    <span class="font-weight-bold">{{ $t("marital status") }}</span>
                    <span class="mx-2">{{ employeeData.marital_status == 1 ? $t('single') : $t('married') }}</span>
                </div>
                <div class="d-flex  mt-4">
                    <span class="font-weight-bold">{{ $t("nationality") }}</span>
                    <span class="mx-2">{{ employeeData.nationality_name }}</span>
                </div>
            </v-col>
            <v-col cols="4">
                <div class="d-flex ">
                    <span class="font-weight-bold">{{ $t("number of children") }}</span>
                    <span class="mx-2">{{ employeeData.children_number }}</span>
                </div>
                <div class="d-flex  mt-4">
                    <span class="font-weight-bold">{{ $t("date of birth") }}</span>
                    <span class="mx-2">{{ employeeData.birthday }}</span>
                </div>
            </v-col>
            <v-col cols="4">
                <div class="d-flex ">
                    <span class="font-weight-bold">{{ $t("religion") }}</span>
                    <span class="mx-2">{{ employeeData.relegion == 1 ? $t("muslim") : $t("christian") }}</span>
                </div>
            </v-col>
        </v-row>
        <v-row class="qualifications">
            <v-col cols="12">
                <h3 class="secondary--text">{{ $t("qualifications") }}</h3>
            </v-col>

            <v-col cols="4">
                <div class="d-flex ">
                    <span class="font-weight-bold">{{ $t("qualification") }}</span>
                    <span class="mx-2">{{ employeeData.qualification_name }}</span>
                </div>
                <div class="d-flex  mt-4">
                    <span class="font-weight-bold">{{ $t("the department") }}</span>
                    <span class="mx-2">{{ employeeData.department_name }}</span>
                </div>
            </v-col>
            <v-col cols="4">
                <div class="d-flex ">
                    <span class="font-weight-bold">{{ $t("the company") }}</span>
                    <span class="mx-2">{{ employeeData.company_name }}</span>
                </div>
                <div class="d-flex  mt-4">
                    <span class="font-weight-bold">{{ $t("job") }}</span>
                    <span class="mx-2">{{ employeeData.job_name }}</span>
                </div>
            </v-col>
            <v-col cols="4">
                <div class="d-flex ">
                    <span class="font-weight-bold">{{ $t("the management") }}</span>
                    <span class="mx-2">{{ employeeData.management_name }}</span>
                </div>

            </v-col>
        </v-row>

    </v-container>
</template>

<script>
export default {
    name: "EmployeesPersonalInformation",
    data: () => ({
        status: null,
        companys: null,
        departments: null,
        jobs: null,
        vacations: null,
        qualifications: null,
        managements: null,
        nationalitys: null,
        shifts: null,
        name: null,
        id: null,
        array: [],
    }),
    props: {
        employeeData: { type: Object }
    },
    methods: {

    },
    watch: {

    }
}
</script>


<style lang="scss" scoped>
.personal_data div {
    // width: 358px;
    width: 416px;
}

.qualifications div,
.marital_status div {
    // width: 337px;
}
</style>