<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <GenericInput type="text" :disabled="false" v-if="pageData.isEdit" :value="form.screen_code"
                     @input="form.screen_code = $event" label="code" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"></GenericInput>
                  <GenericInput type="text" :value="form.screen_sub_title_en" @input="form.screen_sub_title_en = $event"
                     label="name_en" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.screen_sub_title_ar" @input="form.screen_sub_title_ar = $event"
                     label="name_ar" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.url" @input="form.url = $event" label="link" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"></GenericInput>

                  <v-col cols="12" md="4" sm="6">
                     <v-autocomplete :items="cat_screens" item-text="title" item-value="id"
                        :rules="$store.state.Settings.requiredRule" v-model="form.screen_cat_id"
                        :label="$i18n.t('category')" :no-data-text="$i18n.t('no selects')" dense
                        :loading="pageData.editIsLoading" chips small-chips outlined>
                        <template v-slot:item="{ item }">
                           <img v-if="item.main_image" style="max-height: 20px;" class="mx-2"
                              :src="$api.serverUrl + item.main_image" alt="qawaem" />
                           <v-icon v-if="!item.main_image" class="mx-2">mdi-file-image-outline</v-icon>
                           {{ item.main_title }} - {{ item.title }}
                        </template>
                        <template v-slot:selection="{ item }">
                           <img v-if="item.main_image" style="max-height: 20px;" class="mx-2"
                              :src="$api.serverUrl + item.main_image" alt="qawaem" />
                           <v-icon v-if="!item.main_image" class="mx-2">mdi-file-image-outline</v-icon>
                           {{ item.main_title }} - {{ item.title }}
                        </template>
                     </v-autocomplete>
                  </v-col>
                  <GenericInput type="select" :lookups="actions" :value="form.actions" @input="form.actions = $event"
                     label="actions" :multi="true" :required="true" selected_label="name" selected_prop="id"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"></GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>

import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';

export default {
   name: "SubMenusControl",

   data: () => ({
      pageData: {
         screen_code: "01-003",
         url: null,
         controlRoute: "/main/screen_sub-control/",
         entityRouteName: "SubMenusEntity",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },

      valid: false,
      cat_screens: [],
      actions: [],
      form: {
         screen_sub_title_en: null,
         screen_sub_title_ar: null,
         screen_cat_id: null,
         actions: [],
         url: null
      },
   }),
   components: {
      GenericInput,
      ControlHeader
   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t('edit') + ' ' + this.$i18n.t('page') : this.$i18n.t('add page');
      },
      getData() {
         this.actions = [
            {
               id: 1,
               name: this.$i18n.t('view')
            },
            {
               id: 2,
               name: this.$i18n.t('add')
            },
            {
               id: 3,
               name: this.$i18n.t('edit')
            },
            {
               id: 4,
               name: this.$i18n.t('delete')
            },
            {
               id: 5,
               name: this.$i18n.t('print')
            },
         ]
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`screen_sub/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.cat_screens = response.data.cats;
                     this.form.actions = this.form.actions.split(',').map(i => Number(i));
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`screen_sub/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.cat_screens = response.data.cats;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            this.form.actions = this.form.actions.toString()
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`screen_sub/${this.form.screen_sub_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                   this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`screen_sub`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                   this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`screen_sub/${this.form.screen_sub_id}`, { _method: 'delete' }).then(() => {
               this.$router.push('/main/screen_sub');
               this.pageData.isLoading = false
            })
         }
      },
      cancelControl() {
         if (this.pageData.isEdit) {
            this.$router.push({
               name: this.pageData.entityRouteName,
               params: this.$route.params,
            });
         } else {
            this.$router.push(this.$store.state.activeScreen.url)
         }
      },

   },
};
</script>
