<template>
   <section>
      <v-container fluid class="pa-5">
         <v-row  align="center">
            <v-col cols="6" class="text-h6 font-weight-bold">{{ $t('accounts') }}</v-col>

            <v-col cols="6" class="text-h6 font-weight-bold text-end"> 
               <!-- <v-btn depressed @click="exitDialog = true"
                  class="my-2 mx-2 rounded-lg" text plain :height="40" color="error">
                  {{ $t('cancel') }}
               </v-btn> -->
               <v-btn depressed @click="save" :min-width="148" class="my-2 mx-2 rounded-lg white--text" :height="40"
                  color="success">
                  {{ $t('save') }}</v-btn>
               </v-col>
         </v-row>
         <!-- Form -->
         <v-form ref="form" v-model="valid">
            <v-card class="transparent py-5 px-1" elevation="0">
               <v-row align="center">
                  <GenericInput type="checkbox" :value="form.start_date" @input="form.start_date = $event"
                     label="Linking with accounts" :required="false" :isLoading="pageData.isLoading" :cols="[12, 12, 12]">
                  </GenericInput>
                  <GenericInput type="select" :value="form.start_date" @input="form.start_date = $event"
                     label="Saudi insurance account" selected_label="name" selected_prop="id" :required="true"
                     :lookups="calendar" :isLoading="pageData.isLoading" :cols="[12, 6, 6]">
                     <template v-slot:append>
                        <v-icon>mdi-magnify</v-icon>
                     </template>
                  </GenericInput>
                  <v-spacer></v-spacer>
                  <GenericInput type="select" :value="form.start_date" @input="form.start_date = $event"
                     label="Insurance account for non Saudis" selected_label="name" selected_prop="id" :required="true"
                     :lookups="calendar" :isLoading="pageData.isLoading" :cols="[12, 6, 6]">
                     <template v-slot:append>
                        <v-icon>mdi-magnify</v-icon>
                     </template>
                  </GenericInput>
               </v-row>
               <v-col cols="12" class="mt-5">
                  <v-divider></v-divider>
               </v-col>
               <v-row align="center" class="mt-3 px-3">
                  <label class="">{{ $t("Link to the accounts program") }}</label>
                  <GenericInput type="text" :value="form.start_date" @input="form.start_date = $event"
                     label="Write the link (API) for the program" :required="false" :isLoading="pageData.isLoading"
                     :cols="[12, 6, 6]">
                  </GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>

export default {
   name: "AccountsHRsettings",

   data: () => ({
      pageData: {
         screen_code: "01-007",
         url: null,
         controlRoute: "/settings_hr/accounts",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      viewForm: false,
      summery: [],
      valid: false,
      employees: [],
      vacations: [],
      employee_data: [],
      calendar: [],
      openDialog: false,
      attachmentDialog: false,
      vacation_summary_name: "",
      employee_profile_id: "",
      form: {
         created_date: null,
         employee_id: null,
         start_date: null,
         vacation_system_d_id: null,
         days_count: null,
         notes: null,
      },
      exitDialog: false
   }),
   components: {
   },
   computed: {
   },
   mounted() {
      this.calendar = [
         {
            id: 1,
            name: 'الهجري'
         },
         {
            id: 2,
            name: 'الميلادي'
         }
      ]
      this.pageData.isLoading = false;
      this.pageMainData()
      // this.getData()

   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id && !this.openProfileForm) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`vacation_action/${this.$route.params.id}`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.employees = response.data.employees;
                     this.vacations = response.data.vacations;
                     console.log("response", response);
                     // this.form = response.data.data;
                     !this.openProfileForm ? this.form = response.data.data : ""

                     if (this.openProfileForm) {
                        this.form.employee_id = +this.$route.params.id
                     }

                     this.viewSummery = true;
                     // this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`vacation_action/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.employees = response.data.employees;
                     this.vacations = response.data.vacations;

                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`vacation_action/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`vacation_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      save_employee() {
         this.form.amount = this.form.increase_type == 2 ? this.$global.ConvertFromPercentage(this.increase_value, this.form.salary_fixed) : this.increase_value
         this.form.salary_after_increase = this.totalAmount
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.$api.POST_METHOD(`vacation_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     // this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                     this.closeProfileForm()
                     this.refreshTable()
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`vacation_action`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`vacation_action/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
   watch: {
      "form.employee_id"() {
         this.pageData.isLoading = true;
         this.$api.GET_METHOD(`get_employee_vacation/${this.form.employee_id}`).then((response) => {
            if (response.check) {
               console.log("get_employee_vacation", response.data);
               this.pageData.isLoading = false;
               this.employee_data = response.data.vacation_balance;
               this.vacation_summary_name = this.$global.FilterArrayOfObjectElement(this.employee_data, 'id',
                  this.form.vacation_system_d_id, 'name')
            }
         })
      },
      valid() {
         if (this.openProfileForm) {
            return this.saveEmployeeData(this.form, this.valid)
         }
      }
   }
   ,
   props: {
      openProfileForm: { default: false },
      saveEmployeeData: { type: Function }
   }
};
</script>

<style lang="scss" scoped>
div#divider_ver {
   height: 200px !important;
}
</style>
