<template>
  <router-view></router-view>
</template>


<script>
export default {
  name: "FinancialYear",
  components: {

  },

  computed: {

  },
  watch: {

  },
  methods: {

  },

}
</script>
<style scoped></style>