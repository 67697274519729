import { render, staticRenderFns } from "./SalaryIncreaseToggle.vue?vue&type=template&id=605f4db7&scoped=true&"
import script from "./SalaryIncreaseToggle.vue?vue&type=script&lang=js&"
export * from "./SalaryIncreaseToggle.vue?vue&type=script&lang=js&"
import style0 from "./SalaryIncreaseToggle.vue?vue&type=style&index=0&id=605f4db7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "605f4db7",
  null
  
)

export default component.exports