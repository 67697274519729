<template>
   <div>
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
         :paginate-elements-by-height="$store.state.Settings.windowSize.x" :filename="'multi_voucher'" :pdf-quality="2"
         :manual-pagination="true" pdf-format="a4" pdf-orientation="landscape"
         :pdf-content-width="$store.state.Settings.windowSize.y" ref="html2Pdf">
         <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">
            <PrintPDF :header="tableHeader" :maxPDFRows="25" :data="printData || [{}]" :header_slot="false"
               :content_slot="false" :footer_slot="false" :showGroupHeader="false" :groupHeader="false" :footerData="[]"
               :customize_header_footer="false" :printStaticData="Object">
               <template v-slot:pdf_content="{ page }">

                  <div id="title" v-if="page == 1">
                     {{ $t('') }}
                  </div>

                  <div class="salary_history">{{ $t('Salary history') }} : {{ printData[0].accounting_month }}</div>
                  <SalaryReportTable :employeeData="printData[0]" />

               </template>
            </PrintPDF>
         </section>
      </vue-html2pdf>


   </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import SalaryReportTable from './SalaryReportTable.vue';
export default {
   name: "SalaryReportPrint",
   props: {
      printAction: { type: Function },
      print: { default: false },
      printData: { default: Array },
      printStaticData: { default: Object },
   },
   watch: {
      print() {
         if (this.print) {
            this.$refs.html2Pdf.generatePdf();
         }
      },
   },
   data: () => ({
      tableHeader: [],
   }),
   computed: {

   },
   components: {
      VueHtml2pdf,
      SalaryReportTable
   },
   methods: {
   },
   mounted() {

   },


};
</script>

<style lang="scss" scoped>
.salary_history{
   padding: 0 13px;
}
</style>