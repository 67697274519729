<template>
    <v-dialog v-model="openRenewalContract" persistent max-width="550" :key="num">
        <AnimatedLoading :isLoading="!form" />
        <v-form ref="form" v-model="valid" class="renewalContractForm" v-if="form">
            <v-card class="pa-2">
                <v-card-actions>
                    <v-card-text class="font-weight-bold text-h6 text-capitalize">
                        {{ $t("renewal") }} {{ form.document_name }}
                    </v-card-text>
                    <v-btn absolute class="mt-2" :style="$vuetify.rtl ? 'left: 10px' : 'right: 10px'" small fab color="red5"
                        text @click="close">
                        <v-icon color="red5">mdi-close</v-icon>
                    </v-btn>
                </v-card-actions>

                <v-card-text>
                    <v-row class="text-center  align-center">
                        <GenericInput type="text" :value="form.number" @input="form.number = $event"
                            :label="form.document_name" :required="true" paddingX="px-2" :isLoading="false"
                            :cols="[12, 12, 12]">
                        </GenericInput>

                        <GenericInput type="date" :value="form.start_date" @input="form.start_date = $event"
                            label="start date" :required="true" paddingX="px-2" :isLoading="false" :cols="[12, 6, 6]">
                        </GenericInput>
                        <GenericInput type="date" :value="form.end_date" @input="form.end_date = $event" label="end date"
                            :required="true" :minDate="form.start_date" paddingX="px-2" :isLoading="false"
                            :cols="[12, 6, 6]">
                        </GenericInput>
                        <v-col cols="12" class="d-flex align-center justify-end">
                            <div class="upload_pictures text-center mx-3">
                                <v-btn class="primary--text blue12 pa-5" elevation="0" :required="true">
                                    {{ $t("upload pictures") }}
                                    <GenericInput type="dropzone" class="userUploadImage" :value="form.doc_file"
                                        @input="form.doc_file = $event" label="image" :required="true" :cols="[12, 12, 12]">
                                    </GenericInput>
                                    <v-icon class="mx-2">mdi-image</v-icon>
                                </v-btn>
                            </div>

                            <v-btn width="140" height="40" class="white--text success pa-4" :loading="loading" @click="save"
                                elevation="0" :disabled="form.doc_file !== null && valid == true ? false : true">
                                {{ $t("save") }}
                            </v-btn>

                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import GenericInput from '@/components/ui/GenericInput.vue'
export default {
    name: "RenewalContractDialog",
    data: () => ({
        num: 0,
        valid: false,
        loading: false,
        form: {
            id: null,
            start_date: null,
            end_date: null,
            number: null,
            doc_file: null
        }
    }),
    methods: {
        save() {
            this.loading = true
            if (this.$refs.form.validate()) {
                console.log('form', this.form);
                this.$api.POST_METHOD(`update_employee_document/${this.form.id}`, this.form).then((response) => {
                    this.loading = false
                    console.log('save', response);
                    this.$global.CallBackMethod(this.form)
                    this.close()
                })
            }
        },
        close() {
            this.form.doc_file = null // solve second time validation
            this.num++
            this.closeRenewalContract()
            this.$refs.form.reset();
        }
    },
    props: {
        openRenewalContract: { default: false },
        closeRenewalContract: { type: Function },
        pushNewData: { type: Function },
        rowData: { type: Object },
    },
    watch: {
        openRenewalContract() {
            if (this.openRenewalContract) {
                this.form = this.$global.CallBackMethod()
                this.form.doc_file = null
                
            }
        }
    },
    components: { GenericInput, AnimatedLoading }
}
</script>

<style lang="scss" scoped>
.userUploadImage {
    position: absolute;
    top: 0;
    padding: 0;
    opacity: 0;
}

.upload_pictures {
    // position: relative;
    cursor: pointer;

    span {
        overflow: hidden;
    }

    input {
        border-radius: 9px;
        height: 43px;
        width: 250px;
        position: absolute;
        left: -18px;
        opacity: 0;
        cursor: pointer;
    }
}

.renewalContractForm {
    overflow: hidden;
}
</style>