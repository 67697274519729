<template>
  <!-- <transition
    :enter-active-class="$vuetify.rtl == true ? 'fadeIn' : 'fadeIn'"
    :leave-active-class="$vuetify.rtl == true ? 'fadeOut' : 'fadeOut'"
  > -->

    <router-view appear :key="path"></router-view>
  <!-- </transition> -->
</template>


<script>
export default {
  name: "SettingsModule",
  // components: {},
  data: () => ({
    path : null
  }),
};
</script>
