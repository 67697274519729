<template>

  <v-dialog v-model="dialog" overlay-opacity="0.75" persistent max-width="600">
    <v-card relative class="backgroundW" :key="attachmentID">
      <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
        color="grey lighten-1" text @click="closeDialog">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <v-card-title class="text-h6 font-weight-bold">
        <!-- <img v-if="dialogContent.icon == 'info'" src="../../assets/img/png/watchOut.png" height="30" class="mx-2" alt="erp" /> -->
        <v-icon size="25" class="mx-2">mdi-paperclip</v-icon>
        <div class="text-h6 font-weight-bold">{{ data.attach_ext ? $t('download/edit attachment') : $t('add attachment')
        }}</div>
      </v-card-title>
      <v-col cols="12" sm="12" class="px-7" v-if="data.attach_ext">
        <div class=" d-flex justify-space-between">
          <div>{{$t('attach')}}_{{$t(entityName)}}_{{data[dataKey]}}</div>
          <v-btn color="success" :loading="printLoading" @click="downloadAttachment" text>{{ $t('download') }} <v-icon
              class="mx-1">
              mdi-download</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="px-7">
        <GenericInput :type="type" :value="attachment" @input="attachment = $event" label="choose attachment"
          :multi="multi" :required="true" :isLoading="false" :cols="[12, 12, 12]" />
      </v-col>


      <v-col cols="12" class="pa-7">
        <v-row justify="end">
          <v-col cols="auto">
            <v-btn depressed color="error" text plain @click="closeDialog">
              {{ $t('close') }}</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn depressed color="success" :loading="isLoading" :disabled="!(attachment)" min-width="110"
              @click="uploadAttachment">
              {{ $t('add') }}</v-btn>
          </v-col>

        </v-row>
      </v-col>


    </v-card>
  </v-dialog>

</template>



<style scoped lang="scss">

</style>



<script>
import GenericInput from '../ui/GenericInput.vue';

export default {
  name: "AttachmentDialog",
  props: {
    returnMethod: { type: Function },
    dialog: { default: false },
    downloadResource: { default: '' },
    entityName: { default: 'file' },
    dataKey: { default: 'id' },
    uploadResource: { default: '' },
    uploadKey: { default: 'file' },
    type: { default: 'dropzone' },
    multi: { default: false },
    data: { default: Object },
  },
  computed: {},
  data: () => ({
    printLoading: false,
    attachment: null,
    isLoading: false,
    attachmentID: 1,
  }),
  methods: {
    closeDialog() {
      this.returnMethod();
    },
    downloadAttachment() {
      this.printLoading = true;
      const src = `${this.downloadResource}/${this.data[this.dataKey]}`;
      const download_file_name = `${this.$i18n.t('attach')}_${this.$i18n.t(this.entityName)}_${this.data[this.dataKey]}.${this.data.attach_ext}`
      this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
        this.printLoading = false;
      })
    },
    uploadAttachment() {
      this.isLoading = true;
      const requestBody = {
        id: this.data[this.dataKey],
        [this.uploadKey]: this.attachment,
      }
      this.$api.POST_METHOD(`${this.uploadResource}/${this.data[this.dataKey]}`, requestBody).then(() => {
        this.isLoading = false;
        this.attachment = null;
        this.attachmentID = this.attachmentID + 1
        this.returnMethod(true);
      })
    },
  },
  mounted() {
  },
  components: { GenericInput, }
};
</script>
