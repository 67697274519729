<template>
    <v-row justify="center">
        <v-col cols="12">
            <v-btn @click="openDialog = true" class="secondary--text" elevation="0" color="blue12">
                {{ $t('add a note') | capitalize }}
            </v-btn>
        </v-col>

        <v-dialog v-model="openDialog" persistent max-width="750">
            <v-card relative class="pa-5">
                <v-form ref="form" v-model="valid">
                    <div>
                        <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 20px' : 'right: 20px'" small fab
                            color="grey lighten-1" text @click="openDialog = false, $refs.form.reset()">
                            <v-icon color="error lighten-1">mdi-close</v-icon>
                        </v-btn>
                        <v-card-title class="text-h6 text-center font-weight-bold">
                            <h4 class="d-block font-weight-medium text--disabled">
                                {{ $t('add a note') | capitalize }}
                            </h4>
                        </v-card-title>

                        <v-row align="center" class="mt-2">
                            <GenericInput type="textarea" :value="form.note_body" @input="form.note_body = $event"
                                label="note" :required="true" :isLoading="getDataLoading" :cols="[12, 12, 12]">
                            </GenericInput>
                        </v-row>

                        <v-row align="center" justify="end">
                            <v-col cols="auto">
                                <v-btn color="success" depressed :width="120" :disabled="!valid" @click="save"
                                    class="rounded-lg font-weight-bold white--text" :loading="saveDataLoading">
                                    {{ $t('save') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-form>


            </v-card>
        </v-dialog>

        <!-- comments -->
        <v-row align="center" class="pa-3">

            <v-col cols="12" class="my-10" v-if="getDataLoading">
                <AnimatedLoading :height="100" :hideText="false" :isLoading="getDataLoading" />
            </v-col>

            <v-col cols="12" v-if="notes.length === 0 && !getDataLoading">
                <h4>{{ $t("there are no notes comments") | capitalize }}</h4>
            </v-col>

            <v-row class="comments-section px-5 py-3">
                <v-card class="mx-auto my-2" width="100%" outlined v-for="note in notes" :key="note.id">
                    <v-list-item three-line>
                        <v-avatar size="56">
                            <LazyImage :height="80" :src="$api.serverUrl + note.image"
                                :error="require('@/assets/img/svg/default_profile.svg')"
                                :loading="require('@/assets/img/svg/default_profile.svg')" />
                        </v-avatar>

                        <v-list-item-content class="pa-2">
                            <div class="d-flex justify-space-between">
                                <span class="text-button mb-4">
                                    {{ $t("username") }}: {{ note.full_name }}
                                </span>
                                <span class="text-button mb-4">
                                    {{ note.add_date }}
                                </span>
                            </div>
                            <v-list-item-subtitle>{{ note.note_body }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-row>
        </v-row>
    </v-row>
</template>
 
 
 
 
 
 
 
<script>
import GenericInput from "@/components/ui/GenericInput.vue";
import AnimatedLoading from "@/components/ui/AnimatedLoading.vue";
import LazyImage from "@/components/ui/LazyImage.vue";
// import ChatDialog from "@/components/modals/ChatDialog.vue";
export default {
    name: "EmployeesNotes",
    props: {
        backValueMethod: { type: Function },
        isLoading: { default: false },
    },
    computed: {
    },
    components: {
        GenericInput,
        AnimatedLoading,
        LazyImage,
    },
    mounted() {
        this.getData();
    },
    watch: {
    },
    data: () => ({
        valid: false,
        getDataLoading: false,
        saveDataLoading: false,
        openDialog: false,
        notes: [],
        form: {
            note_body: null,
        }
    }),
    methods: {
        save() {
            if (this.$refs.form.validate()) {
                this.saveDataLoading = true;
                this.$api.POST_METHOD(`add_employee_notes/${this.$route.params.id}`, this.form).then((response) => {
                    this.saveDataLoading = false;
                    if (response.check) {
                        this.openDialog = false;
                        this.notes.push(response.data.notes)
                        this.$refs.form.reset();
                    }
                })
            }
        },
        getData() {
            this.getDataLoading = true;
            this.$api.GET_METHOD(`get_employee_notes/${this.$route.params.id}`).then((response) => {
                this.getDataLoading = false;
                if (response.check) {
                    // this.comments = response.data.comments.sort((b, a) => a.id - b.id)
                    this.notes = response.data.notes
                    this.$refs.form.validate();
                }
            })

        }

    },

};
</script>
 
<style scoped lang="scss"></style>