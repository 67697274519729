export const EntityMixins = {
  data: () => ({
    endpoint: null,
    response: {},
    pageData: {
      entityMixin: true,
      screen_code: null,
      list_key: 'items',
      response: {},
      url: null,
      controlRoute: null,
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: new URLSearchParams(),
      printLoading: {
        loading: false,
        id: null,
        key: 'id'
      },
      from_date: null,
      to_date: null,
      to: null,
      from: null,
      date: null,
    },
  }),
  watch: {
    $route() {
      this.pageData.queryParam = new URLSearchParams();
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = new URLSearchParams(this.$store.state.activeScreen.requestParam || '');
    },
    pageMainData() {
      this.pageData.screen_code = this.$store.getters.activeScreen.screen_code || null;
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.url.startsWith("/") && (this.pageData.controlRoute = (`${this.pageData.url}-control/`).substring(1))
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
      };
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`${this.endpoint}?${this.pageData.queryParam.toString()}`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.response = response.data;
              this.pageData.entityName = this.pageData.isTrashed == 0 ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data[this.pageData.list_key] ? response.data[this.pageData.list_key].data : [];
              this.pageData.page = response.data[this.pageData.list_key] ? response.data[this.pageData.list_key].current_page : 0;
              response.data[this.pageData.list_key] ? this.pageData.pagination = {
                page: response.data[this.pageData.list_key].current_page,
                totalPages: response.data[this.pageData.list_key].last_page,
                per_page: response.data[this.pageData.list_key].per_page,
                totalRows: response.data[this.pageData.list_key].total,
              } : { page: 0, totalPages: 0, per_page: 0, totalRows: 0 };

              this.setResponse(response)
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    setResponse() { },
    getSearch(word) {
      this.pageData.queryParam.set("word", word || '')
      this.pageData.queryParam.set("page", 1)
      this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString().replace(/\w+=&/g, '').replace(/&\w+=$/, ''))
    },
    changePage(page, limit) {
      this.pageData.queryParam.set("page", page || 1);
      this.pageData.queryParam.set("rows", limit || 15);
      this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString().replace(/\w+=&/g, '').replace(/&\w+=$/, ''))
    },
    edit(row) {
      !this.pageData.controlRoute.startsWith("/") ?
        this.$router.push(`/${this.pageData.controlRoute}${row.id}`)
        :
        this.$router.push(`${this.pageData.controlRoute}${row.id}`);
    },
    toggleActiveMethod(ID) {
      console.log(ID);
      if (ID) {
        this.$api.POST_METHOD(`${this.endpoint}_toggle_active/${typeof ID === 'object' ? ID.id || ID.m_id : ID}`, null).then((response) => {
          if (response.check) {
            this.getData();
          }
        });
      }
    },
  },
};
