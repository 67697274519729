<template>
    <v-dialog v-model="openDialogAction" persistent max-width="500">
        <v-card v-if="!loading">

            <v-card-actions>
                <v-card-text class="font-weight-bold text-h6 text-capitalize">
                    {{ rowDialogData.id }}- {{ rowDialogData.employee_name }}
                </v-card-text>

                <v-btn class="" small fab color="red5" text @click="closeDialogAction">
                    <v-icon color="red5">mdi-close</v-icon>
                </v-btn>
            </v-card-actions>

            <v-card-text>
                <v-row class="text-center">

                    <v-card-text class="d-flex justify-space-between">
                        <span>
                            <div class="text-h7 font-weight-bold">{{ $t('date of request') }}</div>
                            <span>{{ rowDialogData.add_date }}</span>
                        </span>

                        <span v-if="dialog_id == 3 || dialog_id == 8">
                            <div class="text-h7 font-weight-bold ">{{ $t('allowance type') }}</div>
                            <span>{{ rowDialogData.allowance_one_time }}</span>
                        </span>

                        <span
                            v-if="dialog_id == 0 || dialog_id == 10 || dialog_id == 12 || dialog_id == 13 || dialog_id == 14">
                            <div class="text-h7 font-weight-bold ">{{ $t('amount') }}</div>
                            <span>{{ rowDialogData?.amount | float }}</span>
                        </span>

                        <span v-if="dialog_id == 3 || dialog_id == 8">
                            <div class="text-h7 font-weight-bold ">{{ $t('the value') }}</div>
                            <span>{{ rowDialogData.amount | float }}</span>
                        </span>

                        <span v-if="dialog_id == 0 || dialog_id == 10 || dialog_id == 13 || dialog_id == 14">
                            <div class="text-h7 font-weight-bold">{{ $t('number of installments') }}</div>
                            <span>{{ rowDialogData.installments_count }}</span>
                        </span>

                        <span v-if="dialog_id == 9">
                            <div class="text-h7 font-weight-bold">{{ $t('the number of days') }}</div>
                            <span>{{ rowDialogData.days_count }}</span>
                        </span>

                        <span v-if="dialog_id == 9">
                            <div class="text-h7 font-weight-bold">{{ $t('from day') }}</div>
                            <span>{{ rowDialogData.start_date }}</span>
                        </span>

                        <span v-if="dialog_id == 11">
                            <div class="text-h7 font-weight-bold">{{ $t('amount required') }}</div>
                            <span>{{ rowDialogData.amount | float }}</span>
                        </span>

                        <span v-if="dialog_id == 11 || dialog_id == 12">
                            <div class="text-h7 font-weight-bold">{{ $t('current salary') }}</div>
                            <span>{{ rowDialogData?.salary_after_increase | float }}</span>
                        </span>

                        <span v-if="dialog_id == 4">
                            <div class="text-h7 font-weight-bold ">{{ $t('check out time') }}</div>
                            <span>{{ rowDialogData.from_time }}</span>
                        </span>

                        <span v-if="dialog_id == 4">
                            <div class="text-h7 font-weight-bold ">{{ $t('return time') }}</div>
                            <span>{{ rowDialogData.to_time }}</span>
                        </span>

                        <span v-if="dialog_id == 5">
                            <div class="text-h7 font-weight-bold ">{{ $t('arrival time') }}</div>
                            <span>{{ rowDialogData.to_time }}</span>
                        </span>

                        <span v-if="dialog_id == 5">
                            <div class="text-h7 font-weight-bold ">{{ $t('hours late') }}</div>
                            <span>{{ rowDialogData.hours_late }}</span>
                        </span>
                    </v-card-text>



                    <v-card-text class="d-flex justify-space-between" v-if="dialog_id == 9 || dialog_id == 11">
                        <span v-if="dialog_id == 9">
                            <div class="text-h7 font-weight-bold">{{ $t('to day') }}</div>
                            <span>{{ rowDialogData.end_date }}</span>
                        </span>
                        <span v-if="dialog_id == 9">
                            <div class="text-h7 font-weight-bold ">{{ $t('vacation type') }}</div>
                            <span>{{ rowDialogData.vacation_type }}</span>
                        </span>

                        <span v-if="dialog_id == 11">
                            <div class="text-h7 font-weight-bold blue4--text">محمود سعيد</div>
                        </span>

                        <span v-if="dialog_id == 11">
                            <div class="text-h7 font-weight-bold">01-02-2022</div>
                        </span>

                        <span v-if="dialog_id == 11">
                            <div class="text-h7 font-weight-bold success--text">{{ $t('Ok') }}</div>
                        </span>
                    </v-card-text>


                    <v-card-text class="d-flex justify-space-between" v-if="dialog_id == 11">
                        <span>
                            <div class="text-h7 font-weight-bold blue4--text">محمود سعيد</div>
                        </span>

                        <span>
                            <div class="text-h7 font-weight-bold">01-02-2022</div>
                        </span>

                        <span class="d-flex">
                            <div class="text-h7 font-weight-bold warning--text ml-5">
                                {{ $t('suggestion') }}
                            </div>
                            <span @click="() => openDialogInfo = true" style="cursor: pointer;">
                                <img src="@/assets/img/svg/reason.svg" alt="">
                            </span>
                        </span>
                    </v-card-text>

                    <!-- Attachment -->
                    <v-col cols="12" md="12" lg="12" v-if="rowDialogData.attachment">
                        <div class="pa-3 font-weight-bold">{{ $t("attachments") }}</div>
                        <v-img height="200" width="200" class="ma-auto cursor_pointer" @click="openAttachments = true"
                            :src="$api.serverUrl + rowDialogData.attachment"></v-img>

                        <v-dialog v-model="openAttachments"  max-width="700">
                            <v-card>
                                <div class="d-flex justify-end">
                                    <v-btn absolute small fab class="ma-3" color="red5" text
                                        @click="openAttachments = false">
                                        <v-icon color="red5">mdi-close</v-icon>
                                    </v-btn>
                                </div>

                                <v-img class="cursor_pointer" max-height="100vh" 
                                    @click="downloadAttachment($api.serverUrl + rowDialogData.attachment, rowDialogData)"
                                    :src="$api.serverUrl + rowDialogData.attachment"></v-img>
                            </v-card>
                        </v-dialog>
                    </v-col>


                    <v-card-actions class="d-block mi-start-auto">
                        <v-btn v-if="pendingState" class="error--text mr-10 ml-10" depressed
                            @click="closeDialogAction, openDialogReason = true">
                            {{ $t("reject") }}</v-btn>

                        <v-btn class="error--text mr-10 ml-10" depressed
                            @click="closeDialogAction, openDialogSuggestion = true" v-if="dialog_id == 11">
                            {{ $t("suggestion") }}
                        </v-btn>

                        <v-btn v-if="pendingState" color="success" depressed :width="120"
                            class="rounded-lg font-weight-bold white--text" @click="confirmModal = true">
                            {{ $t('approval') }}
                        </v-btn>
                    </v-card-actions>
                </v-row>
            </v-card-text>
        </v-card>

        <RejectedReasonDialog :openDialogReason="openDialogReason" :closeDialog="() => (openDialogReason = false)"
            :dialog_id="dialog_id" :tab="tab" :status="status" :rowDialogData="rowDialogData"
            :backValueMethod="backValueMethod" :closeDialogAction="closeDialogAction" />

        <DialogSuggestion :openDialogSug="openDialogSuggestion"
            :closeDialog="() => (closeDialogAction(), openDialogSuggestion = false)" :dialog_id="dialog_id"
            :rowDialogData="rowDialogData" />

        <DialogInfo :openDialogInfo="openDialogInfo" :closeDialog="() => openDialogInfo = false"
            :rowDialogData="rowDialogData" :dialog_id="dialog_id" />

        <AnimatedLoading style="height:300px" classes="white pt-16 ma-auto " :hideText="false" :isLoading="loading"
            v-if="loading" />

        <ConfirmModal :saveDataLoading="saveDataLoading" :dialog="confirmModal" :showCloseIcon="false"
            :buttonOneMethod="save" :buttonTwoMethod="() => confirmModal = false" :dialogContent="{
                title: 'are you sure',
                buttonOneTitle: 'yes',
                buttonOneColor: 'primary',
                buttonTwoTitle: 'no',
                buttonTwoColor: 'error',
            }" />

    </v-dialog>
</template>

<script>
import RejectedReasonDialog from './RejectedReasonDialog.vue';
import DialogSuggestion from './DialogSuggestion.vue';
import DialogInfo from './DialogInfo.vue';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import ConfirmModal from '@/components/modals/CustomModal.vue';
// import GenericInput from '@/components/ui/GenericInput.vue';
export default {
    name: "AgreeDialog",

    rowDialogData: {},

    data: () => ({
        openDialogReason: false,
        saveDataLoading: false,
        confirmModal: false,
        // openDialogAction: false,
        openDialogSuggestion: false,
        openDialogInfo: false,
        loading: false,
        valid: false,
        openAttachments: false,
    }),
    methods: {
        save() {
            this.loading = true
            this.saveDataLoading = true
            let endPoint = '';

            switch (this.dialog_id) {

                case 0:
                    endPoint = 'accept_advance_request';
                    break;
                case 9:
                    endPoint = 'accept_vacation_request';
                    break;
                case 11:
                    endPoint = 'accept_increase_request';
                    break;
                case 3:
                    endPoint = 'accept_allowance_request';
                    break;
                case 4:
                    endPoint = 'accept_permit_request';
                    break;
                case 5:
                    endPoint = 'accept_permit_request';
                    break;
                case 6:
                    endPoint = 'accept_resignation_request';
                    break;
                case 7:
                    endPoint = 'accept_termination_request';
                    break;
                default:
                    break;
            }
            this.$api.POST_METHOD(`${endPoint}/${this.rowDialogData.id}`).then((response) => {
                // this.saveDataLoading = false;
                this.loading = false
                this.saveDataLoading = false
                if (response.check) {
                    console.log("this.form", this.form);
                    console.log("res post", response);
                    this.closeDialogAction();
                    this.backValueMethod();
                    this.confirmModal = false
                }
            })
        },
        downloadAttachment(imageUrl, rowDialogData) {
            // Create an image element to load the image
            const img = new Image();
            img.crossOrigin = "anonymous"; // Enable cross-origin requests if necessary

            img.onload = function () {
                // Create a canvas to convert the image to base64
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;

                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                // Convert the image to base64
                const fileExtinction = imageUrl.lastIndexOf(".");
                const base64Data = canvas.toDataURL(`image/${imageUrl.slice(fileExtinction + 1)}`); // You can change the format if needed

                // Create a download link for the base64 image
                const anchor = document.createElement("a");
                anchor.href = base64Data;
                var fileName = `${rowDialogData?.id}-${rowDialogData?.employee_name}${imageUrl.slice(fileExtinction)}`
                anchor.download = fileName; // Set the desired filename and extension

                // Trigger the download
                anchor.click();
            };

            img.src = imageUrl;
        }
    },

    mounted() {
        // this.getData()
        console.log('rowDialogData', this.rowDialogData);
    },
    props: {
        openDialogAction: { default: false },
        // confirmModal: { default: false },
        openDialogSug: { default: false },
        title: { type: String },
        dialog_id: { type: Number },
        rowDialogData: { type: Object },
        tab: { type: Number },
        status: { type: Number },
        closeDialogAction: { type: Function },
        backValueMethod: { type: Function },
        // selectState_id: { type: Number },
    },
    components: { RejectedReasonDialog, DialogSuggestion, AnimatedLoading, DialogInfo, ConfirmModal },

    computed: {

        pendingState() {
            switch (this.dialog_id) {
                case 0:
                    return this.rowDialogData.status == 1
                case 3:
                    return this.rowDialogData.status == 1
                case 4:
                    return this.rowDialogData.status == 1
                case 5:
                    return this.rowDialogData.status == 1
                case 6:
                    return this.rowDialogData.status == 1
                case 7:
                    return this.rowDialogData.status == 1
                case 9:
                    return this.rowDialogData.status == 1
                case 11:
                    return this.rowDialogData.status == 1
                case 13:
                    return this.rowDialogData.status == 1
                default:
                    return []
            }
        }
    }
}

</script>

<style lang="scss" scoped>

</style>
