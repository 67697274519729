<template>
    <v-dialog v-model="openDialog" persistent width="490">
        <v-card class="pa-5" rounded="rounded">
            <v-form ref="form" v-model="valid">
                <v-card-title class="text-h6 px-0 font-weight-bold">
                    {{ $t('Restart') }}
                </v-card-title>

                <v-row>
                    <GenericInput type="date" :value="form.from_date" @input="form.from_date = $event" label="from date"
                        :required="true" :isLoading="false" :clearable="true" :cols="[12, 12, 12]">
                    </GenericInput>

                    <GenericInput type="date" :value="form.to_date" @input="form.to_date = $event" label="to date"
                        :minDate="form.start_date" :required="true" :isLoading="false" :clearable="true"
                        :cols="[12, 12, 12]">
                    </GenericInput>

                    <GenericInput type="switch" class="my-3" :value="is_apply_count" @input="is_apply_count = $event"
                        label="Choose the number of applicants" :isLoading="false" :required="false" :cols="[12, 12, 12]">
                    </GenericInput>

                    <v-scroll-x-reverse-transition>
                        <GenericInput v-if="is_apply_count" type="number" :value="Math.abs(form.applicants_needed)"
                            @input="form.applicants_needed = $event" label="Number of applicants"
                            placeholder="The number of applicants you want" :isLoading="false" :clearable="true"
                            :required="true" :cols="[12, 12, 12]">
                        </GenericInput>
                    </v-scroll-x-reverse-transition>

                    <v-card-actions style="width: 100%;" class="mt-3">
                        <v-btn :loading="loading" depressed @click="closeDialog" color="gray11" class="error--text "
                            min-width="192" height="50">
                            {{ $t('cancel') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="save" :disabled="!valid" depressed color="blue5" class="white--text" min-width="192"
                            height="50" :loading="loading">
                            {{ $t('Restart') }}
                        </v-btn>
                    </v-card-actions>
                </v-row>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "RestartDialog",
    props: {
        openDialog: { default: false },
        closeDialog: { type: Function },
        getData: { type: Function },
        rowData: { default: Object },
    },
    data: () => ({
        form: {
            from_date: null,
            to_date: null,
            applicants_needed: null,
            job_id: null,
        },
        is_apply_count: 1,
        valid: false,
        loading: false,
    }),
    watch: {
        openDialog() {
            if (this.openDialog) {
                this.form.job_id = this.rowData.job_id
            }
        },
    },
    methods: {
        save() {
            this.loading = true
            // this.form._method = 'PUT'
            this.$api.POST_METHOD(`career`, this.form).then((response) => {
                this.loading = false
                if (response.check) {
                    console.log("response", response);
                    this.closeDialog()
                    this.getData()
                    this.$refs.form.reset()
                }
            })
        },
    },
    computed: {},
}


</script>

<style lang="scss" scoped></style>
