<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" />
      <!-- End Of Header -->

      <v-row class="mt-1" v-if="pageData.rows.length > 0">
        <v-col cols="12" sm="12" v-for="(row, index) in pageData.rows" :key="index">
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 3 : 0" class="shadow d-block" color="backgroundW pa-1">
              <v-row align="center">
                <v-col cols="3" sm="3">
                  <v-list-item class="pa-0 px-1">
                    <v-list-item-avatar :color="$store.state.Settings.darkTheme == true ? '#181818' : 'grey lighten-4'">
                      <v-img v-if="row.image_url" class="elevation-0" alt="qawaem" :src="$api.serverUrl + row.image_url">
                      </v-img>
                      <v-icon v-if="!row.image_url">mdi-file-image-outline</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content class="pa-0">
                      <v-list-item-title>{{ row.username }}</v-list-item-title>
                      <v-list-item-subtitle class="text--disabled">
                        {{
                          row.title_name_ar
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="2">
                  <v-list-item-content class="pa-0">
                    <v-list-item-title>{{ $t("mobileNumber") }}</v-list-item-title>
                    <v-list-item-subtitle class="text--disabled subtitle-2">
                      {{
                        row.mobile
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-col>
                <v-col cols="2">
                  <v-list-item-content class="pa-0">
                    <v-list-item-title>{{ $t("Email") }}</v-list-item-title>
                    <v-list-item-subtitle class="text--disabled subtitle-2">
                      {{
                        row.email
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-col>
                <v-col cols="2">
                  <v-list-item-content class="pa-0">
                    <v-list-item-title>{{ $t("company") }}</v-list-item-title>
                    <v-list-item-subtitle class="text--disabled subtitle-2">
                      {{
                        row.default_company
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-col>
                <v-col cols="2">
                  <v-list-item-content class="pa-0">
                    <v-list-item-title>{{ $t("Fiscal year") }}</v-list-item-title>
                    <v-list-item-subtitle class="text--disabled subtitle-2">
                      {{
                        row.default_year
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-col>
                <v-col cols="1" class="pa-0">
                  <div class="d-flex justify-centers">
                    <v-btn icon x-small class="mx-1" color="error" :loading="deleteLoading" @click="deleteMethod(row.id)"
                      v-if="$global.CheckAction('01-008', 4) && pageData.isTrashed == 1">
                      <img style="height: 18px;" src="@/assets/img/svg/delete_icon.svg" alt="qawaem erp" />
                    </v-btn>
                    <v-btn icon x-small class="mx-1" color="primary"
                      v-if="$global.CheckAction('01-008', 3) && pageData.isTrashed == 0" @click="restoreMethod(row)">
                      <img style="height: 18px;" src="@/assets/img/svg/restore.svg" alt="qawaem erp" />
                    </v-btn>
                    <v-btn icon x-small class="mx-1" color="primary" v-if="$global.CheckAction('01-008', 3)"
                      :to="'/main/user-control/' + row.id">
                      <img style="height: 18px;" src="@/assets/img/svg/pen.svg" alt="qawaem erp" />
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-1" v-if="pageData.rows.length == 0 && pageData.isLoading == false">
        <v-col cols="10">
          <v-alert dense outlined type="info">
            {{ $t('No data available') }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row justify="center" v-if="pageData.isLoading">
        <v-col cols="auto" class="my-15">
          <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary" indeterminate>
          </v-progress-circular>
          <p class="text-center">{{ $t("Loading data") }}</p>
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading">
        <v-col cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Settings");
import Pagination from "../../../components/ui/Pagination.vue";
import EntityHeader from "../../../components/ui/EntityHeader.vue";
export default {
  name: "UserEntity",

  data: () => ({
    pageData: {
      screen_code: "01-008",
      url: null,
      controlRoute: "main/user-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
    },
    deleteLoading: false
  }),
  components: {
    Pagination,
    EntityHeader
  },
  computed: {
    ...mapState(["favourites"]),
  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    ...mapActions(["addFavourites"]),

    addToFav() {
      const fav = {
        screen_code: this.pageData.screen_code,
        name: this.pageData.entityName,
        url: this.pageData.url,
      };
      this.addFavourites(fav).finally(() => { });
    },

    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.pageData.rows = []
        this.$api
          .GET_METHOD(
            `user?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search}`
          )
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.page = page;
      this.pageData.rowsNumber = limit;
      this.getData();
    },
    deleteMethod(id) {
      this.$api.POST_METHOD(`user/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`user_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/user/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
  },
};
</script>
