<template>
  <section>
    <v-container fluid>

      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" :showDeleted="false"
        :showSearch="false" :createBtn="false" />
      <!-- End Of Header -->


      <!--Start permissions -->
      <v-row class="mt-6">

        <v-col cols="12">
          <div class="text-h6 gray5--text font-weight-bold">{{ $t("permission") | capitalize }}</div>
        </v-col>

        <v-col cols="auto" md="auto">
          <v-card class="bgc" elevation="0" height="98px" width="206px" @click="dialogData('leave request', 1)">
            <v-card-text class="font-weight-bold text-center hoverColor">
              {{ $t("leave request") | capitalize }}
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="auto" md="auto">
          <v-card class="bgc" elevation="0" height="98px" width="206px" @click="dialogData('delay request', 2)">
            <v-card-text class="font-weight-bold text-center hoverColor">
              {{ $t("delay request") | capitalize }}
            </v-card-text>
          </v-card>
        </v-col>


      </v-row>
      <!--End permissions -->


      <!-- Start Requests  -->
      <v-row class="mt-6">

        <v-col cols="12">
          <div class="text-h6 gray5--text font-weight-bold">{{ $t("requests") | capitalize }}</div>
        </v-col>

        <v-col cols="auto" md="auto">
          <v-card class="bgc" elevation="0" height="98px" width="206px" @click="dialogData('vacation request', 3)">
            <v-card-text class="font-weight-bold text-center hoverColor">
              {{ $t("vacation request") | capitalize }}
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="auto" md="auto">
          <v-card class="bgc" elevation="0" height="98px" width="206px" @click="dialogData('increase request', 4)">
            <v-card-text class="font-weight-bold text-center hoverColor">
              {{ $t("increase request") | capitalize }}
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="auto" md="auto">
          <v-card class="bgc" elevation="0" height="98px" width="206px" @click="dialogData('allowance request', 5)">
            <v-card-text class="font-weight-bold text-center hoverColor">
              {{ $t("allowance request") | capitalize }}
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="auto" md="auto">
          <v-card class="bgc" elevation="0" height="98px" width="206px" @click="dialogData('resignation request', 6)">
            <v-card-text class="font-weight-bold text-center hoverColor">
              {{ $t("resignation request") | capitalize }}
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="auto" md="auto">
          <v-card class="bgc" elevation="0" height="98px" width="206px" @click="dialogData('termination request', 7)">
            <v-card-text class="font-weight-bold text-center hoverColor">
              {{ $t("termination request") | capitalize }}
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="auto" md="auto">
          <v-card class="bgc" elevation="0" height="98px" width="206px" @click="dialogData('advance request', 8)">
            <v-card-text class="font-weight-bold text-center hoverColor">
              {{ $t("advance request") | capitalize }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- End Requests  -->


      <!-- Start Dialog -->
      <RequestDialog :openDialog="openDialog" :vacation_types="vacation_types" :employee="employee"
        :allowances_types="allowances_types" :allowances="allowances" :closeDialog="() => openDialog = false"
        :dialogData="dialogData" :title="dialog_title" :dialog_id="dialog_id" :printMethod="printMethod" />
      <!-- End Dialog -->


      <FollowRequestPrint :printAction="() => print = false" :print="print" :printData="printData"
        :printStaticData="printStaticData" />

    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import FollowRequestPrint from "../FollowRequest/FollowRequestPrint.vue";
import RequestDialog from "./RequestDialog.vue";

export default {
  name: "Request",

  data: () => ({
    pageData: {
      screen_code: "09-010",
      url: null,
      controlRoute: null,
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      controlDialog: false,
      controlID: null,
      queryParam: '?'
    },
    printData: [],
    printStaticData: Object,
    print: false,
    employee: [],
    allowances: [],
    allowances_types: [],
    vacation_types: [],
    dialog_title: "",
    dialog_id: 0,
    openDialog: false,

  }),
  components: {
    EntityHeader,
    RequestDialog,
    FollowRequestPrint,
  },
  computed: {
  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
      // this.pageData.isLoading = true;
    },

  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
    this.pageData.isLoading = true;

  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        // { text: "#", key: "id", type: 'text', classes: "" },
        // { text: "name_ar", key: "name_ar", type: 'text', classes: "" },
        // { text: "name_en", key: "name_en", type: 'text', classes: "" },
        // { text: "relative account", key: "account_name", type: 'text', classes: "" },
        // { text: "created_at", key: "add_date", type: 'date', classes: "" },
        // { text: "activation", key: "active", type: 'switch', classes: "" },
        // { text: "actions", key: "id", type: 'actions', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },

    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${1}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`create_request`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.employee = response.data.employee
              this.allowances = response.data.allowances
              this.allowances_types = response.data.allowances_types
              this.vacation_types = response.data.vacation_types

            }
          });
      } else {
        this.$router.push("/");
      }
    },

    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },



    dialogData(param, id) {
      this.dialog_title = param
      this.dialog_id = id
      this.openDialog = true

    },
    printMethod(row, requestName) {
      console.log('row', row, 'requestName', requestName);
      row.request_name = requestName
      this.printData = [row];
      this.printStaticData.master = row;
      this.print = true;
      setTimeout(() => {
        this.printData = [];
        this.printStaticData = Object;
        this.print = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.bgc {
  background-color: #ECEEF0;
  border-radius: 12px;
  background-image: url("../../../../assets/img/png/Frame.png");
  background-position: right;
  cursor: pointer;

}

.bgc:hover {
  background-color: #143E72;
  background-image: url("../../../../assets/img/svg/hover-request.svg");
}

.hoverColor {
  line-height: 4rem;

}

.bgc:hover .hoverColor {
  color: #fff;
  line-height: 4rem;
}
</style>