<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" :showDeleted="false"
        :showSearch="false" :createBtn="false" />
      <!--End Header -->

      <!-- Start Tab -->
      <v-card class="mt-2 shadow-none">
        <v-tabs center-active v-model="tab" class="font-weight-bold gray2--text text-capitalize">
          <v-tab ><strong>{{ $t('advance payment request') }}</strong></v-tab>

          <v-tab ><strong>{{ $t('vacation requests') }}</strong></v-tab>

          <v-tab ><strong>{{ $t('salary increase requests') }}</strong></v-tab>

          <v-tab ><strong>{{ $t('allowance requests') }}</strong></v-tab>

          <v-tab ><strong>{{ $t('leave permission requests') }}</strong></v-tab>

          <v-tab ><strong>{{ $t('delayed permission requests') }}</strong></v-tab>

          <v-tab ><strong>{{ $t('resignation requests') }}</strong></v-tab>

          <v-tab ><strong>{{ $t('contract termination requests') }}</strong></v-tab>

          <v-tab ><strong>{{ $t('salary disbursement') }}</strong></v-tab>
        </v-tabs>
        <!-- End end tab -->

        <v-divider class="gray11"></v-divider>

        <!-- Global Requests -->
        <v-row align="center" v-if="tab !== 1 && tab !== 2 && tab !== 8">

          <!-- Title and Filter -->
          <v-row class="pa-6 mt-3 mx-2" align="center">
            <!-- Title -->
            <v-col cols="12" md="10">
              <div v-if="tab !== null" class="font-weight-bold mx-6">
                {{ $t(followRequest.name) | capitalize }}
              </div>
            </v-col>

            <!-- Filter -->
            <GenericInput type="select" :lookups="selectState" :value="selectState_id" @input="selectState_id = $event"
              label="" :multi="false" :required="false" selected_label="name" selected_prop="id" :isLoading="false"
              :cols="[2, 2, 2]" icon="mdi-chevron-down">
            </GenericInput>
          </v-row>


          <v-col cols="12" sm="12">
            <DynamicTable class="shadow-none" :isLoading="pageData.isLoading" :data="pageData.rows"
              :header="pageData.tableHeader" :option="pageData.options" :pageData="pageData" :rowClicked="rowClicked">
              <template v-slot:th="{ header }">
                {{ $t(header.text) }}
              </template>

              <template v-slot:td="{ row, header, index }">
                <div v-if="header.key == 'printSlot'">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" x-small v-on="on" :loading="row.printLoading"
                        @click.stop="printMethod(row, index, followRequest.print_title)" icon>
                        <img src="@/assets/img/svg_icons/fi_printer.svg" height="18" />
                      </v-btn>
                    </template>
                    <span>{{ $t('print') }}</span>
                  </v-tooltip>
                </div>

                <div v-if="header.key == 'attachments'">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" x-small v-on="on" :loading="row.printLoading"
                        @click.stop="openDialogAttach = true, rowDialogData = row" icon>
                        <img src="@/assets/img/svg_icons/attach.svg" height="18" />
                      </v-btn>
                    </template>
                    <span>{{ $t('attachments') }}</span>
                  </v-tooltip>
                </div>

                <div v-if="header.key == 'employee_name'">
                  <a @click="profileId(row)" class="text-decoration-underline" text color="info">
                    {{ row.employee_name }}</a>
                </div>

                <div v-if="header.key == 'slot_key'" class="d-flex font-weight-bold" @click.stop>

                  <div class="mx-5 yellow6--text" v-if="row.status == 1">
                    {{ $t('pending') }}
                  </div>

                  <div class="mx-5 green5--text" v-if="row.status == 2">
                    {{ $t('approved') }}
                  </div>

                  <div class="mx-5  red5--text" v-if="row.status == 3">
                    {{ $t('Rejected') }}
                  </div>

                  <span class="refuse_icon cursor_pointer" v-if="row.status == 3"
                    @click="() => rowClickedDialogInfo(row)">
                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <img v-bind="attrs" v-on="on" src="@/assets/img/svg/reason.svg" alt="">
                      </template>

                      <span>{{ row.refuse_reason }}</span>
                    </v-tooltip>
                  </span>

                </div>

                <div v-if="header.key == 'one_time'">
                  {{ $global.FilterArrayOfObjectElement(allowance_type, "id", row.one_time, 'name') }}
                </div>

              </template>
            </DynamicTable>
          </v-col>

        </v-row>

        <!-- Vacation Requests -->
        <v-row v-if="tab == 1" @click="dialogData('', 1)">
          <v-col cols="12" sm="12">
            <VacationRequestsToggle :request_type="tab + 1" />
          </v-col>
        </v-row>

        <!-- Salary Increase -->
        <v-row v-if="tab == 2" @click="dialogData('', 2)">
          <v-col cols="12" sm="12">
            <SalaryIncreaseToggle :request_type="tab + 1" />
          </v-col>
        </v-row>

        <!-- Salary Disbursement Toggle -->
        <v-row v-if="tab == 8" @click="dialogData('', 8)">
          <v-col cols="12" sm="12">
            <SalaryDisbursementToggle :request_type="tab + 1" />
          </v-col>
        </v-row>
      </v-card>

      <!-- Pagination -->
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0"
        v-show="tab == 0 || tab == 3 || tab == 4 || tab == 5 || tab == 6 || tab == 7">
        <v-col class="mt-5" cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>

      <DialogInfo :openDialogInfo="openDialogInfo" :closeDialog="() => openDialogInfo = false"
        :rowDialogData="rowDialogData" />

      <AgreeDialog :openDialogAction="openDialogAction" :closeDialogAction="() => openDialogAction = false"
        :dialog_id="tab" :tab="tab" :status="status" :rowDialogData="rowDialogData" :backValueMethod="getData"
        :selectState_id="selectState_id" />

      <FollowRequestPrint :printAction="() => print = false" :print="print" :printData="printData"
        :printStaticData="printStaticData" />

      <FollowRequestAttach :openDialogAttach="openDialogAttach" :closeDialog="() => openDialogAttach = false"
        :rowDialogData="rowDialogData" :request_type="tab + 1" :callBackMethod="() => getData()" />
    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import DialogInfo from "./DialogInfo.vue";
import AgreeDialog from "./AgreeDialog.vue";
import VacationRequestsToggle from "./VacationRequestsToggle.vue";
import SalaryIncreaseToggle from "./SalaryIncreaseToggle.vue";
import SalaryDisbursementToggle from "./SalaryDisbursementToggle.vue";
import FollowRequestPrint from "./FollowRequestPrint.vue";
import FollowRequestAttach from "./FollowRequestAttach.vue";
export default {
  name: "FollowRequest",

  data: () => ({
    pageData: {
      screen_code: "09-011",
      url: null,
      controlRoute: "codes/follow-request-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?',
    },
    allowance_type: [],
    rowDialogData: {},
    printData: [],
    printStaticData: Object,
    print: false,
    selectState: [],
    selectState_id: 1,
    dialog_id: 0,
    status: null,
    tab: null,
    openDialogInfo: false,
    openDialogAction: false,
    openDialogAttach: false,
  }),
  components: {
    EntityHeader,
    Pagination,
    DynamicTable,
    GenericInput,
    DialogInfo,
    AgreeDialog,
    VacationRequestsToggle,
    SalaryIncreaseToggle,
    SalaryDisbursementToggle,
    FollowRequestPrint,
    FollowRequestAttach
  },
  computed: {
    followRequest() {
      switch (this.tab) {
        case 0: return { name: 'advance payment request', print_title: 'advance request' }
        case 1: return { name: 'vacation requests', print_title: 'vacation request' }
        case 2: return { name: 'salary increase requests', print_title: 'increase request' }
        case 3: return { name: 'allowance requests', print_title: 'allowance request' }
        case 4: return { name: 'leave permission requests', print_title: 'leave request' }
        case 5: return { name: 'delayed permission requests', print_title: 'delay request' }
        case 6: return { name: 'resignation requests', print_title: 'resignation request' }
        case 7: return { name: 'contract termination', print_title: 'termination request' }
        case 8: return { name: 'salary disbursement', print_title: 'salary payment request' }
        default: return this.tab
      }
    },
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.allowance_type = [
      {
        id: 0,
        name: this.$i18n.t('monthly')
      },
      {
        id: 1,
        name: this.$i18n.t('one time')
      }
    ]
  },
  watch: {
    $route() {
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    selectState_id() {
      this.getData();
    },
    tab() {
      switch (this.tab) {

        // advance payment request
        case 0:
          this.selectState_id = 1
          this.pageData.tableHeader = [
            { text: "serial", key: "id", type: 'text', classes: "cursor_pointer" },
            { text: "date of request", key: "add_date", type: 'date', classes: "cursor_pointer" },
            { text: "employee name", key: "employee_name", type: 'slot', classes: "cursor_pointer" },
            { text: "amount", key: "amount", type: 'float', classes: "" },
            { text: "number of installments", key: "installments_count", type: 'text', classes: "" },
            { text: "status", key: "slot_key", export_key: 'printStatus', type: 'slot', classes: "" },
            { text: "attachments", key: "attachments", type: 'slot', classes: "", hideInPrint: true },
            { text: "actions", key: "printSlot", type: 'slot', classes: "", hideInPrint: true },
          ];

          this.getData()
          break;

        // allowance requests
        case 3:
          this.selectState_id = 1
          this.pageData.tableHeader = [
            { text: "serial", key: "id", type: 'text', classes: "cursor_pointer" },
            { text: "date of request", key: "add_date", type: 'date', classes: "cursor_pointer" },
            { text: "employee name", key: "employee_name", type: 'slot', classes: "cursor_pointer" },
            { text: "allowance name", key: "allowance_name", type: 'text', classes: "" },
            { text: "allowance type", key: "one_time", type: 'slot', classes: "" },
            { text: "the value", key: "amount", type: 'float', classes: "" },
            { text: "status", key: "slot_key", export_key: 'printStatus',type: 'slot', classes: "" },
            { text: "attachments", key: "attachments", type: 'slot', classes: "", hideInPrint: true },
            { text: "actions", key: "printSlot", type: 'slot', classes: "", hideInPrint: true },
          ];

          this.getData()
          break;

        // leave permission requests
        case 4:
          this.selectState_id = 1
          this.pageData.tableHeader = [
            { text: "serial", key: "id", type: 'text', classes: "cursor_pointer" },
            { text: "date of request", key: "add_date", type: 'date', classes: "cursor_pointer" },
            { text: "employee name", key: "employee_name", type: 'slot', classes: "cursor_pointer" },
            { text: "check out time", key: "from_time", type: 'date', classes: "" },
            { text: "return time", key: "to_time", type: 'date', classes: "" },
            { text: "status", key: "slot_key", export_key: 'printStatus', type: 'slot', classes: "" },
            { text: "attachments", key: "attachments", type: 'slot', classes: "", hideInPrint: true },
            { text: "actions", key: "printSlot", type: 'slot', classes: "", hideInPrint: true },
          ];

          this.getData()
          break;

        // delayed permission requests
        case 5:
          this.selectState_id = 1
          this.pageData.tableHeader = [
            { text: "serial", key: "id", type: 'text', classes: "cursor_pointer" },
            { text: "date of request", key: "add_date", type: 'date', classes: "cursor_pointer" },
            { text: "employee name", key: "employee_name", type: 'slot', classes: "cursor_pointer" },
            { text: "arrival time", key: "to_time", type: 'date', classes: "" },
            { text: "hours late", key: "hours_late", type: 'text', classes: "" },
            { text: "status", key: "slot_key", export_key: 'printStatus', type: 'slot', classes: "" },
            { text: "attachments", key: "attachments", type: 'slot', classes: "", hideInPrint: true },
            { text: "actions", key: "printSlot", type: 'slot', classes: "", hideInPrint: true },
          ];

          this.getData()
          break;

        // resignation requests
        case 6:
          this.selectState_id = 1
          this.pageData.tableHeader = [
            { text: "serial", key: "id", type: 'text', classes: "cursor_pointer" },
            { text: "date of request", key: "add_date", type: 'date', classes: "cursor_pointer" },
            { text: "employee name", key: "employee_name", type: 'slot', classes: "cursor_pointer" },
            { text: "status", key: "slot_key", export_key: 'printStatus', type: 'slot', classes: "" },
            { text: "attachments", key: "attachments", type: 'slot', classes: "", hideInPrint: true },
            { text: "actions", key: "printSlot", type: 'slot', classes: "", hideInPrint: true },
          ];

          this.getData()
          break;

        // contract termination requests
        case 7:
          this.selectState_id = 1
          this.pageData.tableHeader = [
            { text: "serial", key: "id", type: 'text', classes: "cursor_pointer" },
            { text: "date of request", key: "add_date", type: 'date', classes: "cursor_pointer" },
            { text: "employee name", key: "employee_name", type: 'slot', classes: "cursor_pointer" },
            { text: "status", key: "slot_key", export_key: 'printStatus', type: 'slot', classes: "" },
            { text: "attachments", key: "attachments", type: 'slot', classes: "", hideInPrint: true },
            { text: "actions", key: "printSlot", type: 'slot', classes: "", hideInPrint: true },
          ];

          this.getData()
          break;

        default:
          break;

      }
    }
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.selectState = [
        {
          id: 0,
          name: this.$i18n.t('view all')
        },
        {
          id: 1,
          name: this.$i18n.t('pending')
        },
        {
          id: 2,
          name: this.$i18n.t('accepted requests')
        },
        {
          id: 3,
          name: this.$i18n.t('rejected requests')
        },
      ],
        this.pageData.options = {
          print: this.$global.CheckAction(this.pageData.screen_code, 5),
          delete: this.$global.CheckAction(this.pageData.screen_code, 4),
          edit: this.$global.CheckAction(this.pageData.screen_code, 3),
          restore: this.$global.CheckAction(this.pageData.screen_code, 4),
          view: this.$global.CheckAction(this.pageData.screen_code, 1),
          switch: false,
          selectRow: false,
          sortBy: "id",
          tableHeader: false,
          searchInput: false,
          printButton: false,
          handleClickRow: false
        };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${1}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`follow_request${this.pageData.queryParam}&request_type=${this.tab + 1 || 0}&status=${this.selectState_id == 0 ? '' : this.selectState_id}`).then((response) => {
            this.pageData.isLoading = false;

            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.rows.forEach(row => {
                switch(row.status) {
                  case 1 : return row.printStatus = this.$i18n.t('pending')
                  case 2 : return row.printStatus = this.$i18n.t('approved')
                  case 3 : return row.printStatus = this.$i18n.t('Rejected')
                }
              })
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    rowClicked(row) {
      console.log('row', row)
      this.rowDialogData = row
      this.rowDialogData.allowance_one_time = this.$global.FilterArrayOfObjectElement(this.allowance_type, "id", row?.one_time, 'name')
      this.openDialogAction = true
    },

    rowClickedDialogInfo(row) {
      console.log('row', row)
      this.rowDialogData = row
      this.rowDialogData.allowance_one_time = this.$global.FilterArrayOfObjectElement(this.allowance_type, "id", row?.one_time, 'name')
      this.openDialogInfo = true

    },
    printMethod(row, index, requestName) {
      row.printLoading = true
      row.request_name = requestName
      this.printData = [this.pageData.rows];
      this.printStaticData.master = row;
      this.printStaticData.master.allowance_one_time = this.$global.FilterArrayOfObjectElement(this.allowance_type, "id", row?.one_time, 'name')
      this.printStaticData.contract = row;
      this.print = true;
      setTimeout(() => {
        this.pageData.rows[index].printLoading = false
        this.pageData.rows = [...this.pageData.rows]
        this.printData = [];
        this.printStaticData = Object;
        this.print = false;
      }, 1000);
    },
    profileId(row) {
      console.log('row', row);
      this.$router.push(`/codes/employee-control/${row.employee_id}`);

    },
    dialogData(param, id) {
      this.dialog_title = param
      this.dialog_id = id
      this.openDialog = true
    }
  }
};
</script>

<style lang="scss" scoped>
#pdfViewer {
  .refuse_icon{
    display: none !important;
  }
}

</style>