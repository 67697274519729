<template>
    <v-dialog v-model="openDialog" persistent width="480">
        <v-card class="pa-5" rounded="rounded">
            <v-form ref="form" v-model="valid">
                <v-card-title class="text-h6 px-0 font-weight-bold">
                    {{ $t('Create the link') }}
                </v-card-title>

                <v-row align="center">
                    <GenericInput type="select" :value="link" @input="link = $event"
                        label="Choose the entity where you want this link to appear" :lookups="platforms"
                        selected_label="name" :required="false" :multi="false" selected_prop="platform_id"
                        :isLoading="false" :cols="[10, 10, 10]" />

                    <v-col cols="2" class="mt-5 pa-0">
                        <v-btn depressed color="green12" height="39" @click="showAddLink = !showAddLink">
                            <v-icon color="green1">mdi mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </v-col>

                    <v-scroll-y-transition>
                        <v-col cols="12" v-if="showAddLink">
                            <v-card class="pa-3 add_link" elevation="0">
                                <v-btn :loading="loading" :disabled="!valid" @click="addLink" depressed color="green1" class="white--text mb-3"
                                    width="100%" height="35">
                                    {{ $t('add') }}
                                </v-btn>

                                <GenericInput type="text" :value="form.name" @input="form.name = $event"
                                    label="Name of the destination" paddingX="px-0" :required="true" :isLoading="false"
                                    :cols="[12, 12, 12]" />
                            </v-card>
                        </v-col>
                    </v-scroll-y-transition>

                    <v-card-actions style="width: 100%;" class="mt-3">
                        <v-btn :loading="loading" depressed @click="close" color="gray11" class="error--text "
                            min-width="192" height="50">
                            {{ $t('back') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="copyLink"  depressed color="blue5" class="white--text"
                            min-width="192" height="50" :loading="loading">
                            <span>{{ $t('Copy link') }}</span>
                            <v-icon class="mx-1">mdi mdi-attachment</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-row>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ShareDialog",
    props: {
        openDialog: { default: false },
        closeDialog: { type: Function },
        rowData: { default: Object },
    },
    data: () => ({
        form: {
            name: null,
            job_ad_id: null,
        },
        link: null,
        valid: false,
        showAddLink: false,
        loading: false,
        platforms: []
    }),
    watch: {
        openDialog() {
            if (this.openDialog && !this.platforms.length) {
                this.platforms = [...this.rowData.social_links]
            }
        },

    },
    methods: {
        addLink() {
            if (this.valid) {
                this.form.job_ad_id = this.rowData.data.job_ad_id
                this.loading = true
                this.$api.POST_METHOD(`platform`, this.form).then((response) => {
                    this.loading = false
                    if (response.check) {
                        console.log("addLink", response);
                        this.showAddLink = false;
                        this.platforms.unshift(response.data)
                        this.form.name = null;
                        this.link = response.data.platform_id;
                        this.copyLink()
                    }
                })
            }

        },
        copyLink() {
            let textToCopy = this.platforms.find(platform => platform.platform_id == this.link).link
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    console.log("Text copied to clipboard");
                    this.$store.state.snackbarTitle = this.$i18n.t('The link has been copied');
                    this.$store.state.snackbarType = "success";
                    this.$store.state.showSnackbar = true;
                })
                .catch(err => {
                    console.error("Failed to copy text: " + err);
                });
        },
        close(){
            this.showAddLink = false;
            this.$refs.form.reset()
            this.closeDialog()
        }
    },

}


</script>

<style lang="scss" scoped>
.v-card.add_link {
    border: 2px dashed var(--green1);
}
</style>
