<template>
  <v-col :cols="cols ? cols[0] : 12" :sm="cols ? cols[0] : 12" :md="cols ? cols[1] : 6" :lg="cols ? cols[2] : 4"
    :offset="offset ? offset[0] : 0" :offset-sm="offset ? offset[0] : 0" :offset-md="offset ? offset[1] : 0"
    :offset-lg="offset ? offset[2] : 0" class="formControl"
    :class="`${paddingY} ${paddingX} ${handelBorderBottom} ${inputDesign == 'up_label' ? 'd-block' : 'd-flex'}`"
    :dir="dir ? dir : $vuetify.rtl ? 'rtl' : 'ltr'">
    <!-- If Label is Inline -->
    <div :style="{ width: inputDesign == 'up_label' ? '100%' : '30%' }" :class="{ 'mb-1': inputDesign == 'up_label' }"
      v-if="inputDesign !== 'default'">
      <label v-if="label && type !== 'checkbox' && type !== 'switch' && type !== 'dropzone'" class="subtitle-2">
        {{ $t(label) }}
        <span class="error--text" v-if="required == true && allowInput">*</span>
        <span class="font-weight-bold" v-if="type == 'label' || !allowInput">: {{ inputValue }}</span>
      </label>
    </div>

    <div :style="`width:${computedWidth}%`" v-if="allowInput">

      <!-- Text  -->

      <TextInput v-if="type == 'text'" :value="value" :name="name" :label="label" :placeholder="placeholder"
        :inputDesign="inputDesign" :autofocus="autofocus" :required="required" :hideDetails="hideDetails"
        :disabled="disabled" :readonly="readonly" :isLoading="isLoading" :reverse="reverse" :color="color"
        :background="background" :icon="icon" :solo="solo" :dense="dense" :classes="classes" :clearable="clearable"
        :tabKey="(event) => tabKey(event)" :validation_type="validation_type"
        :keydownEnter="(event) => keydownEnter(event)" :focusin="() => focusin()" :focusout="() => focusout()"
        :onChange="(event) => onChange(event)" @input="input = $event" :errorMessage="errorMessage" :hint="hint">
        <template v-slot:append-outer>
          <slot name="append-outer" />
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template v-slot:prepend>
          <slot name="prepend" />
        </template>
      </TextInput>

      <!-- // Text  -->

      <!--  Number -->

      <NumberInput v-if="type == 'number'" :value="value" :name="name" :label="label" :placeholder="placeholder"
        :inputDesign="inputDesign" :required="required" :hideDetails="hideDetails" :disabled="disabled"
        :isLoading="isLoading" :reverse="reverse" :color="color" :background="background" :icon="icon" :solo="solo"
        :dense="dense" :classes="classes" :clearable="clearable" :acceptZero="acceptZero"
        :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)" @input="input = $event"
        :errorMessage="errorMessage" :hint="hint">
        <template v-slot:append-outer>
          <slot name="append-outer" />
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template v-slot:prepend>
          <slot name="prepend" />
        </template>
      </NumberInput>

      <!-- // Number -->

      <!-- Percentage -->

      <PercentageInput v-if="type == 'percentage'" :value="value" :name="name" :label="label" :placeholder="placeholder"
        :inputDesign="inputDesign" :required="required" :hideDetails="hideDetails" :disabled="disabled"
        :isLoading="isLoading" :reverse="reverse" :color="color" :background="background" :icon="icon" :solo="solo"
        :dense="dense" :classes="classes" :clearable="clearable" :acceptZero="acceptZero"
        :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)" @input="input = $event"
        :errorMessage="errorMessage" :hint="hint">
        <template v-slot:append-outer>
          <slot name="append-outer" />
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template v-slot:prepend>
          <slot name="prepend" />
        </template>
      </PercentageInput>

      <!-- // Percentage -->

      <!-- Float Number -->

      <FloatInput v-if="type == 'float'" :value="value" :name="name" :label="label" :placeholder="placeholder"
        :inputDesign="inputDesign" :autofocus="autofocus" :required="required" :hideDetails="hideDetails"
        :disabled="disabled" :readonly="readonly" :isLoading="isLoading" :reverse="reverse" :color="color"
        :background="background" :icon="icon" :solo="solo" :dense="dense" :classes="classes" :clearable="clearable"
        :tabKey="(event) => tabKey(event)" :keydownEnter="(event) => keydownEnter(event)"
        :onChange="(event) => onChange(event)" @input="input = $event" :errorMessage="errorMessage" :hint="hint">
        <template v-slot:append-outer>
          <slot name="append-outer" />
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template v-slot:prepend>
          <slot name="prepend" />
        </template>
      </FloatInput>

      <!-- // Float Number -->

      <!-- Date -->

      <DateInput v-if="type == 'date'" :value="value" :name="name" :label="label" :placeholder="placeholder"
        :inputDesign="inputDesign" :required="required" :hideDetails="hideDetails" :disabled="disabled"
        :isLoading="isLoading" :reverse="reverse" :color="color" :background="background" :icon="icon" :solo="solo"
        :dense="dense" :classes="classes" :clearable="clearable" :acceptZero="acceptZero"
        :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)"
        :isPickerOpened="(event) => isPickerOpened(event)" @input="input = $event" :minDate="minDate" :maxDate="maxDate"
        :datePickerType="datePickerType" :errorMessage="errorMessage" :hint="hint">
        <template v-slot:append-outer>
          <slot name="append-outer" />
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template v-slot:prepend>
          <slot name="prepend" />
        </template>
      </DateInput>

      <!-- // Date -->

      <!-- Time -->

      <TimeInput v-if="type == 'time'" :value="value" :name="name" :label="label" :placeholder="placeholder"
        :inputDesign="inputDesign" :required="required" :hideDetails="hideDetails" :disabled="disabled"
        :isLoading="isLoading" :reverse="reverse" :color="color" :background="background" :icon="icon" :solo="solo"
        :dense="dense" :classes="classes" :clearable="clearable" :acceptZero="acceptZero"
        :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)"
        :isPickerOpened="(event) => isPickerOpened(event)" @input="input = $event" :minDate="minDate" :maxDate="maxDate"
        :errorMessage="errorMessage" :hint="hint">
        <template v-slot:append-outer>
          <slot name="append-outer" />
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template v-slot:prepend>
          <slot name="prepend" />
        </template>
      </TimeInput>

      <!-- // Time -->


      <!-- Textarea -->

      <TextAreaInput v-if="type == 'textarea'" :value="value" :name="name" :label="label" :placeholder="placeholder"
        :inputDesign="inputDesign" :required="required" :hideDetails="hideDetails" :disabled="disabled"
        :isLoading="isLoading" :reverse="reverse" :color="color" :background="background" :icon="icon" :solo="solo"
        :dense="dense" :classes="classes" :clearable="clearable" :keydownEnter="(event) => keydownEnter(event)"
        :onChange="(event) => onChange(event)" @input="input = $event" :rowsNumber="rowsNumber"
        :errorMessage="errorMessage" :hint="hint">
        <template v-slot:append-outer>
          <slot name="append-outer" />
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template v-slot:prepend>
          <slot name="prepend" />
        </template>
      </TextAreaInput>

      <!-- // Textarea -->

      <!-- Email-->

      <EmailInput v-if="type == 'email'" :value="value" :name="name" :label="label" :placeholder="placeholder"
        :inputDesign="inputDesign" :required="required" :hideDetails="hideDetails" :disabled="disabled"
        :isLoading="isLoading" :reverse="reverse" :color="color" :background="background" :icon="icon" :solo="solo"
        :dense="dense" :classes="classes" :clearable="clearable" :keydownEnter="(event) => keydownEnter(event)"
        :onChange="(event) => onChange(event)" @input="input = $event" :errorMessage="errorMessage" :hint="hint">
        <template v-slot:append-outer>
          <slot name="append-outer" />
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template v-slot:prepend>
          <slot name="prepend" />
        </template>
      </EmailInput>

      <!-- // Email-->

      <!-- Password-->
      <PasswordInput v-if="type == 'password'" :value="value" :name="name" :label="label" :placeholder="placeholder"
        :inputDesign="inputDesign" :required="required" :hideDetails="hideDetails" :disabled="disabled"
        :isLoading="isLoading" :reverse="reverse" :color="color" :background="background" :icon="icon" :solo="solo"
        :dense="dense" :classes="classes" :clearable="clearable" :keydownEnter="(event) => keydownEnter(event)"
        :onChange="(event) => onChange(event)" @input="input = $event" :errorMessage="errorMessage" :hint="hint">
        <template v-slot:append-outer>
          <slot name="append-outer" />
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template v-slot:prepend>
          <slot name="prepend" />
        </template>
      </PasswordInput>

      <!-- // Password-->

      <!-- Mobile-->
      <MobileInput v-if="type == 'mobile' || type == 'phone'" :value="value" :name="name" :label="label"
        :placeholder="placeholder" :inputDesign="inputDesign" :required="required" :hideDetails="hideDetails"
        :disabled="disabled" :isLoading="isLoading" :reverse="reverse" :color="color" :background="background"
        :icon="icon" :solo="solo" :dense="dense" :classes="classes" :clearable="clearable"
        :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)" @input="input = $event"
        :errorMessage="errorMessage" :hint="hint">
        <template v-slot:append-outer>
          <slot name="append-outer" />
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template v-slot:prepend>
          <slot name="prepend" />
        </template>
      </MobileInput>

      <!-- // Mobile-->

      <!-- File -->

      <FileInput v-if="type == 'file'" :base64="base64" :value="value" :name="name" :label="label"
        :placeholder="placeholder" :inputDesign="inputDesign" :required="required" :hideDetails="hideDetails"
        :disabled="disabled" :isLoading="isLoading" :reverse="reverse" :color="color" :background="background"
        :icon="icon" :solo="solo" :dense="dense" :classes="classes" :multi="multi" :selected_prop="selected_prop"
        :hideImage="hideImage" :clearable="clearable" :keydownEnter="(event) => keydownEnter(event)"
        :onChange="(event) => onChange(event)" @input="input = $event" :errorMessage="errorMessage" :hint="hint">
        <template v-slot:append-outer>
          <slot name="append-outer" />
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template v-slot:prepend>
          <slot name="prepend" />
        </template>
      </FileInput>

      <!-- // File -->

      <!-- Autocomplete || Select || Multiselect -->

      <AutoCompleteInput v-if="type == 'autocomplete' || type == 'multiselect' || type == 'select'" :value="value"
        :name="name" :label="label" :placeholder="placeholder" :inputDesign="inputDesign" :required="required"
        :hideDetails="hideDetails" :disabled="disabled" :isLoading="isLoading" :reverse="reverse" :color="color"
        :background="background" :icon="icon" :solo="solo" :disableItem="disableItem" :dense="dense" :classes="classes"
        :multi="multi" :lookups="lookups" :selected_prop="selected_prop" :selected_label="selected_label"
        :clearable="clearable" :keydownEnter="(event) => keydownEnter(event)" :onChange="(event) => onChange(event)"
        :tabKey="(event) => tabKey(event)" :has_slot="has_slot" @input="input = $event" :errorMessage="errorMessage"
        :hint="hint">

        <template v-slot:selection="{ item, index, select, selected, disabled }">
          <slot name="selection" :item="item" :index="index" :select="select" :selected="selected" :disabled="disabled" />
        </template>
        <template v-slot:item="{ item }">
          <slot name="item" :item="item" />
        </template>
        <template v-slot:append-outer>
          <slot name="append-outer" />
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template v-slot:prepend>
          <slot name="prepend" />
        </template>
      </AutoCompleteInput>

      <!-- // Autocomplete || Select || Multiselect -->

      <!-- Checkbox -->

      <CheckBoxInput v-if="type == 'checkbox'" :value="value" :name="name" :label="label" :placeholder="placeholder"
        :inputDesign="inputDesign" :required="required" :hideDetails="hideDetails" :disabled="disabled"
        :isLoading="isLoading" :reverse="reverse" :color="color" :background="background" :icon="icon" :solo="solo"
        :dense="dense" :classes="classes" :clearable="clearable" :keydownEnter="(event) => keydownEnter(event)"
        :onChange="(event) => onChange(event)" @input="input = $event">
        <template v-slot:append-outer>
          <slot name="append-outer" />
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template v-slot:prepend>
          <slot name="prepend" />
        </template>
      </CheckBoxInput>


      <!-- // Checkbox -->

      <!-- Radio Group -->

      <RadioInput v-if="type == 'radiogroup'" :value="value" :name="name" :label="label" :placeholder="placeholder"
        :inputDesign="inputDesign" :required="required" :hideDetails="hideDetails" :disabled="disabled"
        :isLoading="isLoading" :reverse="reverse" :color="color" :background="background" :icon="icon" :solo="solo"
        :dense="dense" :classes="classes" :multi="multi" :lookups="lookups" :selected_prop="selected_prop"
        :selected_label="selected_label" :clearable="clearable" :keydownEnter="(event) => keydownEnter(event)"
        :onChange="(event) => onChange(event)" @input="input = $event">
        <template v-slot:append-outer>
          <slot name="append-outer" />
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template v-slot:prepend>
          <slot name="prepend" />
        </template>
      </RadioInput>

      <!-- // Radio Group -->

      <!-- Switch -->

      <SwitchInput v-if="type == 'switch'" :value="value" :name="name" :label="label" :placeholder="placeholder"
        :inputDesign="inputDesign" :required="required" :hideDetails="hideDetails" :disabled="disabled"
        :isLoading="isLoading" :reverse="reverse" :color="color" :background="background" :icon="icon" :solo="solo"
        :dense="dense" :classes="classes" :clearable="clearable" :keydownEnter="(event) => keydownEnter(event)"
        :onChange="(event) => onChange(event)" @input="input = $event">
        <template v-slot:append-outer>
          <slot name="append-outer" />
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template v-slot:prepend>
          <slot name="prepend" />
        </template>
      </SwitchInput>

      <!-- // Switch -->


      <!-- TextEditor -->

      <TextEditor v-if="type == 'texteditor'" :value="value" :name="name" :label="label" :placeholder="placeholder" :readonly="readonly"
        :inputDesign="inputDesign" :required="required" :hideDetails="hideDetails" :disabled="disabled"
        :isLoading="isLoading" :reverse="reverse" :color="color" :background="background" :icon="icon" :solo="solo"
        :dense="dense" :classes="classes" :clearable="clearable" :keydownEnter="(event) => keydownEnter(event)"
        :onChange="(event) => onChange(event)" @input="input = $event">
      </TextEditor>

      <!-- TextEditor -->


      <!-- dropzone -->
      <DropZone v-if="type == 'dropzone'" :value="input" :name="name" :multi="multi"
        :backValueMethod="(images) => { input = images }" :label="label" :placeholder="placeholder" :required="required"
        :isLoading="isLoading" :color="color" :background="background" :icon="icon" :hideDetails="hideDetails" />

      <!-- // dropzone -->
    </div>
  </v-col>
</template>

<script>
export default {
  name: "GenericInput",
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.type == "float") {
          this.$emit("input", Number(val));
        }
        else {
          this.$emit("input", val);
        }
      },
    },
    inputValue() {
      let obj = {};
      let arr = [];
      switch (this.type) {
        case 'text':
        case 'number':
        case 'textarea':
        case 'mobile':
        case 'date':
        case 'time':
          return this.value || '-';
        case 'percentage':
          return this.value + '%' || '-';
        case 'switch':
        case 'checkbox':
          return this.value ? this.$i18n.t('yes') : this.$i18n.t('no');
        case 'radiogroup':
        case 'select':
        case 'multiselect':
        case 'autocomplete':
          if (this.multi && Array.isArray(this.lookups)) {
            this.lookups.forEach(lookup => {
              if (!this.value.includes(lookup)) {
                arr.push(lookup[this.selected_label]);
              }
            });
            return arr.toString() || '-';
          }
          else {
            obj = Array.isArray(this.lookups) ? this.lookups.find((lookup) => lookup[this.selected_prop || 'id'] == this.value) || {} : {};
            return obj[this.selected_label] || '-';
          }
        default:
          return this.value || '-';
      }
    },
    computedWidth() {
      if (this.inputDesign === 'default') {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
          case 'sm': return this.width.at(0) || 100
          case 'md': return this.width.at(1) || 100
          case 'lg':
          case 'xl': return this.width.at(2) || 100
          default:
            return 100
        }
      } else return this.inputDesign === 'up_label' ? 100 : 70
    },
    handelBorderBottom() {
      return this.border_bottom ? 'border_bottom' : ''
    }
  },
  mounted() {

  },
  watch: {
    '$store.state.Settings.darkTheme'() {
      if (this.background) {
        this.background = this.$store.state.Settings.darkTheme ? '#0F0D15' : '#F9F9F9'
      }
    }
  },
  methods: {
    setFloatValue(value) {
      this.input = Number(value);
    }
  },
  components: {},
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: null },
    value: { default: null },
    lookups: { default: null },
    cols: { default: null },
    width: { default: '' },
    offset: { default: null },
    spacer: { default: null },
    required: { default: false },
    validation_type: { default: null },
    readonly: { default: false },
    autofocus: { default: false },
    lookups_value: { default: null },
    multi: { default: null },
    selected_label: { default: null },
    selected_prop: { default: null },
    onChange: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    inputDesign: { default: 'up_label' /* default || up_label || inline_label */ },
    dense: { default: true },
    color: { default: "secondary" },
    background: { default: '#F9F9F9' },
    hideDetails: { default: false },
    icon: { default: "" },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    tabKey: { default: Function },
    disableItem: { default: null },
    minDate: { default: null },
    maxDate: { default: null },
    datePickerType: { default: 'date' },
    isPickerOpened: { default: Function },
    focusin: { default: Function },
    focusout: { default: Function },
    placeholder: { default: null },
    errorMessage: { default: null },
    hint: { default: null },
    paddingY: { default: "py-0" },
    paddingX: { default: null },
    border_bottom: { default: null },
    solo: { default: false },
    acceptZero: { default: false },
    classes: { default: null },
    rowsNumber: { default: 3 },
    dir: { default: null },
    reverse: { default: false },
    has_slot: { default: false },
    base64: { default: true },
    allowInput: { default: true },
  },
  data: () => ({

  }),
};
</script>

<style lang="scss">
.formControl {
  position: relative;
}

.border_bottom::after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #e1e1e1;
  width: 98%;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
}
</style>