import { render, staticRenderFns } from "./DialogInfo.vue?vue&type=template&id=03ee1ad3&scoped=true&"
import script from "./DialogInfo.vue?vue&type=script&lang=js&"
export * from "./DialogInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03ee1ad3",
  null
  
)

export default component.exports