<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod"
            :editForm="pageData.isEdit && !viewForm" :viewForm="() => viewForm = true" />
         <!-- End Of Header -->
         
         <!-- Summery -->
         <v-card class="my-6 shadow ">
            <v-card class="d-flex pa-5" v-if="pageData.isEdit" max-width="650" elevation="0">
               <v-card-text>
                  <h4 class="gray8--text">{{ $t("number of managements") }}</h4>
                  <span class="font-weight-bold text-body-2">{{ managements_count }}</span>
               </v-card-text>
               <v-divider vertical></v-divider>
               <v-card-text>
                  <h4 class="gray8--text">{{ $t("number of departments") }}</h4>
                  <span class="font-weight-bold text-body-2">{{ departments_count }}</span>
               </v-card-text>
               <v-divider vertical></v-divider>
               <v-card-text>
                  <h4 class="gray8--text">{{ $t("number of employees") }}</h4>
                  <span class="font-weight-bold text-body-2">{{ employees_count }}</span>
               </v-card-text>
            </v-card>
            <v-divider width="95%" class="d-block ma-auto" v-if="viewForm === false"></v-divider>
            <ControlSummery :summery="summery" :loading="pageData.isLoading" v-if="pageData.isEdit && !viewForm" />
         </v-card>
         <!-- End of Summery -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3 white shadow rounded-xl" v-if="viewForm">
            <v-row class="align-center my-5">
               <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                  :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 5]"></GenericInput>
               <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en"
                  :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 5]"></GenericInput>
               <GenericInput type="radiogroup" selected_label="name" selected_prop="id" :value="form.company_type"
                  :lookups="companyTypes" @input="form.company_type = $event" label="type of company" :required="true"
                  :isLoading="pageData.editIsLoading" :cols="[12, 6, 5]"></GenericInput>
               <GenericInput type="text" :value="form.company_owner" @input="form.company_owner = $event"
                  label="owner/chairman name" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 5]">
               </GenericInput>
            </v-row>

         </v-form>
         <v-card class="backgroundW shadow overflow-hidden mt-5 pa-0" v-if="!pageData.isLoading" :key="deleteRefresher">

            <v-col cols="12">
               <v-row align="center" justify="space-between">
                  <v-col cols="auto" class="pa-0 px-10">
                     <v-tabs color="secondary" active-class="blue12" v-model="tab">
                        <v-tab class="font-weight-bold"><strong>{{ $t('contracts') }}</strong></v-tab>
                        <v-tab class="font-weight-bold"><strong>{{ $t('licenses') }}</strong></v-tab>
                     </v-tabs>

                  </v-col>
                  <v-col cols="auto" class="py-0" v-if="pageData.isEdit">
                     <v-row>
                        <v-col cols="auto">
                           <v-btn color="primary" outlined class="blue12"
                              :disabled="((tab == 0 && form.company_contracts.length == 0) || (tab == 1 && form.company_licenses.length == 0))"
                              @click="downloadAllAttachment(tab)" depressed :loading="attachDownloadLoading">
                              {{ tab === 0 ? $t('download all contracts') : $t('download all licenses') }}
                              <v-icon size="20" right>mdi-download</v-icon>
                           </v-btn>
                        </v-col>
                        <v-col cols="auto" v-if="viewForm">
                           <v-btn color="primary" depressed @click="attachmentDialog = true"
                              :loading="attachDownloadLoading">
                              {{ tab === 0 ? $t('add contract') : $t('add license') }}
                           </v-btn>
                        </v-col>
                     </v-row>
                  </v-col>
                  <v-col cols="12" class="py-0">
                     <v-divider></v-divider>
                  </v-col>
               </v-row>

            </v-col>

            <v-col cols="12" class="pa-0" v-if="tab === 0" :key="tab">
               <GenericInput type="dropzone" :value="form.company_contracts" paddingY="py-5" paddingX="px-5"
                  @input="form.company_contracts = $event" v-if="!pageData.isEdit" :required="true" :multi="true"
                  :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>
            </v-col>

            <v-col cols="12" v-if="tab === 0 && pageData.isEdit">
               <v-row align="center" class="mt-2" v-if="form.company_contracts.length > 0">
                  <v-col cols="6" class="py-0">
                     <div class="subtitle-1 font-weight-bold">{{ $t('contract name') }}</div>
                  </v-col>
                  <v-col cols="3" class="py-0">
                     <div class="subtitle-1 font-weight-bold">{{ $t('added date') }}</div>
                  </v-col>
                  <v-col cols="3" class="py-0">
                  </v-col>
               </v-row>

               <v-row align="center" v-for="(attach, index) in form.company_contracts" :key="index">
                  <v-col cols="6" class="py-0">
                     <!-- {{attach.file_name || '-'}} -->
                     {{ $t('contract') + '_' + form.name + '_' + attach.company_contract_id + '.'
                        + attach.file_extension
                     }}
                  </v-col>
                  <v-col cols="3" class="py-0" v-if="typeof attach !== 'string'">
                     {{ attach.add_date || '-' }}
                  </v-col>
                  <v-col cols="3" v-if="typeof attach === 'string'" class="py-0">
                     {{ formattedDateTime || '-' }}
                  </v-col>
                  <v-col cols="3" class="py-0">
                     <div class="d-flex justify-end px-5">
                        <v-btn icon color="primary" v-if="typeof attach !== 'string'"
                           @click="downloadAttachment(attach, 0)" :loading="attachDownloadLoading">
                           <v-icon>mdi-download</v-icon>
                        </v-btn>

                        <v-btn icon color="error" v-if="typeof attach === 'string'"
                           @click="handelDelete(index,'contract')" :loading="false">
                           <v-icon>mdi-close-circle-outline</v-icon>
                        </v-btn>
                        <!-- <v-btn icon color="error" :loading="attachDownloadLoading">
                           <v-icon>mdi-delete</v-icon>
                        </v-btn> -->
                     </div>
                  </v-col>
        
               </v-row>
               <v-col v-if="form.company_contracts.length == 0" cols="12">
                  <v-alert type="primary" outlined dense :value="true">
                     {{ $t('no attachment') }}
                  </v-alert>
               </v-col>
            </v-col>
            <v-col cols="12" class="pa-0" v-if="tab === 1" :key="tab">
               <GenericInput type="dropzone" :value="form.company_licenses" paddingY="py-5" paddingX="px-5"
                  @input="form.company_licenses = $event" v-if="!pageData.isEdit" :required="true" :multi="true"
                  :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>
            </v-col>
            <v-col cols="12" v-if="tab === 1 && pageData.isEdit">

               <v-row align="center" class="mt-2" v-if="form.company_licenses.length > 0">
                  <v-col cols="6" class="py-0">
                     <div class="subtitle-1 font-weight-bold">{{ $t('license name') }}</div>
                  </v-col>
                  <v-col cols="3" class="py-0">
                     <div class="subtitle-1 font-weight-bold">{{ $t('added date') }}</div>
                  </v-col>
                  <v-col cols="3" class="py-0">
                  </v-col>
               </v-row>

               <v-row align="center" v-for="(attach, index) in form.company_licenses" :key="index">
                  <v-col cols="6" class="py-0">
                     {{ $t('license') + '_' + form.name + '_' + attach.company_license_id + '.' + attach.file_extension
                     }}
                  </v-col>
                  <v-col cols="3" class="py-0" v-if="typeof attach !== 'string'">
                     {{ attach.add_date || '-' }}
                  </v-col>

                  <v-col cols="3" v-if="typeof attach === 'string'" class="py-0">
                     {{ formattedDateTime || '-' }}
                  </v-col>
                  
                  <v-col cols="3" class="py-0">
                     <div class="d-flex justify-end px-5">
                        <v-btn icon color="primary" v-if="typeof attach !== 'string'"
                           @click="downloadAttachment(attach, 1)" :loading="attachDownloadLoading">
                           <v-icon>mdi-download</v-icon>
                        </v-btn>

                        <v-btn icon color="error" v-if="typeof attach === 'string'"
                           @click="handelDelete(index,'license')" :loading="false">
                           <v-icon>mdi-close-circle-outline</v-icon>
                        </v-btn>
                        
                        <!-- <v-btn icon color="error" :loading="attachDownloadLoading">
                           <v-icon>mdi-delete</v-icon>
                        </v-btn> -->
                     </div>
                  </v-col>
               </v-row>
               <v-col v-if="form.company_licenses.length == 0" cols="12">
                  <v-alert type="primary" outlined dense :value="true">
                     {{ $t('no attachment') }}
                  </v-alert>
               </v-col>
            </v-col>
         </v-card>
         <!-- End of Form -->


         <AttachmentDialog :dialog="attachmentDialog" :entityName="form.name || '-'" :dataKey="'id'" :data="form"
            :uploadKey="tab == 0 ? 'company_contracts' : 'company_licenses'" :multi="false"
            :uploadResource="tab == 0 ? 'upload_company_contract' : 'upload_company_license'"
            :downloadResource="tab == 0 ? 'download_company_contract' : 'download_company_license'"
            :returnData="handelReturnAttachment" :returnMethod="() => attachmentDialog = false" />

      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlSummery from "@/components/ui/ControlSummery.vue";
import AttachmentDialog from "@/views/HRModule/Codes/Company/AttachmentDialog.vue";

export default {
   name: "CompanyControl",

   data: () => ({
      pageData: {
         count: 0,
         screen_code: "09-022",
         url: null,
         controlRoute: "/codes/company-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      attachmentDialog: false,
      viewForm: false,
      attachDownloadLoading: false,
      companyTypes: [],
      summery: [],
      valid: false,
      tab: 0,
      departments_count: 0,
      managements_count: 0,
      employees_count: 0,
      deleteRefresher: 0,
      form: {
         name_en: null,
         name_ar: null,
         company_type: 2,
         company_owner: null,
         company_contracts: [],
         company_licenses: [],
      },
   }),
   components: {
      GenericInput,
      ControlHeader,
      ControlSummery,
      AttachmentDialog
   },
   computed: {
      formattedDateTime() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      const seconds = String(currentDate.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`company/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.departments_count = response.data.data.departments_count;
                     this.managements_count = response.data.data.managements_count;
                     this.employees_count = response.data.data.employees_count;
                     this.form.company_contracts = response.data.company_contracts;
                     this.form.company_licenses = response.data.company_licenses;
                     switch (response.data.data.company_type) {
                        case 1:
                           response.data.data.company_type_title = this.$i18n.t("corporate")
                           break;
                        case 2:
                           response.data.data.company_type_title = this.$i18n.t("company")
                           break;
                     }

                     this.summery = [
                        {
                           label: 'name_ar',
                           value: response.data.data.name_ar || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'name_en',
                           value: response.data.data.name_en || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'company type',
                           value: response.data.data.company_type_title || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'owner name',
                           value: response.data.data.company_owner || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },

                     ]
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`company/create`).then(() => {
                  this.pageData.isLoading = false;
               })
            } else {
               this.$router.push('/')
            }

         }
         this.companyTypes = [
            {
               id: 2,
               name: this.$i18n.t("company")
            },
            {
               id: 1,
               name: this.$i18n.t("corporate")
            },
         ]
      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.form.company_contracts= this.form.company_contracts.filter(contract => typeof contract === 'string')
               this.form.company_licenses= this.form.company_licenses.filter(licenses => typeof licenses === 'string')

               this.$api.POST_METHOD(`company/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                     // this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`company`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      handelReturnAttachment(data) {
         console.log('data', typeof data == 'string');
         if (this.tab == 0) {
            this.form.company_contracts.push(data)
         } else if (this.tab == 1) {
            this.form.company_licenses.push(data)
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`company/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      downloadAttachment(attach, type) {
         this.attachDownloadLoading = true;
         var src = "";
         var download_file_name = "";
         switch (type) {
            case 0:
               src = `download_company_contract/${attach.company_contract_id}`;
               download_file_name = `${this.$i18n.t('contract')}_${this.form.name}_${attach.company_contract_id}.${attach.file_extension}`
               this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
                  this.attachDownloadLoading = false;
               })
               break;
            case 1:
               src = `download_company_license/${attach.company_license_id}`;
               download_file_name = `${this.$i18n.t('license')}_${this.form.name}_${attach.company_license_id}.${attach.file_extension}`
               this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
                  this.attachDownloadLoading = false;
               })
               break;

            default:
               break;
         }
      },
      downloadAllAttachment(type) {
         switch (type) {
            case 0:
               this.form.company_contracts.forEach(attachment => {
                  if (typeof attachment !== 'string') {
                     this.downloadAttachment(attachment, 0)
                  }
               });
               break;
            case 1:
               this.form.company_licenses.forEach(attachment => {
                  if (typeof attachment !== 'string') {
                     this.downloadAttachment(attachment, 1)
                  }
               });
               break;

            default:
               break;
         }
      },
      handelDelete(index , itemType){
         if(itemType === 'contract'){
            this.form.company_contracts.splice(index,1)
         }
         if(itemType === 'license'){
            this.form.company_licenses.splice(index,1)
         }
         
         this.deleteRefresher++
      }

   },
};
</script>
