import BranchesEntity from "@/views/SettingsModule/Branches/BranchesEntity";
import BranchesControl from "@/views/SettingsModule/Branches/BranchesControl";
import MainMenuEntity from "@/views/SettingsModule/MainMenu/MainMenuEntity";
import MainMenuControl from "@/views/SettingsModule/MainMenu/MainMenuControl";
import CategoriesMenuEntity from "@/views/SettingsModule/CategoriesMenu/CategoriesMenuEntity";
import CategoriesMenuControl from "@/views/SettingsModule/CategoriesMenu/CategoriesMenuControl";
import SubMenusEntity from "@/views/SettingsModule/SubMenus/SubMenusEntity";
import SubMenusControl from "@/views/SettingsModule/SubMenus/SubMenusControl";
import UserEntity from "@/views/SettingsModule/UserEntity/UserEntity";
import UserControl from "@/views/SettingsModule/UserEntity/UserControl";
import PermissionsEntity from "@/views/SettingsModule/PermissionsEntity/PermissionsEntity";
import PermissionsControl from "@/views/SettingsModule/PermissionsEntity/PermissionsControl";
import JobTitleControlVue from "@/views/SettingsModule/JobTitle/JobTitleControl.vue";
import JobTitleEntityVue from "@/views/SettingsModule/JobTitle/JobTitleEntity.vue";
import GeneralSettings from "@/views/SettingsModule/GeneralSettings/GeneralSettings";
// Setting Module Routes:-

export const SettingsModuleChildrens = [
  // Branches
  {
    path: "/main/branch",
    name: "BranchesEntityVue",
    component: BranchesEntity,
    meta: {
      screen_code: "01-006",
    },
  },
  {
    path: "/main/branch-control",
    name: "BranchesControlVue",
    component: BranchesControl,
    meta: {
      screen_code: "01-006",
    },
  },
  {
    path: "/main/branch-control/:id",
    name: "BranchesControlVueEdit",
    component: BranchesControl,
    meta: {
      screen_code: "01-006",
    },
  },
  // Main Menu
  {
    path: "/main/screen_main",
    name: "MainMenuEntity",
    component: MainMenuEntity,
    meta: {
      screen_code: "01-001",
    },
  },
  {
    path: "/main/screen_main-control",
    name: "MainMenuControl",
    component: MainMenuControl,
    meta: {
      screen_code: "01-001",
    },
  },
  {
    path: "/main/screen_main-control/:id",
    name: "MainMenuControlEdit",
    component: MainMenuControl,
    meta: {
      screen_code: "01-001",
    },
  },
  // Categories Menu
  {
    path: "/main/screen_cat",
    name: "CategoriesMenuEntity",
    component: CategoriesMenuEntity,
    meta: {
      screen_code: "01-002",
    },
  },
  {
    path: "/main/screen_cat-control",
    name: "CategoriesMenuControl",
    component: CategoriesMenuControl,
    meta: {
      screen_code: "01-002",
    },
  },
  {
    path: "/main/screen_cat-control/:id",
    name: "CategoriesMenuControlVueEdit",
    component: CategoriesMenuControl,
    meta: {
      screen_code: "01-002",
    },
  },
  // Submenu
  {
    path: "/main/screen_sub",
    name: "SubMenusEntity",
    component: SubMenusEntity,
    meta: {
      screen_code: "01-003",
    },
  },
  {
    path: "/main/screen_sub-control",
    name: "SubMenusControl",
    component: SubMenusControl,
    meta: {
      screen_code: "01-003",
    },
  },
  {
    path: "/main/screen_sub-control/:id",
    name: "SubMenusControlEdit",
    component: SubMenusControl,
    meta: {
      screen_code: "01-003",
    },
  },

  // Users
  {
    path: "/main/user",
    name: "UserEntityVue",
    component: UserEntity,
    meta: {
      screen_code: "01-008",
    },
  },
  {
    path: "/main/user-control",
    name: "UserControl",
    component: UserControl,
    meta: {
      screen_code: "01-008",
    },
  },
  {
    path: "/main/user-control/:id",
    name: "UserControlEdit",
    component: UserControl,
    meta: {
      screen_code: "01-008",
    },
  },
  // permissions
  {
    path: "/main/user_permission",
    name: "PermissionsEntity",
    component: PermissionsEntity,
    meta: {
      screen_code: "01-014",
    },
  },
  {
    path: "/main/permissions-control/:id",
    name: "PermissionsControl",
    component: PermissionsControl,
    meta: {
      screen_code: "01-014",
    },
  },
  // Job Title
  {
    path: "/main/title",
    name: "JobTitleEntity",
    component: JobTitleEntityVue,
    meta: {
      screen_code: "01-016",
    },
  },
  {
    path: "/main/title-control",
    name: "JobTitleControlADD",
    component: JobTitleControlVue,
    meta: {
      screen_code: "01-016",
    },
  },
  {
    path: "/main/title-control/:id",
    name: "JobTitleControlEdit",
    component: JobTitleControlVue,
    meta: {
      screen_code: "01-016",
    },
  },
  // General Settings
  {
    path: "/main/settings/:id",
    name: "GeneralSettingsVue",
    component: GeneralSettings,
    meta: {
      screen_code: "01-007",
    },
  },
];
