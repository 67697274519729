<template>
  <v-card class="shadow justify-center my-1 py-0 overflow-hidden backgroundW">
    <v-col cols="12" sm="12" class="pa-0">
      <!-- 
         fixed-header
        height="60vh"
      -->
      <v-data-table  :headers="header" :items="isLoading == false ? data : []" disable-pagination
        calculate-widths :hide-default-header="isLoading" :loading="isLoading"
        :loading-text="$i18n.t('Loading... Please wait')" :no-data-text="$i18n.t('No data available')"
        :sort-by="options.sortBy" hide-default-footer disable-sort class="elevation-0 backgroundW"
        :class="options.handleClickRow ? 'hoverRow' : ''" :search="searchValue ? searchValue : search"
        :show-select="options.selectRow" v-model="selected" @click:row="handleClickRow" :contextmenu:row="contextmenu"
        :dblclick:row="dblclick" :locale="$store.state.Settings.language">


        <!-- eslint-disable-next-line  -->
        <template v-slot:body.append>
          <tr v-if="pageData.footerRow" class="light">
            <td v-for="(footer, i) in pageData.footerRow" :key="i" class="font-weight-bold light" :class="footer.class"
              :colspan="footer.colspan">{{ footer.value }}</td>
          </tr>
        </template>

        <!-- eslint-disable-next-line  -->
        <template v-slot:item.actions="{ item }" color="info">
          <div class="d-flex align-center">
            <v-switch v-if="options.switch == true" inset @mouseup="toggleActiveMethod(item)" color="indigo darken-2"
              hide-details dense class="my-auto" v-model="item.active"></v-switch>
            <v-tooltip bottom v-if="options.print">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" v-if="pageData.printLoading"
                  :loading="(pageData.printLoading.loading == true && pageData.printLoading.id == item[pageData.printLoading.key])"
                  @click="printValueMethod(item)" icon>
                  <v-icon color="info">mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('print') }}</span>
            </v-tooltip>
            <div v-if="isLoading == true">
              <v-icon class="mdi-spin mdi-dark">mdi-loading</v-icon>
            </div>
            <v-tooltip bottom v-if="options.view && !options.edit && isLoading == false" color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="editValueMethod(item)" class="mx-2" color="primary" :size="20">
                  mdi-eye-outline</v-icon>
                <!-- <img class="mx-2 cursor_pointer" style="height: 18px;" v-bind="attrs" v-on="on"
                  @click="editValueMethod(item)" src="../assets/img/svg/edit-icon.svg" alt="qawaem erp" /> -->
              </template>
              <span>{{ $t('show') }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="options.edit && isLoading == false" color="primary">
              <template v-slot:activator="{ on, attrs }">
                <!-- <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editValueMethod(item)"
                  class="mx-2"
                  color="primary"
                  small
                >mdi-square-edit-outline</v-icon>-->
                <img class="mx-2 cursor_pointer" style="height: 18px;" v-bind="attrs" v-on="on"
                  @click="editValueMethod(item)" src="@/assets/img/svg/pen.svg" alt="qawaem erp" />
              </template>
              <span>{{ $t('edit') }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="options.delete && pageData.isTrashed == 1 && isLoading == false" color="error">
              <template v-slot:activator="{ on, attrs }">
                <!-- <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="dialogDeleteItem = item, deleteDialog = true"
                  class="mx-2"
                  color="error"
                  small
                >mdi-minus-box</v-icon>-->
                <img class="mx-2 cursor_pointer" style="height: 18px;" v-bind="attrs" v-on="on"
                  @click="dialogDeleteItem = item, deleteDialog = true" src="@/assets/img/svg/delete_icon.svg"
                  alt="qawaem erp" />
              </template>
              <span>{{ $t('delete') }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="options.restore && pageData.isTrashed == 0 && isLoading == false" color="light">
              <template v-slot:activator="{ on, attrs }">
                <!-- <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="restoreValueMethod(item)"
                  class="mx-2"
                  color="success"
                  small
                >mdi-delete-restore</v-icon>-->
                <img class="mx-2 cursor_pointer" style="height: 20px;" v-bind="attrs" v-on="on"
                  @click="restoreValueMethod(item)" src="@/assets/img/svg/restore.svg" alt="qawaem erp" />
              </template>
              <span class="primary--text">{{ $t('restore') }}</span>
            </v-tooltip>
          </div>
        </template>

        <!-- eslint-disable-next-line  -->
        <template v-slot:item.image="{ item }">
          <v-avatar size="auto" tile>
            <img v-if="item.image" style="max-height: 35px;" class="ma-auto d-block"
              v-lazy="$api.serverUrl + item.image" alt="erp" />
            <v-icon v-if="!item.image">mdi-file-image-outline</v-icon>
          </v-avatar>
        </template>
        <!-- eslint-disable-next-line  -->
        <template v-slot:item.main_image="{ item }">
          <v-avatar size="auto" tile>
            <img v-if="item.main_image" style="max-height: 35px;" v-lazy="$api.serverUrl + item.main_image" alt="erp" />
            <v-icon v-if="!item.main_image">mdi-file-image-outline</v-icon>
          </v-avatar>
        </template>
        <!-- eslint-disable-next-line  -->
        <template v-slot:item.add_date="{ item }">
          <p class="my-auto">
            {{item.add_date}}
            <!-- {{
                new Date(item.add_date).toLocaleDateString(
                  `${$vuetify.rtl == true ? "ar" : "en-UK"}`,
                  {
                    weekday: "short",
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  }
                )
            }} ( {{ new Date(item.add_date).toLocaleTimeString(`${$vuetify.rtl == true ? "ar" : "en-UK"}`) }} ) -->
          </p>
        </template>
      </v-data-table>
      <DeleteConfirmation v-if="deleteDialog == true" :dialog="deleteDialog"
        :item="dialogDeleteItem.name ? dialogDeleteItem.name : null" :backValueMethod="deleteValue">
      </DeleteConfirmation>
    </v-col>
  </v-card>
</template>




<style scoped lang="scss">
#printInvoice {
  @media screen {
    display: none;
  }

  @media print {
    display: block;
  }
}

thead {
  tr {
    &:nth-of-type(1) {
      background-color: #f2f2f2 !important;

      &:hover {
        background-color: #f2f2f2 !important;
      }
    }
  }
}

tr {
  &:hover {
    background: transparent !important;
  }
}

td,
th {
  border-bottom: 0 !important;
}
</style>



<script>
import DeleteConfirmation from "./modals/DeleteConfirmation.vue";
export default {
  name: "DataTable",
  computed: {

  },
  components: {
    DeleteConfirmation
  },
  methods: {
    handleClickRow(row) {
      // console.log(row);
      this.backValueMethod(row);
    },
    deleteValue(status) {
      this.deleteDialog = false;
      if (status == true) {
        if (this.deleteProperty) {
          this.deleteValueMethod(this.dialogDeleteItem[this.deleteProperty])
        } else {

          this.deleteValueMethod(this.dialogDeleteItem.id)
        }
      }
    },
    contextmenu(row) {
      console.log('contextmenu', row);
    },
    dblclick(row) {
      console.log('dblclick', row);
    }
  },
  props: {
    backValueMethod: { type: Function },
    editValueMethod: { type: Function },
    deleteValueMethod: { type: Function },
    printValueMethod: { type: Function },
    restoreValueMethod: { type: Function },
    toggleActiveMethod: { type: Function },
    pageData: { default: {} },
    isLoading: { default: false },
    value: { default: "" },
    data: { default: "" },
    header: { default: "" },
    options: { default: "" },
    searchValue: { default: "" },
    deleteProperty: { default: null },
  },
  data() {
    return {
      search: '',
      selected: [],
      deleteDialog: false,
      dialogDeleteItem: null,
    };
  },
};
</script>
