<template>
    <v-dialog v-model="openRenewalContract" persistent>
        <v-form ref="form" v-model="valid" class="renewalContractForm" :key="refresher">
            <v-card class="pa-2">
                <v-card-actions>
                    <v-card-text class="font-weight-bold text-h6 text-capitalize">
                        {{ employeesContract.items.length === 0 ? $t("create contract") : $t("contract renewal") }}
                    </v-card-text>
                    <v-btn absolute class="mt-2" :style="$vuetify.rtl ? 'left: 10px' : 'right: 10px'" small fab color="red5"
                        text @click="close">
                        <v-icon color="red5">mdi-close</v-icon>
                    </v-btn>
                </v-card-actions>
                <v-col class="d-flex justify-end mb-n10 px-6">
                    <v-btn width="140" height="40" class="white--text success pa-4" @click="save" elevation="0"
                        :disabled="!valid" :loading="loading">
                        {{ $t("save") }}
                    </v-btn>
                </v-col>
                <AnimatedLoading classes="mt-16 white pa-16" :hideText="false" :isLoading="loading" />
                <ContractsControl :employeesContract="employeesContract" :reciveFormData="reciveFormData" v-if="!loading" />

            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import ContractsControl from '../Contracts/ContractsControl.vue';
export default {
    name: "RenewalContractDialog",
    data: () => ({
        valid: false,
        loading: false,
        refresher: 0,
        form: {}
    }),
    methods: {
        save() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.$api.POST_METHOD(`renew_contract/${this.$route.params.id}`, this.form).then((response) => {
                    console.log('save', response);
                    this.loading = false;
                    this.close()
                    this.pushNewData(response.data.item)
                })
            }

        },
        reciveFormData(form, valid) {
            console.log("valid", valid);
            this.form = form
            this.valid = valid
        },
        close() {
            this.closeRenewalContract()
            this.$refs.form.reset();
        }
    },
    watch: {
        openRenewalContract() {
            this.refresher++
        }
    },
    props: {
        openRenewalContract: { default: false },
        closeRenewalContract: { type: Function },
        pushNewData: { type: Function },
        employeesContract: { default: Object },
    },
    components: {
        ContractsControl,
        AnimatedLoading
    }
}
</script>

<style lang="scss" scoped>
.userUploadImage {
    position: absolute;
    top: 0;
    padding: 0;
    opacity: 0;
}

.upload_pictures {
    // position: relative;
    cursor: pointer;

    span {
        overflow: hidden;
    }

    input {
        border-radius: 9px;
        height: 43px;
        width: 250px;
        position: absolute;
        left: -18px;
        opacity: 0;
        cursor: pointer;
    }


}

.renewalContractForm {
    overflow: hidden;

}
</style>
