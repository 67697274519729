<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod"
            :actionBtn="false" actionBtnClass="light text-decoration-underline blue5--text"
            :actionBtnClick="() => attachmentDialog = true" :actionBtnValid="true" actionBtnText="attachments"
            :editForm="pageData.isEdit && !viewForm" :viewForm="() => viewForm = true">
         </ControlHeader>
         <!-- End Of Header -->

         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading" v-if="pageData.isEdit && !viewForm">
            <template v-slot:summerySlot="{ item }">
               <v-row>
                  <v-divider vertical></v-divider>
                  <div v-if="item.key == 'slotKey'">
                     <v-card class="pa-3" elevation="0">
                        <v-row>
                           <v-col cols="12"><v-divider></v-divider></v-col>
                           <div class="d-flex align-center">
                              <span class="subtitle-1 gray5--text">{{ $t('Application exception') }}</span>

                              <v-tooltip top>
                                 <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="mx-3" color="primary" dark v-bind="attrs" v-on="on">
                                       mdi-alert-circle-outline
                                    </v-icon>
                                 </template>
                                 <span>{{ $t('PublicHolidaysExceptions') }}</span>
                              </v-tooltip>
                           </div>

                           <!-- Apply to a category -->
                           <v-col cols="12">
                              <span class="font-weight-bold gray7--text"> {{ $t('Apply to a category') }}</span>
                           </v-col>
                           <v-col cols="auto" v-for="item in categories" :key="item.id">
                              {{ item.name }}
                           </v-col>
                           <v-col cols="12"><v-divider></v-divider></v-col>
                           <!-- End Apply to a category -->

                           <!-- Application to management -->
                           <v-col cols="12">
                              <span class="font-weight-bold gray7--text"> {{ $t('Application to management') }}</span>
                           </v-col>

                           <v-row class="flex-column ma-3">
                              <div v-for="item in companies" :key="item.id">
                                 <v-col cols="12">
                                    <span class="font-weight-bold gray7--text"> {{ item.company_name }}</span>
                                 </v-col>

                                 <div class="d-flex align-center">
                                    <v-col cols="auto" v-for="item in item.managements" :key="item.id">
                                       {{ item.name }}
                                    </v-col>
                                 </div>
                              </div>
                           </v-row>
                           <v-col cols="12"><v-divider></v-divider></v-col>
                           <!-- End Application to management -->

                           <!-- Apply to the department -->
                           <v-col cols="12">
                              <span class="font-weight-bold gray7--text"> {{ $t('Apply to the department') }}</span>
                           </v-col>

                           <v-row class="flex-column ma-3">
                              <div v-for="item in companies" :key="item.id">
                                 <v-col cols="12">
                                    <span class="font-weight-bold gray7--text"> {{ item.company_name }}</span>
                                 </v-col>

                                 <div class="d-flex align-center">
                                    <v-col cols="auto" v-for="item in item.departments" :key="item.id">
                                       {{ item.name }}
                                    </v-col>
                                 </div>
                              </div>
                           </v-row>
                           <!-- End Apply to the department -->
                        </v-row>
                     </v-card>
                  </div>
               </v-row>
            </template>
         </ControlSummery>
         <!-- End of Summery -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3" v-if="viewForm">
            <v-card class="transparent  overflow-hidden py-5" elevation="0">
               <v-row class="align-center">
                  <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="date" :value="form.from_date" @input="form.from_date = $event" label="from date"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="date" :value="form.to_date" @input="form.to_date = $event" label="to date"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="textarea" :value="form.notes" @input="form.notes = $event" label="details"
                     :rowsNumber="4" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                  </GenericInput>

                  <v-card class="pa-3" elevation="0">
                     <v-row>
                        <v-col cols="12"><v-divider></v-divider></v-col>

                        <div class="d-flex align-center">
                           <span class="subtitle-1 gray5--text px-3">{{ $t('Application exception') }}</span>

                           <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                 <v-icon color="primary" dark v-bind="attrs" v-on="on">
                                    mdi-alert-circle-outline
                                 </v-icon>
                              </template>
                              <span>{{ $t('PublicHolidaysExceptions') }}</span>
                           </v-tooltip>
                        </div>

                        <!-- {{ form.categories }}<br> -->
                        <!-- {{ form.managements }}<br> -->
                        <!-- {{ form.departments }}<br> -->

                        <!-- Apply to a category -->
                        <v-col cols="12">
                           <span class="font-weight-bold gray7--text"> {{ $t('Apply to a category') }}</span>
                        </v-col>
                        <v-col cols="auto" v-for="item in categories" :key="item.id">
                           <v-checkbox v-model="form.categories" :label='item.name' :value="item.id"></v-checkbox>
                        </v-col>
                        <v-col cols="12"><v-divider></v-divider></v-col>
                        <!-- End Apply to a category -->

                        <!-- Application to management -->
                        <v-col cols="12">
                           <span class="font-weight-bold gray7--text"> {{ $t('Application to management') }}</span>
                        </v-col>

                        <v-row class="flex-column ma-3">
                           <div v-for="item in companies" :key="item.id">
                              <v-col cols="12">
                                 <span class="font-weight-bold gray7--text"> {{ item.company_name }}</span>
                              </v-col>

                              <div class="d-flex align-center">
                                 <v-col cols="auto" v-for="item in item.managements" :key="item.id">
                                    <v-checkbox v-model="form.managements" :label='item.name'
                                       :value="item.id"></v-checkbox>
                                 </v-col>
                              </div>
                           </div>
                        </v-row>
                        <v-col cols="12"><v-divider></v-divider></v-col>
                        <!-- End Application to management -->

                        <!-- Apply to the department -->
                        <v-col cols="12">
                           <span class="font-weight-bold gray7--text"> {{ $t('Apply to the department') }}</span>
                        </v-col>

                        <v-row class="flex-column ma-3">
                           <div v-for="item in companies" :key="item.id">
                              <v-col cols="12">
                                 <span class="font-weight-bold gray7--text"> {{ item.company_name }}</span>
                              </v-col>

                              <div class="d-flex align-center">
                                 <v-col cols="auto" v-for="item in item.departments" :key="item.id">
                                    <v-checkbox v-model="form.departments" :label='item.name'
                                       :value="item.id"></v-checkbox>
                                 </v-col>
                              </div>
                           </div>
                        </v-row>
                        <!-- End Apply to the department -->
                     </v-row>
                  </v-card>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlSummery from "@/components/ui/ControlSummery.vue";

export default {
   name: "PublicHolidaysControl",

   data: () => ({
      pageData: {
         screen_code: "01-007",
         url: null,
         controlRoute: "/settings_hr/public_holidays-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      viewForm: false,
      summery: [],
      categories: [],
      companies: [],
      valid: false,
      form: {
         notes: null,
         to_date: null,
         from_date: null,
         name_ar: null,
         name_en: null,
         categories: [],
         managements: [],
         departments: [],
      },
   }),
   components: {
      GenericInput,
      ControlHeader,
      ControlSummery,
   },
   computed: {
   },
   mounted() {
      this.pageMainData()
      // this.getData()
      this.pageData.isLoading = false;
      this.pageData.isEdit = true;
      this.summery = [
         {
            label: 'name_ar',
            value: 'عيد الفطر' || '-',
            class: '',
            cols: 12, sm: 6, md: 6, lg: 6, xl: 6,
         },
         {
            label: 'name_en',
            value: 'Eid Alfeter' || '-',
            class: '',
            cols: 12, sm: 6, md: 6, lg: 6, xl: 6,
         },
         {
            label: 'from date',
            value: '01-05-20223' || '-',
            class: '',
            cols: 12, sm: 6, md: 6, lg: 6, xl: 6,
         },
         {
            label: 'to date',
            value: '01-05-20223' || '-',
            class: '',
            cols: 12, sm: 6, md: 6, lg: 6, xl: 6,
         },
         {
            label: 'note',
            value: 'قوم بعمليات النسخ الاحتياطي لبياناتك وحفظها على وحدات تخزين بشكل يمكننا من استرجاعها مشفرة في حال مسحت أو تم تغييرها بقصد أو بدون. نؤمن بأن بياناتك في قيراط هي ملك لك' || '-',
            class: '',
            cols: 12, sm: 12, md: 12, lg: 12, xl: 12,
         },
         {
            label: '',
            key: 'slotKey',
            value: "response.data.data" || '-',
            type: 'slot',
            class: '',
            cols: 12, sm: 12, md: 12, lg: 12, xl: 12,
         },
      ]
      this.categories = [
         {
            "id": 1,
            "name": "إداري عمومي 1"
         },
         {
            "id": 2,
            "name": "إداري عمومي 2"
         },
         {
            "id": 3,
            "name": "إداري عمومي 3"
         },
         {
            "id": 4,
            "name": "إداري عمومي 4"
         },
         {
            "id": 5,
            "name": "إداري عمومي 5"
         },
         {
            "id": 6,
            "name": "إداري عمومي 6"
         },
         {
            "id": 7,
            "name": "إداري عمومي 7"
         }
      ]
      this.companies = [
         {
            "id": 1,
            "company_name": "شركة 1",
            "managements": [
               {
                  "id": 101,
                  "name": "أدارة 1"
               },
               {
                  "id": 102,
                  "name": "أدارة 2"
               }
            ],
            "departments": [
               {
                  "id": 1001,
                  "name": "قسم 1"
               },
               {
                  "id": 1002,
                  "name": "قسم 2"
               }
            ]
         },
         {
            "id": 2,
            "company_name": "شركة 2",
            "managements": [
               {
                  "id": 103,
                  "name": "أدارة 3"
               },
               {
                  "id": 104,
                  "name": "أدارة 4"
               }
            ],
            "departments": [
               {
                  "id": 1003,
                  "name": "قسم 3"
               },
               {
                  "id": 1004,
                  "name": "قسم 4"
               }
            ]
         }
      ]
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`deduction_actions/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.categories = response
                     this.companies = response

                     this.summery = [
                        {
                           label: 'name_ar',
                           value: 'عيد الفطر' || '-',
                           class: '',
                           cols: 12, sm: 6, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'name_en',
                           value: 'Eid Alfeter' || '-',
                           class: '',
                           cols: 12, sm: 6, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'from date',
                           value: '01-05-20223' || '-',
                           class: '',
                           cols: 12, sm: 6, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'to date',
                           value: '01-05-20223' || '-',
                           class: '',
                           cols: 12, sm: 6, md: 6, lg: 6, xl: 6,
                        },
                        {
                           label: 'note',
                           value: 'قوم بعمليات النسخ الاحتياطي لبياناتك وحفظها على وحدات تخزين بشكل يمكننا من استرجاعها مشفرة في حال مسحت أو تم تغييرها بقصد أو بدون. نؤمن بأن بياناتك في قيراط هي ملك لك' || '-',
                           class: '',
                           cols: 12, sm: 12, md: 12, lg: 12, xl: 12,
                        },
                        {
                           label: '',
                           key: 'slotKey',
                           value: "response.data.data" || '-',
                           type: 'slot',
                           class: '',
                           cols: 12, sm: 12, md: 12, lg: 12, xl: 12,
                        },
                     ]
                     this.viewSummery = true;
                     // this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`deduction_actions/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.employee = response.data.employees;
                     this.deduction_type = response.data.deductions;
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`deduction_actions/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`deduction_actions`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`deduction_actions/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
   props: {
   },
   watch: {
   }
};
</script>

<style lang="scss" scoped>
div#divider_ver {
   height: 200px !important;
}
</style>
