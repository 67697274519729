<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <ControlHeader v-if="!$route.params.id" :pageData="pageData" :form="form" :save="save" :valid="valid"
        :deleteMethod="deleteMethod" :actionBtn="false" actionBtnClass="light text-decoration-underline blue5--text"
        :actionBtnClick="() => attachmentDialog = true" :actionBtnValid="true" actionBtnText="attachments"
        :editForm="pageData.isEdit && !viewForm" :viewForm="() => viewForm = true"></ControlHeader>
      <!-- End Of Header -->


      <!-- Form -->
      <v-form ref="form" v-model="valid" v-if="!$route.params.id" class="mt-5">
        <v-card class="overflow-hidden py-5 px-1 transparent" elevation="0">
          <v-row>
            <v-col cols="12" class="font-weight-bold">
              <label>{{ $t('Job data') }}</label>
            </v-col>

            <GenericInput type="date" :value="form.start_date" @input="form.start_date = $event" label="from date"
              :required="true" :isLoading="false" :clearable="true" :cols="[12, 4, 4]"></GenericInput>

            <GenericInput type="date" :value="form.end_date" @input="form.end_date = $event" label="to date"
              :minDate="form.start_date" :required="true" :isLoading="false" :clearable="true" :cols="[12, 4, 4]">
            </GenericInput>

            <GenericInput type="number" :value="Math.abs(form.apply_count)" @input="form.apply_count = $event"
              label="Number of applicants" placeholder="The number of applicants you want" :isLoading="false"
              :clearable="true" :required="true" :cols="[12, 4, 4]"></GenericInput>

            <GenericInput type="text" :value="form.job_name_ar" @input="form.job_name_ar = $event"
              label="Job title in Arabic" :required="true" :isLoading="false" :cols="[12, 4, 4]" />

            <GenericInput type="text" :value="form.job_name_en" @input="form.job_name_en = $event"
              label="Job Title in English" :required="true" :isLoading="false" :cols="[12, 4, 4]" />

            <v-col cols="12" class="font-weight-bold">
              <label>{{ $t('Job details') }}</label>
            </v-col>
            <GenericInput type="texteditor" :value="form.job_details" @input="form.job_details = $event" label="details"
              :rowsNumber="6" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>
          </v-row>
        </v-card>
      </v-form>
      <!-- End of Form -->
    </v-container>
  </section>
</template>



<script>
export default {

  name: "InterviewControl",

  data: () => ({
    pageData: {
      screen_code: "10-002",
      url: null,
      controlRoute: "/careers/interview-control/",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isEdit: false,
      editIsLoading: false,
      printLoading: {
        loading: false,
        id: null,
        key: "id",
      },
    },
    viewForm: false,
    summery: [],
    employees: [],
    valid: false,
    tab: 0,
    sales_areas: [],
    sales_agents: [],
    account_trees: [],
    currencies: [],
    countries: [],
    cities: [],
    openDialog: false,
    attachmentDialog: false,
    employee_profile_id: "",
    form: {
      start_date: null,
      end_date: null,
      apply_count: null,
      job_name_ar: null,
      job_name_en: null,
      job_details: null,
    },
    printData: [],
    printStaticData: Object,
    print: false,
    printResponse: {},
  }),
  computed: {},
  mounted() {
    this.pageMainData();
    this.pageData.isLoading = false;
    // this.getData()
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.url = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).url;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
    },
    getData() {
      if (this.$route.params.id) {
        if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
          this.pageData.isEdit = true;
          this.pageData.editIsLoading = true;
          this.pageData.isLoading = true;
          this.$api
            .GET_METHOD(`covenant/${this.$route.params.id}`)
            .then((response) => {
              this.pageData.editIsLoading = false;
              this.pageData.isLoading = false;
              if (response.check) {
                console.log("response", response);
                this.printResponse = response.data;

                // this.$refs.form.validate();
              }
            });
        } else {
          this.$router.push("/");
        }
      } else {
        if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
          this.pageData.isLoading = true;
          this.viewForm = true;
          this.$api.GET_METHOD(`covenant/create`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.employees = response.data.employees;
            }
          });
        } else {
          this.$router.push("/");
        }
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        this.pageData.isLoading = true;
        if (this.pageData.isEdit == true) {
          this.form._method = "PUT";
          this.$api
            .POST_METHOD(`covenant/${this.form.id}`, this.form)
            .then((response) => {
              this.pageData.isLoading = false;
              if (response.check) {
                this.$router.push(
                  this.pageData.url +
                  (this.$store.state.prevScreen.requestParam || "")
                );
              }
            });
        } else {
          this.$api.POST_METHOD(`covenant`, this.form).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.$router.push(this.pageData.url);
            }
          });
        }
      }
    },
    deleteMethod(status) {
      if (status == true) {
        this.pageData.isLoading = true;
        this.$api
          .POST_METHOD(`covenant/${this.form.id}`, { _method: "delete" })
          .then(() => {
            this.$router.push(this.pageData.url);
            this.pageData.isLoading = false;
          });
      }
    },
    printMethod() {
      this.pageData.printLoading.loading = true;
      this.pageData.printLoading.id = this.$route.params.id;
      this.printData = [this.printResponse.data];
      this.printStaticData.master = this.printResponse.data;
      this.print = true;
      setTimeout(() => {
        this.printData = [];
        this.printStaticData = Object;
        this.print = false;
        this.pageData.printLoading.loading = false;
      }, 1000);
    },
  },
  watch: {},
};
</script>
