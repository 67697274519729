<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid && !isEdit && form.details.length"
            createBtnText='cashing' :editForm="false" :showDeleted="false" :viewForm="() => viewForm = true">
            <template v-slot:leftSide>
               <ExportBtn :groupHeader="printGroupHeader" :showGroupHeader="true" :footerData="[]"
                  :header="pageData.printTableHeader" :exportData="pageData.rows" printOrientation="landscape"
                  classes="primary">
                  <template v-slot:td="{ header, row }">
                     <div v-if="header.key === 'payment_method'">
                        <div>
                           {{ $global.FilterArrayOfObjectElement(payment_method, "id", row.payment_method, 'name') }}
                        </div>
                     </div>
                  </template>
               </ExportBtn>
            </template>
         </ControlHeader>
         <!-- End Of Header -->

         <AnimatedLoading :isLoading="first_loading" :height="100" class="mt-16" />

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="py-3 mt-6" v-if="!first_loading">
            <v-expansion-panels v-model="panel" multiple>
               <v-expansion-panel class="blue11 rounded-xl">
                  <v-expansion-panel-header expand-icon="mdi-menu-down"></v-expansion-panel-header>
                  <v-expansion-panel-content>
                     <v-card class="overflow-hidden pa-5 transparent" elevation="0">
                        <v-row class="align-center">
                           <v-col cols="7">
                              <v-row class="align-center">
                                 <v-col cols="auto" class="font-weight-bold">
                                    {{ $t("company") }}: {{ masterData.data.company_name }}
                                 </v-col>
                                 <v-col cols="auto" class="font-weight-bold">
                                    {{ $t("department") }}: {{ masterData.data.department_name }}</v-col>
                                 <v-col cols="auto" class="font-weight-bold">
                                    {{ $t("value") }}: {{ masterData.data.total_amount | float }} {{ $t("ryal") }}
                                 </v-col>
                                 <v-col cols="auto" class="font-weight-bold">
                                    {{ $t("management") }}: {{ masterData.data.management_name }}
                                 </v-col>
                                 <v-col cols="auto" class="font-weight-bold">
                                    {{ $t("accounting month") }}: {{ masterData.data.accounting_month }}
                                 </v-col>
                              </v-row>
                           </v-col>
                           <v-col cols="5">
                              <v-card class="pa-5" width="500" :color="!isEdit ? 'lavender' : 'green9'" elevation="0">
                                 <v-row class="justify-space-between text-center mx-5 " v-once>
                                    <v-col cols="12">
                                       <h3 class="mb-3">
                                          {{ $t("the number") }}: {{ employee_count().total }} {{ $t("employee") }}
                                       </h3>
                                    </v-col>
                                    <v-col cols="auto">
                                       <p class="mb-3">{{ $t("my bank") }}</p>
                                       <span>{{ employee_count().bank }}</span>
                                    </v-col>
                                    <v-col cols="1">
                                       <v-divider vertical class="white"></v-divider>
                                    </v-col>
                                    <v-col cols="auto">
                                       <p class="mb-3">{{ $t("transfer") }}</p>
                                       <span>{{ employee_count().transfer }}</span>
                                    </v-col>
                                    <v-col cols="1">
                                       <v-divider vertical class="white"></v-divider>
                                    </v-col>
                                    <v-col cols="auto">
                                       <p class="mb-3">{{ $t("cash money") }}</p>
                                       <span>{{ employee_count().cash }}</span>
                                    </v-col>
                                 </v-row>
                              </v-card>
                           </v-col>
                        </v-row>
                     </v-card>
                  </v-expansion-panel-content>
               </v-expansion-panel>
            </v-expansion-panels>
         </v-form>

         <v-row v-if="!first_loading">
            <v-col cols="12" sm="12">
               <v-tabs color="secondary" active-class="blue12" v-model="tab" background-color="transparent"
                  class="font-weight-bold mb-n2 align-center py-5">
                  <v-col cols="9" class="pa-0 d-flex">
                     <v-tab class="transparent"><strong>{{ $t('all exchange') }}</strong></v-tab>
                     <v-tab class="transparent"><strong>{{ $t('bank exchange employees') }}</strong>
                     </v-tab>
                     <v-tab class="transparent"><strong>{{ $t('cash exchange employees') }}</strong>
                     </v-tab>
                     <v-tab class="transparent"><strong>{{ $t('transactions exchange staff') }}</strong>
                     </v-tab>
                  </v-col>
                  <v-col cols="3" class="pa-0 d-flex justify-end">
                     <GenericInput v-if="!isEdit" type="select" :value="changePaymentAllEmployee"
                        @input="changePaymentAllEmployee = $event" icon="mdi-chevron-down" :disabled="!form.details.length"
                        label="Change exchange type for all" :lookups="payment_method" selected_prop="id"
                        selected_label="name" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col>
               </v-tabs>
               <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
                  :key="tab" :option="pageData.options" :pageData="pageData" :checkbox="!isEdit" :selectRow="!isEdit"
                  :checkboxMethod="checkboxMethod">
                  <template v-slot:td="{ header, row }">
                     <div class="input_container" v-if="header.key === 'payment_method'">
                        <GenericInput v-if="!isEdit" type="select" :value="row.payment_method"
                           @input="row.payment_method = $event" icon="mdi-chevron-down"
                           :label="!isEdit ? 'exchange type' : null" :lookups="payment_method" selected_prop="id"
                           selected_label="name" :disabled="isEdit" :isLoading="pageData.editIsLoading"
                           :cols="[12, 12, 12]">
                        </GenericInput>
                        <div v-else>
                           {{ $global.FilterArrayOfObjectElement(payment_method, "id", row.payment_method, 'name') }}
                        </div>
                     </div>
                  </template>

                  <template v-slot:afterTable>
                     <v-divider v-if="pageData.rows.length && !pageData.isLoading"></v-divider>
                     <v-row v-if="pageData.rows.length && !pageData.isLoading" justify="space-between"
                        class="font-weight-bold text-center text-body-2 mt-1">
                        <v-col cols="6" class="pa-2">{{ $t('the total') }}</v-col>
                        <v-col cols="6" class="pa-2">{{ total_amount || 0 | float }}</v-col>
                     </v-row>
                  </template>
               </DynamicTable>
            </v-col>
         </v-row>
         <!-- End of Form -->
      </v-container>
   </section>
</template>

<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import AnimatedLoading from "@/components/ui/AnimatedLoading.vue";
import ExportBtn from "@/components/ui/ExportBtn.vue";
export default {
   name: "DisbursementControl",

   data: () => ({
      pageData: {
         screen_code: "09-020",
         url: null,
         rows: [],
         tableHeader: [],
         printTableHeader: [],
         options: {},
         controlRoute: "/transactions/disbursement_transaction-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      panel: [0],
      viewForm: false,
      summery: [],
      valid: false,
      tab: 0,
      changePaymentAllEmployee: "",
      printHeaders: {},
      printGroupHeader: [],
      payment_method: [],
      masterData: Object,
      is_paid: false,
      first_loading: true,
      form: {
         details: []
      },
      isEdit: false,
   }),
   components: {
      ControlHeader,
      DynamicTable,
      GenericInput,
      AnimatedLoading,
      ExportBtn,
   },
   mounted() {
      this.queryParams();
      this.pageMainData();
      this.getData();
   },
   computed: {
      total_amount() {
         return this.pageData.rows.reduce((prev, current) => prev + +current.total_amount, 0)
      },
      employee_count() {
         return () => {
            const paymentCounts = this.pageData.rows.reduce((counts, row) => {
               switch (row.payment_method) {
                  case 1:
                     counts.bank++;
                     break;
                  case 2:
                     counts.cash++;
                     break;
                  case 3:
                     counts.transfer++;
                     break;
                  default:
                     break;
               }
               return counts;
            }, { bank: 0, cash: 0, transfer: 0 });

            paymentCounts.total = paymentCounts.bank + paymentCounts.cash + paymentCounts.transfer;
            return paymentCounts;
         };
      }
   },
   watch: {
      $route() {
         this.queryParams();
         this.pageMainData();
         this.getData();
      },
      tab() {
         this.getData()
         this.changePaymentAllEmployee = ""
         this.form.details = []
      },
      changePaymentAllEmployee() {
         this.form.details.forEach(row => row.payment_method = +this.changePaymentAllEmployee)
      }
   },
   methods: {
      queryParams() {
         this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
         this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
         this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
         this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
         this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
      },
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).main_title;
         this.pageData.category = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).cat_title;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.tableHeader = [
            { text: "#", key: "id", type: 'text', classes: "" },
            { text: "employee name", key: "employee_name", type: 'text', classes: "" },
            { text: "fixed salary", key: "fixed", type: 'float', classes: "" },
            { text: "housing allowance", key: "housing_allowance", type: 'float', classes: "" },
            { text: "total other allowances", key: "total_allowances", type: 'float', classes: "" },
            { text: "total salary", key: "salary_includes_allowances", type: 'float', classes: "" },
            { text: "deductions", key: "total_preview_deductions", type: 'non_zero_float', classes: "" },
            { text: "deserved salary", key: "total_amount", type: 'non_zero_float', classes: "" },
            { text: "exchange type", key: "payment_method", type: 'slot', classes: "" },
         ];
         this.pageData.options = {
            print: this.$global.CheckAction(this.pageData.screen_code, 5),
            delete: this.$global.CheckAction(this.pageData.screen_code, 4),
            edit: this.$global.CheckAction(this.pageData.screen_code, 3),
            restore: this.$global.CheckAction(this.pageData.screen_code, 4),
            view: this.$global.CheckAction(this.pageData.screen_code, 1),
            switch: false,
            selectRow: false,
            sortBy: "id",
            tableHeader: false,
            searchInput: false,
            printButton: false,
            handleClickRow: false,
         };
      },
      getSearch(word) {
         this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${1}&word=${word || ''}`
         this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`payment_salary/${this.$route.params.id}?payment_method=${this.tab == 0 ? '' : this.tab}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  this.first_loading = false;

                  if (this.$route.params.tab == undefined) {
                     this.$router.push('/transactions/disbursement_transaction')
                  } else {
                     this.isEdit = this.$route.params.tab
                  }

                  if (response.check) {
                     console.log("response", response);
                     this.pageData.isLoading = false;
                     this.masterData = response.data;
                     this.pageData.rows = response.data.details;
                     this.printHeaders = response.data.headers;
                     this.tableDataPrint()
                     setTimeout(() => this.panel = [], 2000)
                     this.is_paid = this.pageData.rows.every(e => e.is_fund === 1)
                     this.pageData.page = response.data.details.current_page;
                     this.pageData.pagination = {
                        page: response.data.details.current_page,
                        totalPages: response.data.details.last_page,
                        per_page: response.data.details.per_page,
                        totalRows: response.data.details.total,
                     };
                     // this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
            this.payment_method = [
               {
                  id: 1,
                  name: this.$i18n.t('Bank')
               },
               {
                  id: 2,
                  name: this.$i18n.t('cash')
               },
               {
                  id: 3,
                  name: this.$i18n.t('transfer')
               }
            ]
         }
      },
      tableDataPrint() {
         const { allowances, absence } = this.printHeaders;

         this.pageData.printTableHeader = [
            { text: "employee serial number", key: "employee_id", type: 'text', classes: "", is_rowspan: true },
            { text: "employee name", key: "employee_name", type: 'text', classes: "", is_rowspan: true },
            { text: "workdays", key: "worked_days", type: 'text', classes: "", is_rowspan: true },
            { text: "the basic", key: "fixed", type: 'float', classes: "", is_rowspan: true },
            { text: "housing allowance", key: "housing_allowance", type: 'float', classes: "", is_rowspan: true },
            { text: "total additions", key: "total_allowances", type: 'float', classes: "", is_rowspan: true },
            { text: 'advance', key: "advance_count", type: 'float', classes: "" },
            { text: "deduction", key: "deduction_count", type: 'float', classes: "" },
            { text: "Total deductions", key: "total_deductions", type: 'float', classes: "", is_rowspan: true },
            { text: 'days', key: 'absence_days', type: 'text', classes: "" },
            { text: 'amount', key: 'absence_value', type: 'float', classes: "" },
            { text: "the total", key: "total_amount", type: 'non_zero_float', classes: "", is_rowspan: true },
            { text: "exchange type", key: "payment_method", type: 'slot', classes: "", is_rowspan: true },
         ]

         for (const row of this.pageData.rows) {
            // handel errors if any item in row null or empty
            const rowAllowances = row.allowances || [];
            const rowDeductions = row.deductions || [];
            const rowAbsence = row.absence || {};

            // Calculate and assign allowance values to their respective keys in the row
            const rowAllowancesGroup = this.$global.GroupBy(rowAllowances, 'salary_effect_name')
            for (const allowance in rowAllowancesGroup) {
               row[allowance] = rowAllowancesGroup[allowance].reduce((prev, current) => prev + +current.amount, 0)
            }

            // Initialize counters for deduction types
            let deduction_count = 0;
            let advance_count = 0;

            // Calculate total deduction amounts based on deduction type
            for (const deduction of rowDeductions) {
               if (deduction.advance_id === 0) {
                  deduction_count += +deduction.amount;
               } else {
                  advance_count += +deduction.amount;
               }
            }

            // Extract absence days and value from the absence data
            const absence_days = +rowAbsence.days || 0;
            const absence_value = +rowAbsence.value || 0;

            // Assign calculated values to row properties
            row.deduction_count = deduction_count;
            row.advance_count = advance_count;
            row.absence_days = absence_days;
            row.absence_value = absence_value;

            // Calculate additional derived values
            row.salary_includes_allowances = (+row.fixed + +row.total_allowances) + +row.housing_allowance
            row.total_allowances = rowAllowances.reduce((prev, current) => prev + +current.amount, 0);
            row.total_deductions = rowDeductions.reduce((prev, current) => prev + +current.amount, 0) + absence_value;
            row.total_preview_deductions = -(rowDeductions.reduce((prev, current) => prev + +current.amount, 0) + absence_value);
            row.total_amount = (+row.fixed + +row.total_allowances + +row.housing_allowance) - (+row.total_deductions);
         }

         for (const item of allowances) {
            this.pageData.printTableHeader.splice(5, 0, { text: item, key: item, type: 'float', classes: "" });
         }

         this.printGroupHeader = [
            { text: "employee serial number", rowspan: 2, classes: "" },
            { text: "employee name", rowspan: 2, classes: "" },
            { text: "workdays", rowspan: 2, classes: "" },
            { text: "the basic", rowspan: 2, classes: "" },
            { text: "housing allowance", rowspan: 2, classes: "" },
            { text: "additions", colspan: allowances.length, type: 'text', classes: "", },
            { text: "total additions", colspan: 1, rowspan: 2, type: 'text', classes: "", },
            { text: "Deductions", colspan: 2, type: 'text', classes: "", },
            { text: "Total deductions", colspan: 1, rowspan: 2, type: 'text', classes: "", },
            { text: "absence", colspan: absence.length, type: 'text', classes: "", },
            { text: "the total", rowspan: 2, classes: "" },
            { text: "exchange type", rowspan: 2, classes: "" },
         ];
      },
      changePage(page, limit) {
         this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
         this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
      },
      restoreMethod(row) {
         this.$api.POST_METHOD(`purchases_vendor_toggle_active/${row.id}`, null).then((response) => {
            if (response.check) {
               this.getData();
            }
         });
      },
      save() {
         this.form.salary_calculator_m_id = +this.$route.params.id
         this.form = { ...this.masterData.data, ...this.form }
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               // this.form._method = 'PUT';
               this.$api.POST_METHOD(`payment_salary`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`payment_salary`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      edit(row) {
         console.log("edit", row);
         this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
      },
      checkboxMethod(row) {
         this.form.details = [...row]
      },

   },
};
</script>

<style lang="scss" scoped>
.input_container {
   max-width: 137px;
}
</style>
