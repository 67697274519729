<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="validateSubmit > 0 ? true : false"
            :deleteMethod="deleteMethod" :createBtnText='isRecalculate || !pageData.isEdit ? "save" : "Recalculate"'
            :viewForm="() => viewForm = true" actionBtnText="re search" :actionBtn="!showFilter" :actionBtnValid="true"
            actionBtnColor="transparent" :actionBtnClick="() => { showFilter = true }" :actionBtnOutline="true"
            actionBtnClass="primary--text mx-2">
            <template v-slot:leftSide>
               <ExportBtn v-if="pageData.rows?.length" :groupHeader="printGroupHeader" :showGroupHeader="true" :footerData="[]"
                  :header="pageData.printTableHeader" :exportData="pageData.rows" printOrientation="landscape"
                  classes="primary">
                  <template v-slot:td="{ header, row }">
                     <div v-if="header.key === 'payment_method'">
                        <div>
                           {{ $global.FilterArrayOfObjectElement(payment_method, "id", row.payment_method, 'name') }}
                        </div>
                     </div>
                  </template>
               </ExportBtn>
            </template>
         </ControlHeader>
         <!-- End Of Header -->

         <!-- Form -->
         <v-scroll-y-transition>
            <v-form ref="form" v-model="valid" class="py-3 mt-6" v-show="showFilter && !pageData.isEdit">
               <v-expansion-panels v-model="panel" multiple>
                  <v-expansion-panel class="blue12 rounded-xl">
                     <v-expansion-panel-header expand-icon="mdi-menu-down" class="text-left">
                     </v-expansion-panel-header>
                     <v-expansion-panel-content>
                        <v-card class="overflow-hidden pa-5 transparent" elevation="0">
                           <v-row class="align-center">
                              <GenericInput type="date" :value="form.movement_history" :disabled="true"
                                 @input="form.movement_history = $event" paddingY="py-6" label="movement history"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                              </GenericInput>

                              <GenericInput type="date" :value="form.accounting_month"
                                 @input="form.accounting_month = $event" label="accounting month" datePickerType="month"
                                 :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                              </GenericInput>

                              <GenericInput type="textarea" :required="false" :value="form.notes"
                                 @input="form.notes = $event" label="description of the movement" :rowsNumber="4"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                              </GenericInput>


                              <v-col cols="12" class="py-3">
                                 <v-divider class="blue10"></v-divider>
                              </v-col>

                              <v-col cols="12">
                                 <p>{{ $t("filter") }}</p>
                              </v-col>

                              <GenericInput type="select" :lookups="filterCompany" :value="company_id"
                                 @input="company_id = $event" icon="mdi-chevron-down" selected_prop="id" label="company"
                                 :clearable="true" selected_label="name" :isLoading="pageData.editIsLoading"
                                 :cols="[12, 6, 3]">
                              </GenericInput>

                              <GenericInput type="select" :lookups="filterManagement" :value="management_id"
                                 :clearable="true" @input="management_id = $event" icon="mdi-chevron-down"
                                 selected_prop="id" label="the management" selected_label="name"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 6, 2]">
                              </GenericInput>

                              <GenericInput type="select" :lookups="filterDepartment" :value="department_id"
                                 :clearable="true" @input="department_id = $event" icon="mdi-chevron-down"
                                 selected_prop="id" label="department" selected_label="name"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 6, 2]">
                              </GenericInput>

                              <GenericInput type="select" :lookups="filterEmployees" :value="employee_id" :clearable="true"
                                 @input="employee_id = $event" label="choose the employee" selected_prop="id"
                                 selected_label="employee_name" :focusin="getCompanyEmployees"
                                 :isLoading="companyEmployeesLoading" :cols="[12, 6, 3]">
                                 <template v-slot:append>
                                    <v-icon>mdi-magnify</v-icon>
                                 </template>
                              </GenericInput>

                              <v-col cols="12" lg="auto" class="pt-md-8 pt-0">
                                 <v-btn @click="search" :disabled="form.accounting_month === null || btnSearchValidation"
                                    height="34" color="primary" class="white--text" elevation="0"
                                    :loading="pageData.isLoading">
                                    {{ $t("search") }}
                                 </v-btn>
                              </v-col>
                           </v-row>
                        </v-card>
                     </v-expansion-panel-content>
                  </v-expansion-panel>
               </v-expansion-panels>
            </v-form>
         </v-scroll-y-transition>
         <!-- End of Form -->

         <!-- DynamicTable -->
         <v-row>
            <v-slide-y-transition>
               <v-col col="12" v-if="isRecalculate">
                  <v-alert dense text type="info" class="ma-0">{{ $t('Please click on Save to complete the process')
                  }}</v-alert>
               </v-col>
            </v-slide-y-transition>

            <v-col cols="12" v-if="pageData.rows?.length">
               <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
                  :option="pageData.options" :pageData="pageData" :checkbox="true" :selectRow="!isRecalculate"
                  :checkboxMethod="checkboxMethod" :groupHeader="groupHeader" :showGroupHeader="true"
                  printOrientation="landscape">
                  <template v-slot:td="{ header, row }">
                     <div v-if="header.key === 'payment_method'">
                        <div>
                           {{ $global.FilterArrayOfObjectElement(payment_method, "id", row.payment_method, 'name') }}
                        </div>
                     </div>
                  </template>
                  <template v-slot:afterTable>
                     <v-divider v-if="pageData.rows.length && !pageData.isLoading"></v-divider>
                     <v-row v-if="pageData.rows.length && !pageData.isLoading" justify="space-between"
                        class="font-weight-bold text-center text-body-2 mt-1">
                        <v-col cols="6" class="pa-1">{{ $t('the total') }}</v-col>
                        <v-col cols="6" class="pa-1">{{ total_amount || 0 | float }}</v-col>
                     </v-row>
                  </template>
               </DynamicTable>
            </v-col>
         </v-row>
      </v-container>
   </section>
</template>

<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import DynamicTable from "@/components/DynamicTable.vue";
import ExportBtn from "@/components/ui/ExportBtn.vue";

export default {
   name: "SalaryCalculationControl",

   data: () => ({
      pageData: {
         screen_code: "09-019",
         url: null,
         rows: [],
         printRows: [],
         tableHeader: [],
         printTableHeader: [],
         options: {},
         controlRoute: "/transactions/salary_calculator_transaction-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
         queryParam: new URLSearchParams(),
      },
      headers: {},
      printHeaders: {},
      panel: [0, 1],
      viewForm: false,
      summery: [],
      valid: false,
      tab: 0,
      company: [],
      company_id: "",
      management: [],
      management_id: "",
      department: [],
      department_id: "",
      employees: [],
      employee_id: "",
      openDialog: false,
      companyEmployeesLoading: false,
      showFilter: true,
      isRecalculate: false,
      groupHeader: [],
      printGroupHeader: [],
      checkbox: [],
      payment_method: [],
      filteredEmployees: [],
      titleAllowance: [],
      validateSubmit: 0,
      form: {
         movement_history: null,
         accounting_month: null,
         notes: null,
         total_amount: null,
         details: []
      },
      rules: {
         required: value => !!value || 'Required.',
      },
   }),
   components: {
      GenericInput,
      ControlHeader,
      DynamicTable,
      ExportBtn,
   },
   mounted() {
      this.queryParams();
      this.pageMainData();
      this.form.movement_history = this.$global.GetCurrentDate()
      this.form.accounting_month = this.GetCurrentDate()
      this.getData();
   },
   computed: {
      btnSearchValidation() {
         if (this.company_id !== '' || this.management_id !== '' || this.department_id !== '' || this.employee_id !== '') {
            return false
         } else {
            return true
         }
      },
      filterCompany() {
         if (this.management_id) {
            return this.company.filter((company) =>
               this.management.filter(management => management.id === this.management_id)
                  .some(management => management.company_id === company.id));
         } else {
            return this.company;
         }
      },
      filterManagement() {
         if (this.company_id) {
            return this.management.filter(management =>
               this.company.filter(company => company.id === this.company_id)
                  .some(company => company.id === management.company_id));
         } else if (this.department_id) {
            return this.management.filter(management =>
               this.department.filter(department => department.id === this.department_id)
                  .some(department => department.management_id === management.id));
         } else {
            return this.management
         }

      },
      filterDepartment() {
         if (this.management_id && this.department && this.company_id) {
            return this.department.filter(department =>
               this.management.filter(management => management.id === this.management_id)
                  .some(management => management.id === department.management_id));
         } else if (!this.management_id && this.company_id) {
            return []
         } else if (this.management_id) {
            return []
         } else {
            return this.department
         }
      },

      filterEmployees() {
         if (this.department_id) {
            return this.filteredEmployees.filter(employees => employees.department_id === this.department_id)
         } else if (this.department_id === null && this.company_id === null) {
            return []
         } else {
            return this.employees
         }
      },
      total_amount() {
         return this.pageData.rows.reduce((prev, current) => prev + +current.total_amount, 0)
      },
   },
   watch: {
      $route() {
         this.queryParams();
         this.pageMainData();
         this.getData();
      },
      company_id() {
         this.employee_id = '';
         this.management_id = '';
      },
      management_id() {
         this.employee_id = '';
         this.department_id = '';
      },
      employee_id() {
         if (this.employee_id)
            this.search();
      }
   },
   methods: {
      queryParams() {
         this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
         this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
         this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
         this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
         this.pageData.queryParam = new URLSearchParams(this.$store.state.activeScreen.requestParam || '');
      },
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).main_title;
         this.pageData.category = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).cat_title;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.options = {
            print: this.$global.CheckAction(this.pageData.screen_code, 5),
            delete: this.$global.CheckAction(this.pageData.screen_code, 4),
            edit: this.$global.CheckAction(this.pageData.screen_code, 3),
            restore: this.$global.CheckAction(this.pageData.screen_code, 4),
            view: this.$global.CheckAction(this.pageData.screen_code, 1),
            switch: false,
            selectRow: false,
            sortBy: "id",
            tableHeader: false,
            searchInput: false,
            printButton: false,
            handleClickRow: false,
         };
      },
      getSearch(word) {
         this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${1}&word=${word || ''}`
         this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`salary_calculator/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$store.state.sidebarIsOpen = true;
                     this.form = response.data.data
                     this.headers = response.data.headers;
                     this.pageData.rows = response.data.details;
                     this.printHeaders = response.data.headers;
                     this.tableData()
                     this.tableDataPrint()
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`salary_calculator/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.company = response.data.company
                     this.management = response.data.managment
                     this.department = response.data.department
                     this.employees = response.data.employees
                     this.filteredEmployees = response.data.employees
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         this.payment_method = [
            {
               id: 1,
               name: this.$i18n.t('Bank')
            },
            {
               id: 2,
               name: this.$i18n.t('cash')
            },
            {
               id: 3,
               name: this.$i18n.t('transfer')
            }
         ]
      },
      getCompanyEmployees() {
         this.department_id === null && (this.department_id = "")
         this.management_id === null && (this.management_id = "")
         this.company_id === null && (this.company_id = "")
         if (this.management_id === '' && this.department_id === '' && this.company_id && !this.employee_id) {
            this.companyEmployeesLoading = true;
            this.$api
               .GET_METHOD(`get_company_employees/${this.company_id}`)
               .then((response) => {
                  this.companyEmployeesLoading = false;
                  if (response.check) {
                     this.employees = response.data.item
                  }
               });
         } else if (!this.company_id && this.employee_id === '') {
            this.pageData.rows = []
            this.getData()
         } else if (this.company_id && (this.management_id || this.department_id)) {
            this.employees = []
         }
      },
      changePage(page, limit) {
         this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
         this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
      },
      deleteMethod(status) {
         this.deleteDialog = false;
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`salary_calculator/${this.form.id}`, { _method: "delete" }).then((response) => {
               if (response.check) {
                  this.pageData.isLoading = false
                  this.$router.push(this.pageData.url);
               }
            });
         }
      },
      restoreMethod(row) {
         this.$api.POST_METHOD(`salary_calculator_toggle_active/${row.id}`, null).then((response) => {
            if (response.check) {
               this.getData();
            }
         });
      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            this.form.total_amount = this.total_amount
            if (this.pageData.isEdit == true && this.form.details.length && this.isRecalculate) {
               this.$api.POST_METHOD(`recalculate_salary/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.isRecalculate = false;
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else if (this.pageData.isEdit == true && this.form.details.length && !this.isRecalculate) {
               const payload = new URLSearchParams()
               this.form.details.forEach(row => payload.append("employee[]", row))
               payload.append("accounting_month", this.form.accounting_month)
               payload.append("recalculate", true)
               this.$api.GET_METHOD(`salary_calculator_d?${payload.toString()}`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.isRecalculate = true;
                     console.log('salary_calculator_d', response);
                     this.headers = response.data.headers;
                     this.pageData.rows = response.data.items;
                     this.printHeaders = response.data.headers;
                     this.tableData()
                     this.tableDataPrint()
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`salary_calculator`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      search() {
         this.pageData.isLoading = true;
         this.$api.GET_METHOD(`salary_calculator_d?company=${this.company_id || ''}&accounting_month=${this.form.accounting_month || ''}&management=${this.management_id || ''}&department=${this.department_id || ''}&employee=${this.employee_id || ''}`)
         .then((response) => {
            response.data.items?.length && (this.$store.state.sidebarIsOpen = true);
            response.data.items?.length && ( this.showFilter = false);
            this.pageData.isLoading = false;
            this.headers = response.data.headers;
            this.printHeaders = response.data.headers;
            this.pageData.rows = response.data.items;
            this.tableData()
            setTimeout(() => this.tableDataPrint(), 1000)
         })
      },
      tableData() {
         if (this.headers) {
            const { allowances, absence } = this.headers;

            this.pageData.tableHeader = [
               { text: "employee serial number", key: "employee_id", type: 'text', classes: "", is_rowspan: true },
               { text: "employee name", key: "employee_name", type: 'text', classes: "", is_rowspan: true },
               { text: "workdays", key: "worked_days", type: 'text', classes: "", is_rowspan: true },
               { text: "the basic", key: "fixed", type: 'float', classes: "", is_rowspan: true },
               { text: "housing allowance", key: "housing_allowance", type: 'float', classes: "", is_rowspan: true },
               { text: "total additions", key: "total_additions", type: 'float', classes: "", is_rowspan: true },
               { text: 'advance', key: "advance_count", type: 'float', classes: "" },
               { text: "deduction", key: "deduction_count", type: 'float', classes: "" },
               { text: "Total deductions", key: "total_deductions", type: 'float', classes: "", is_rowspan: true },
               { text: 'days', key: 'absence_days', type: 'text', classes: "" },
               { text: 'amount', key: 'absence_value', type: 'float', classes: "" },
               { text: "the total", key: "total_amount", type: 'non_zero_float', classes: "", is_rowspan: true },
               { text: "exchange type", key: "payment_method", type: 'slot', classes: "", is_rowspan: true },
            ]

            // Iterate through each row in the page data
            for (const row of this.pageData.rows) {
               // handel errors if any item in row null or empty
               const rowAllowances = row.allowances || [];
               const rowDeductions = row.deductions || [];
               const rowAbsence = row.absence || {};

               // Calculate and assign allowance values to their respective keys in the row
               const rowAllowancesGroup = this.$global.GroupBy(rowAllowances, 'salary_effect_name')
               for (const allowance in rowAllowancesGroup) {
                  row[allowance] = rowAllowancesGroup[allowance].reduce((prev, current) => prev + +current.amount, 0)
               }

               // Initialize counters for deduction types
               let deduction_count = 0;
               let advance_count = 0;

               // Calculate total deduction amounts based on deduction type
               for (const deduction of rowDeductions) {
                  if (deduction.advance_id === 0) {
                     deduction_count += +deduction.amount;
                  } else {
                     advance_count += +deduction.amount;
                  }
               }

               // Extract absence days and value from the absence data
               const absence_days = +rowAbsence.days || 0;
               const absence_value = +rowAbsence.value || 0;

               // Calculate total additions and deductions, and final total amount for the row
               row.total_additions = rowAllowances.reduce((prev, current) => prev + +current.amount, 0);
               row.total_deductions = rowDeductions.reduce((prev, current) => prev + +current.amount, 0);
               row.total_amount = (+row.fixed + +row.total_additions + +row.housing_allowance) - (+row.total_deductions + absence_value);

               // Assign the calculated deduction and advance counts, and absence days and value to the row
               row.deduction_count = deduction_count;
               row.advance_count = advance_count;
               row.absence_days = absence_days;
               row.absence_value = absence_value;
            }

            for (const item of allowances) {
               this.pageData.tableHeader.splice(5, 0, { text: item, key: item, type: 'float', classes: "" });
            }

            this.groupHeader = [
               { text: "employee serial number", rowspan: 2, classes: "" },
               { text: "employee name", rowspan: 2, classes: "" },
               { text: "workdays", rowspan: 2, classes: "" },
               { text: "the basic", rowspan: 2, classes: "" },
               { text: "housing allowance", rowspan: 2, classes: "" },
               { text: "additions", colspan: allowances.length, type: 'text', classes: "", },
               { text: "total additions", colspan: 1, rowspan: 2, type: 'text', classes: "", },
               { text: "Deductions", colspan: 2, type: 'text', classes: "", },
               { text: "Total deductions", colspan: 1, rowspan: 2, type: 'text', classes: "", },
               { text: "absence", colspan: absence.length, type: 'text', classes: "", },
               { text: "the total", rowspan: 2, classes: "" },
               { text: "exchange type", rowspan: 2, classes: "" },
            ];
         }
      },
      tableDataPrint() {
         if (this.printHeaders) {
            const { allowances, absence } = this.printHeaders;

            this.pageData.printTableHeader = [
               { text: "employee serial number", key: "employee_id", type: 'text', classes: "", is_rowspan: true },
               { text: "employee name", key: "employee_name", type: 'text', classes: "", is_rowspan: true },
               { text: "workdays", key: "worked_days", type: 'text', classes: "", is_rowspan: true },
               { text: "the basic", key: "fixed", type: 'float', classes: "", is_rowspan: true },
               { text: "housing allowance", key: "housing_allowance", type: 'float', classes: "", is_rowspan: true },
               { text: "total additions", key: "total_additions", type: 'float', classes: "", is_rowspan: true },
               { text: 'advance', key: "advance_count", type: 'float', classes: "" },
               { text: "deduction", key: "deduction_count", type: 'float', classes: "" },
               { text: "Total deductions", key: "total_deductions", type: 'float', classes: "", is_rowspan: true },
               { text: 'days', key: 'absence_days', type: 'text', classes: "" },
               { text: 'amount', key: 'absence_value', type: 'float', classes: "" },
               { text: "the total", key: "total_amount", type: 'non_zero_float', classes: "", is_rowspan: true },
               { text: "exchange type", key: "payment_method", type: 'slot', classes: "", is_rowspan: true },
            ]

            for (const item of allowances) {
               this.pageData.printTableHeader.splice(5, 0, { text: item, key: item, type: 'float', classes: "" });
            }

            this.printGroupHeader = [
               { text: "employee serial number", rowspan: 2, classes: "" },
               { text: "employee name", rowspan: 2, classes: "" },
               { text: "workdays", rowspan: 2, classes: "" },
               { text: "the basic", rowspan: 2, classes: "" },
               { text: "housing allowance", rowspan: 2, classes: "" },
               { text: "additions", colspan: allowances.length, type: 'text', classes: "", },
               { text: "total additions", colspan: 1, rowspan: 2, type: 'text', classes: "", },
               { text: "Deductions", colspan: 2, type: 'text', classes: "", },
               { text: "Total deductions", colspan: 1, rowspan: 2, type: 'text', classes: "", },
               { text: "absence", colspan: absence.length, type: 'text', classes: "", },
               { text: "the total", rowspan: 2, classes: "" },
               { text: "exchange type", rowspan: 2, classes: "" },
            ];
         }
      },
      edit(row) {
         console.log("edit", row);
         this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
      },
      checkboxMethod(row) {
         this.validateSubmit = row.length
         this.form.details = row.map(row => row.employee_id)
      },
      GetCurrentDate() {
         var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

         if (month.length < 2)
            month = '0' + month;
         if (day.length < 2)
            day = '0' + day;
         return [year, month].join('-');
      },
   },

};
</script>
<style lang="scss" scoped></style>
