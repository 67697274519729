<template>
    <v-dialog v-model="openDialog" persistent width="470">
        <v-card class="pa-3" rounded="rounded">
            <v-card-title class="text-h6 font-weight-bold" :class="rowData.action_name == 'ending' ? 'red6--text' : 'yellow2--text'">
                {{ rowData.action_name == 'ending' ?
                    $t('Are you sure to finish')
                    : $t('Are you sure you want to suspend the job') }}
            </v-card-title>

            <v-card-actions class="px-5">
                <v-btn :class="rowData.action_name == 'ending' ? 'red6 white--text' : 'yellow4 black--text'" text
                    :loading="loading" @click="confirm" width="192" height="42">
                    {{ rowData.action_name == 'ending' ? $t('ending') : $t('suspense') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn class=" gray4--text" text @click="closeDialog" width="192" height="42" :loading="loading" outlined>
                    {{ $t('back') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ActionDialog",
    props: {
        openDialog: { default: false },
        closeDialog: { type: Function },
        confirmAction: { type: Function },
        rowData: { default: Object },
    },
    data: () => ({
        loading: false,
    }),

    methods: {
        confirm() {
            this.confirmAction(this.rowData)
            this.closeDialog()
        },
    },
    computed: {},
}


</script>

<style lang="scss" scoped></style>
