<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod"
            :editForm="pageData.isEdit && !viewForm" :viewForm="() => viewForm = true" />
         <!-- End Of Header -->
         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading" v-if="pageData.isEdit && !viewForm" />
         <!-- End of Summery -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3 mt-5 transparent" v-if="viewForm">
            <v-card class="transparent overflow-hidden pa-0" elevation="0">
               <v-sheet class="pa-5 transparent">
                  <v-row align="center">
                     <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                        :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]"></GenericInput>

                     <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en"
                        :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]"></GenericInput>

                     <GenericInput type="text" :value="form.ip" @input="form.ip = $event" label="IP" :required="true"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]"></GenericInput>

                     <GenericInput type="text" :value="form.port" @input="form.port = $event" label="port"
                        :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 2]"></GenericInput>

                     <GenericInput type="select" :lookups="company" :value="form.company_id"
                        @input="form.company_id = $event" label="company name" icon="mdi-chevron-down" :multi="false"
                        :required="true" selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                        :cols="[12, 6, 3]">
                     </GenericInput>

                     <GenericInput type="text" :value="form.username" @input="form.username = $event" label="username"
                        :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>

                     <GenericInput type="text" :value="form.password" @input="form.password = $event" label="password"
                        :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>
                  </v-row>
               </v-sheet>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlSummery from "@/components/ui/ControlSummery.vue";

export default {
   name: "FingerPrintEntity",

   data: () => ({
      pageData: {
         screen_code: "09-024",
         url: null,
         controlRoute: "/codes/fingerprint-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      viewForm: false,
      summery: [],
      valid: false,
      company: [],
      form: {
         name_en: null,
         name_ar: null,
         ip: null,
         port: null,
         company_id: null,
         username: null,
         password: null,
      },
   }),
   components: {
      GenericInput,
      ControlHeader,
      ControlSummery
   },
   computed: {},
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`finger_print/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.company = response.data.companys;
                     this.form = response.data.data;
                     this.summery = [
                        {
                           label: 'name_ar',
                           value: response.data.data.name_ar || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 4, xl: null,
                        },
                        {
                           label: 'name_en',
                           value: response.data.data.name_en || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 5, xl: null,
                        },
                        {
                           label: 'IP',
                           value: response.data.data.ip || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 4, xl: null,
                        },
                        {
                           label: 'Port',
                           value: response.data.data.port || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 5, xl: null,
                        },
                        {
                           label: 'company name',
                           value: response.data.data.company_name  || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 4, xl: null,
                        },
                        {
                           label: 'username',
                           value: response.data.data.username || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 4, xl: null,
                        },
                     ]
                     this.viewSummery = true;
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`finger_print/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.company = response.data.companys;
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`finger_print/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`finger_print`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`finger_print/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
   },
};
</script>
