<template>
   <section>
      <v-container fluid>
         <AnimatedLoading classes="mt-16" :height="100" :hideText="false" :isLoading="isLoading" />

         <v-card class="backgroundW shadow overflow-hidden pa-0 text-capitalize mt-3" v-if="!isLoading">
            <v-row class="align-center">
               <v-col cols="auto">
                  <v-avatar size="100" @click="handelOpenAvatar" class="employeeAvatar rounded-circle ma-5 cursor_pointer">
                     <LazyImage :src="$api.serverUrl + employeeData.employee_image"
                        :error="require('@/assets/img/svg/default_profile.svg')"
                        :loading="require('@/assets/img/png/files/loading.gif')" />
                  </v-avatar>

                  <v-dialog v-model="openAvatar" max-width="700">
                     <v-card>
                        <div class="d-flex justify-end">
                           <v-btn absolute small fab class="ma-3" color="red5" text @click="openAvatar = false">
                              <v-icon color="red5">mdi-close</v-icon>
                           </v-btn>
                        </div>

                        <v-img max-height="100vh" class="cursor_pointer"
                           @click="downloadAttachment($api.serverUrl + employeeData.employee_image, employeeData)"
                           :src="$api.serverUrl + employeeData.employee_image"></v-img>
                     </v-card>
                  </v-dialog>
               </v-col>
               <v-col cols="5">
                  <div>{{ $route.params.id }}<Strong>-</Strong>{{ employeeData.job_name }}</div>
                  <div class="font-weight-bold py-1">{{ employeeData.employee_name }}</div>
                  <div class="py-1">
                     {{ $t("employee serial number") }} {{ employeeData.employee_serial || $t('no data') }}
                  </div>
                  <div>
                     {{ $t("fingerprint code") }} {{ employeeData.finger_print_id || $t('no data') }}
                  </div>
               </v-col>
               <v-col cols="2">
                  <p>{{ $t("number of holidays") }}</p>
                  <span class="font-weight-bold">{{ employeeData.total_vacation || 0 }} {{ $t("day") }} </span>
               </v-col>
               <v-col cols="2">
                  <p>{{ $t("number of advances") }}</p>
                  <span class="font-weight-bold">{{ employeeData.total_advance || 0 }}</span>
               </v-col>
            </v-row>

            <v-col cols="12" class="pa-0">
               <v-tabs color="black" active-class="blue12" v-model="tab">
                  <v-tab class="font-weight-bold text-body-2"><strong>{{ $t('personal information') }}</strong>
                  </v-tab>
                  <v-tab class="font-weight-bold text-body-2"><strong>{{ $t('documents') }}</strong></v-tab>
                  <v-tab class="font-weight-bold text-body-2"><strong>{{ $t('salary increase') }}</strong></v-tab>
                  <v-tab class="font-weight-bold text-body-2"><strong>{{ $t('deductions') }}</strong></v-tab>
                  <v-tab class="font-weight-bold text-body-2"><strong>{{ $t('benefits') }}</strong></v-tab>
                  <v-tab class="font-weight-bold text-body-2"><strong>{{ $t('vacations') }}</strong></v-tab>
                  <v-tab class="font-weight-bold text-body-2"><strong>{{ $t('loans') }}</strong></v-tab>
                  <v-tab class="font-weight-bold text-body-2"><strong>{{ $t('allowances') }}</strong></v-tab>
                  <v-tab class="font-weight-bold text-body-2"><strong>{{ $t('covenant') }}</strong></v-tab>
                  <v-tab class="font-weight-bold text-body-2"><strong>{{ $t('the contracts') }}</strong></v-tab>
                  <v-tab class="font-weight-bold text-body-2"><strong>{{ $t('notes') }}</strong></v-tab>
               </v-tabs>
            </v-col>
            <v-col cols="12" class="pa-0">
               <v-divider></v-divider>
            </v-col>
            <v-sheet class="pa-5">
               <EmployeesPersonalInformation :employeeData="employeeData" v-if="tab === 0" />
               <EmployeesDocuments :employeeDocs="employeeDocs" v-if="tab === 1" />
               <SalaryIncreaseEntity :tab="tab" v-if="tab === 2" />
               <DeductionTransactionEntity :employeeId="employeeId" v-if="tab === 3" />
               <BenefitsTransactionsEntity :employeeId="employeeId" v-if="tab === 4" />
               <VacationTransactionEntity :employeeId="employeeId" :callGetData="callGetData" v-if="tab === 5" />
               <LoanEntity :employeeId="employeeId" :callGetData="callGetData" v-if="tab === 6" />
               <AllowancesTransactionsEntity :employeeId="employeeId" v-if="tab === 7" />
               <CovenantEntity :employeeId="employeeId" v-if="tab === 8" />
               <EmployeesContracts :employeesContract="employeesContract" v-if="tab === 9" />
               <EmployeesNotes v-if="tab === 10" />
            </v-sheet>
         </v-card>

         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import EmployeesPersonalInformation from './EmployeesPersonalInformation.vue';
import EmployeesDocuments from './EmployeesDocuments.vue';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import LazyImage from '@/components/ui/LazyImage.vue';
import EmployeesContracts from './EmployeesContracts.vue';
import SalaryIncreaseEntity from '../../Transactions/SalaryIncrease/SalaryIncreaseEntity.vue';
import DeductionTransactionEntity from '../../Transactions/DeductionTransaction/DeductionTransactionEntity.vue';
import BenefitsTransactionsEntity from '../../Transactions/BenefitsTransactions/BenefitsTransactionsEntity.vue';
import VacationTransactionEntity from '../../Transactions/VacationTransaction/VacationTransactionEntity.vue';
import LoanEntity from '../../Transactions/Loan/LoanEntity.vue';
import AllowancesTransactionsEntity from '../../Transactions/AllowancesTransactions/AllowancesTransactionsEntity.vue';
import CovenantEntity from '../../Transactions/Covenant/CovenantEntity.vue';
import EmployeesNotes from './EmployeesNotes.vue';


export default {
   name: "EmployeesProfile",

   data: () => ({
      pageData: {
         screen_code: "09-004",
         url: null,
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      viewForm: false,
      valid: false,
      openAvatar: false,
      employeeId: "",
      tab: 0,
      // endPoint: '',
      arabicAndEnglishName: "",
      employeesData: [],
      employeesContract: {},
   }),
   components: {
      EmployeesPersonalInformation,
      EmployeesDocuments,
      AnimatedLoading,
      SalaryIncreaseEntity,
      EmployeesContracts,
      DeductionTransactionEntity,
      BenefitsTransactionsEntity,
      VacationTransactionEntity,
      LoanEntity,
      AllowancesTransactionsEntity,
      LazyImage,
      CovenantEntity,
      EmployeesNotes
   },
   watch: {
      tab() {
         switch (this.tab) {
            case 9:
               this.$api.GET_METHOD(`get_employee_contracts/${this.$route.params.id}`).then((response) => {
                  this.employeesContract = response.data
               })
               break;
            default:
               // this.endPoint = true
               this.employeeId = this.$route.params.id
               break;
         }
      },
      // isLoading() {
      //    this.arabicAndEnglishName = `${this.employeeData.employee_name_ar} ${this.employeeData.family_name_ar} - ${this.employeeData.employee_name_en} ${this.employeeData.family_name_en}`
      // }

   },
   methods: {
      downloadAttachment(imageUrl, employeeData) {
         // Create an image element to load the image
         const img = new Image();
         img.crossOrigin = "anonymous"; // Enable cross-origin requests if necessary

         img.onload = function () {
            // Create a canvas to convert the image to base64
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            // Convert the image to base64
            const fileExtinction = imageUrl.lastIndexOf(".");
            const base64Data = canvas.toDataURL(`image/${imageUrl.slice(fileExtinction + 1)}`); // You can change the format if needed

            // Create a download link for the base64 image
            const anchor = document.createElement("a");
            anchor.href = base64Data;
            // const arabicAndEnglishName = `${employeeData.employee_name_ar} ${employeeData.family_name_ar} - ${employeeData.employee_name_en} ${employeeData.family_name_en}`
            const arabicAndEnglishName = `${employeeData.employee_name}`
            var fileName = `${employeeData.id}-${arabicAndEnglishName}${imageUrl.slice(fileExtinction)}`
            anchor.download = fileName; // Set the desired filename and extension

            // Trigger the download
            anchor.click();
         };

         img.src = imageUrl;
      },
      handelOpenAvatar() {
         if (this.employeeData.employee_image) {
            this.openAvatar = true
         }
      }
   },
   props: {
      isLoading: { type: Boolean },
      callGetData: { type: Function },
      employeeData: { type: Object },
      employeeDocs: { type: Array }
   }
};
</script>

<style lang="scss" >

</style>
