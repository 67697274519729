<template>
    <v-dialog v-model="openDialog" max-width="500" persistent>
        <v-card relative class="pa-9">
            <v-row align="center">
                <v-col cols="12">
                    <span class="font-weight-bold text-h6">{{ $t("Do you want to close the year") }}</span>
                    <v-btn absolute class="mt-0" :style="$vuetify.rtl ? 'left: 10px' : 'right: 10px'" small fab color="red5"
                        text @click="closeDialog">
                        <v-icon color="gray6">mdi-close</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" class="mt-11">
                    <v-btn depressed text @click="save" width="100%" height="44"
                        class="rounded-lg font-weight-bold white--text error">
                        {{ $t('closing the year') }}
                    </v-btn>
                </v-col>

                <v-col cols="12">
                    <v-btn  depressed width="100%" height="44" @click="closeDialog"
                    color="blue11" class="primary--text">
                        {{ $t('back') }}
                    </v-btn>
                </v-col>
            </v-row>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "FinancialYeaCancellationDialog",
    props: {
        openDialog: { default: false },
        pageData: { default: null },
        closeDialog: { type: Function },
        route: { default: null },

    },
    methods: {
        save() {

        }
    }
}
</script>

<style lang="scss" scoped></style>
