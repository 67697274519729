<template>
    <v-dialog v-model="openDialog" max-width="500" persistent>
        <v-card relative class="pa-5">
            <v-row align="center">
                <v-col cols="12">
                    <span class="font-weight-bold text-h6 ">{{ $t("the reason for not agreeing") }}</span>
                </v-col>
                <v-col cols="12">
                    <div class="text--h6">{{ rejectMass.rejected_reason }}</div>
                </v-col>
                <v-col cols="12">
                    <v-btn depressed text @click="closeDialog" width="100%" height="44"
                        class="rounded-lg font-weight-bold red5--text ">
                        {{ $t('back') }}
                    </v-btn>
                </v-col>

            </v-row>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "SalaryCalculationDialog",
    props: {
        openDialog: { default: false },
        closeDialog: { type: Function },
        rejectMass: { default: Object },
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped></style>
