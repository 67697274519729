<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :actionBtn="false" :showDeleted="false" :createBtn="false" :showClose="false"
            :actionBtnValid="true">
            <template v-slot:rightSide>
               <GenericInput type="select" :value="job_ads_id" @input="job_ads_id = $event" placeholder="Job run date"
                  :lookups="job_ads" selected_label="name" :clearable="true" :required="false" :multi="false"
                  selected_prop="id" :isLoading="false" :cols="['auto', 'auto', 'auto']" />
            </template>
            <template v-slot:leftSide>
               <!-- back -->
               <v-btn depressed @click="$router.push('/careers/jobs')" class="my-2 rounded-lg" text plain :height="40"
                  color="gray2">
                  {{ $t('back') }}
               </v-btn>

               <!-- sideDotsMenu -->
               <v-menu offset-y min-width="161">
                  <template v-slot:activator="{ on, attrs }">
                     <v-btn v-bind="attrs" v-on="on" depressed class="my-2 rounded-lg" text plain :height="40">
                        <v-icon>mdi-dots-horizontal</v-icon>
                     </v-btn>
                  </template>

                  <v-list>
                     <v-list-item link class="gray2--text font-weight-bold">
                        <v-list-item-title @click="shareDialog = true" class="d-flex justify-space-between">
                           <span>{{ $t('sharing') }}</span>
                           <v-icon color="black">mdi mdi-share-variant-outline</v-icon>
                        </v-list-item-title>
                     </v-list-item>
                     <v-divider></v-divider>
                     <v-list-item link class="gray2--text font-weight-bold" @click="dialogActions(rowData, 'suspense')">
                        <v-list-item-title class="d-flex justify-space-between">
                           <span>{{ $t('suspense') }}</span>
                           <v-icon color="black">mdi mdi-pause-circle-outline</v-icon>
                        </v-list-item-title>
                     </v-list-item>
                     <v-divider></v-divider>
                     <v-list-item @click="dialogActions(rowData, 'ending')" link class="gray2--text font-weight-bold">
                        <v-list-item-title class="d-flex justify-space-between">
                           <span>{{ $t('close') }}</span>
                           <v-icon color="black">mdi mdi-flag-triangle</v-icon>
                        </v-list-item-title>
                     </v-list-item>
                  </v-list>
               </v-menu>
            </template>
         </ControlHeader>
         <!-- End Of Header -->

         <v-row class="mt-3">
            <v-col cols="12" md="5">
               <v-card class="overflow-hidden py-5 px-1" elevation="0" height="100%">

                  <v-row class="employees_main_row" align-content="start">

                     <v-col cols="12" class="header d-flex flex-wrap">
                        <!-- Search -->
                        <GenericInput type="text" :value="search" @input="search = $event" placeholder="Search"
                           :keydownEnter="getSearch" :required="false" classes="search" :isLoading="false"
                           :cols="[12, 8, 8]">
                           <template v-slot:append>
                              <v-icon v-if="search" @click="clearSearch" color="secondary" class="cursor_pointer">
                                 mdi mdi-close</v-icon>
                              <v-icon @click="getSearch" class="cursor_pointer">mdi-magnify</v-icon>
                           </template>
                        </GenericInput>

                        <!-- Sort -->
                        <v-col cols="12" md="auto" class="py-1 px-1">
                           <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                 <v-btn v-bind="attrs" v-on="on" depressed outlined color="blue6" min-width="47">
                                    <v-icon color="gray2">mdi mdi-sort-ascending</v-icon>
                                 </v-btn>
                              </template>

                              <!-- Sort List-->
                              <v-list width="180" class="font-weight-bold gray6--text mt-1 pa-2">
                                 <v-radio-group v-model="selected" hide-details class="mt-0 pt-0">
                                    <v-radio v-for="(sort) in sort_list" :key="sort.id" :label="sort.name"
                                       :value="sort.value">
                                    </v-radio>
                                 </v-radio-group>
                              </v-list>
                           </v-menu>
                        </v-col>

                        <!-- Filter Dialog Btn -->
                        <v-col cols="12" md="auto" class="py-1 px-1">
                           <v-btn depressed outlined color="blue6" min-width="47" @click="filterDialog = true">
                              <v-icon color="gray2">mdi mdi-filter-outline</v-icon>
                           </v-btn>
                        </v-col>

                        <v-menu offset-y :close-on-content-click="false">
                           <!-- Filters List btn-->
                           <template v-slot:activator="{ on, attrs }">
                              <v-col cols="12" md="auto" class="py-1 text-start" v-bind="attrs" v-on="on"
                                 @click="filtersList = !filtersList; sortList = false">
                                 <v-btn depressed outlined color="purple4" class="px-3 justify-space-between" width="121">
                                    <span class="purple1--text">{{ $t("filter") }}</span>
                                    <v-icon color="purple1">mdi mdi-tune</v-icon>
                                 </v-btn>
                              </v-col>
                           </template>
                           <!--End Filters List btn-->

                           <!--  Filters List-->
                           <v-list width="350">
                              <v-btn v-for="(filter, i) in computedSelectedFilter" :key="i"
                                 v-show="computedSelectedFilter.length" @click="clearFilters(filter)" depressed outlined
                                 color="purple4" class="px-3 ma-2 justify-space-between">
                                 <span class="purple1--text">{{ translateSelectedFilter(filter) }}</span>
                                 <v-icon color="purple1">mdi mdi-window-close</v-icon>
                              </v-btn>
                              <div v-show="!computedSelectedFilter.length" class="text-center purple1--text mx-auto">
                                 {{ $t('There is no effective filter') }}
                              </div>
                           </v-list>
                           <!--  End Filters List-->
                        </v-menu>
                     </v-col>

                     <AnimatedLoading :height="100" classes="mx-auto" :hideText="true"
                        :isLoading="pageData.editIsLoading" />

                     <v-col cols="12" v-if="!employees.length && !pageData.isLoading">
                        <div class="gray9--text font-weight-bold text-center mt-16 px-2">
                           {{ $t('There are no data') }}
                        </div>
                     </v-col>

                     <!-- Employees List -->
                     <v-col cols="12" class="employees_col py-0" v-show="employees?.length && !pageData.isLoading"
                        v-for="(employee, i) in employees" :key="i">
                        <v-divider></v-divider>
                        <v-card class="employee_card mx-auto my-2 cursor_pointer"
                           :class="{ 'blue12': employee.is_read == 0 }" width="100%" elevation="0"
                           @click="getCvAndDetails(employee)">
                           <v-list-item three-line>
                              <v-avatar size="56">
                                 <LazyImage :height="80" :src="$api.serverUrl + employee?.image"
                                    :error="require('@/assets/img/svg/default_profile.svg')"
                                    :loading="require('@/assets/img/svg/default_profile.svg')" />
                              </v-avatar>

                              <v-list-item-content class="pa-2">
                                 <div class="d-flex justify-space-between ">
                                    <div>
                                       <span class="text-body-1 font-weight-bold">{{ employee?.full_name }}</span>

                                       <div class="d-flex mt-4 align-center">

                                          <!-- Gender -->
                                          <span v-if="employee?.gender">
                                             <v-img v-if="employee?.gender == 1"
                                                src="@/assets/img/svg_icons/male.svg"></v-img>
                                             <v-img v-if="employee?.gender == 2"
                                                src="@/assets/img/svg_icons/female.svg"></v-img>
                                          </span>

                                          <!-- Old -->
                                          <span v-if="employee?.age"
                                             class="mx-2 gray6--text text-caption font-weight-bold">
                                             {{ employee?.age }}
                                             {{ $t('Year') }}
                                          </span>

                                          <!-- Take Action -->
                                          <v-chip v-if="employee.is_read != 0 && employee.status != 0" min-width="85"
                                             height="30" :class="actionButton(employee)?.style" class="mx-2" elevation="0">
                                             <span class="mx-1"> {{ $t(actionButton(employee)?.name) }}</span>
                                          </v-chip>

                                          <!-- Rate -->
                                          <v-chip v-if="employee?.overall_rate && employee.is_read != 0" icon
                                             class="gray2--text white" elevation="0">
                                             <span class="mx-1"> {{ employee?.overall_rate }}</span>
                                             <v-img src="@/assets/img/svg_icons/rate.svg"></v-img>
                                          </v-chip>
                                       </div>
                                    </div>

                                    <!-- views -->
                                    <v-row class="text-end" align="end">
                                       <v-col cols="12">
                                          <v-tooltip bottom>
                                             <template v-slot:activator="{ on, attrs }">
                                                <v-avatar size="24" class="mr-n2" v-bind="attrs" v-on="on"
                                                   v-for="(view_img, i) in employee?.views_images?.split()" :key="i"
                                                   v-show="i <= 2">
                                                   <LazyImage :height="24" :width="24" :src="$api.serverUrl + view_img"
                                                      :error="require('@/assets/img/svg/default_profile.svg')"
                                                      :loading="require('@/assets/img/svg/default_profile.svg')" />
                                                </v-avatar>
                                             </template>
                                             <v-row>
                                                <v-col cols="12" v-for="(view, i) in employee?.views_users?.split()"
                                                   :key="i + view">
                                                   {{ view }}</v-col>
                                             </v-row>
                                          </v-tooltip>
                                       </v-col>

                                       <v-col cols="12">
                                          <div>
                                             {{ employee?.add_date }}
                                          </div>
                                       </v-col>
                                    </v-row>
                                 </div>
                              </v-list-item-content>
                           </v-list-item>
                        </v-card>
                     </v-col>
                     <!--End Employees List -->

                     <!-- Pagination -->
                     <v-col cols="12" class="pagination" v-if="employees?.length && !pageData.isLoading">
                        <div class="text-center">
                           <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber"
                              :backValueMethod="changePage" :isLoading="pageData.isLoading"></Pagination>
                        </div>
                     </v-col>
                     <!-- Pagination -->
                  </v-row>
               </v-card>
            </v-col>

            <!-- Preview Pdf -->
            <v-col cols="12" md="7">
               <v-card class="overflow-auto py-5 px-1" height="102dvh" elevation="0">
                  <v-row class="employees_details_row" justify="center" align="center"
                     v-if="!employeeData?.id">
                     <v-col cols="12">
                        <div class="gray9--text font-weight-bold text-center px-2">
                           {{ $t('Select one of the job applicants to display here') }}
                        </div>
                        <AnimatedLoading :height="100" classes="mx-auto" :isLoading="getCvAndDetailsLoading" />
                     </v-col>
                  </v-row>

                  <CareersDetailsAndCv v-show="employeeData.id" :employeeData="employeeData" />
               </v-card>
            </v-col>
            <!-- Preview Pdf -->
         </v-row>

         <ActionDialog :openDialog="actionDialog" :closeDialog="() => actionDialog = false" :rowData="rowData"
            :confirmAction="confirmAction" />

         <FilterDialog :openDialog="filterDialog" :closeDialog="() => filterDialog = false" :mainData="mainData"
            :getData="pushQueryParam" :queryParam="pageData.queryParam" />

         <ShareDialog :openDialog="shareDialog" :closeDialog="() => shareDialog = false"
            :rowData="mainData" />
      </v-container>
   </section>
</template>



<script>
import LazyImage from '@/components/ui/LazyImage.vue';
import FilterDialog from './FilterDialog.vue';
import CareersDetailsAndCv from './CareersDetailsAndCv.vue';
import ActionDialog from './ActionDialog.vue';
import ShareDialog from './ShareDialog.vue';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';

export default {
   name: "CareersJobCategory",
   components: { LazyImage, FilterDialog, CareersDetailsAndCv, ActionDialog, ShareDialog, AnimatedLoading },
   data: () => ({
      pageData: {
         screen_code: "10-001",
         url: null,
         controlRoute: "/careers/jobs-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
         page: 1,
         rows: [],
         options: {},
         pagination: {},
         rowsNumber: 15,
         queryParam: new URLSearchParams(),
      },
      employees: [],
      valid: false,
      tab: 0,
      page: 1,
      search: null,
      selectedCvId: null,
      job_ads_id: null,
      filtersList: false,
      sortList: false,
      filterDialog: false,
      actionDialog: false,
      shareDialog: false,
      selected: [],
      sort_list: [],
      selectedFilter: [],
      job_ads: [],
      rowData: {},
      mainData: {},
      cvData: {},
      employeeData: {},
      getCvAndDetailsLoading: false,
   }),
   watch: {
      $route() {
         this.queryParams();
         this.pageMainData();
         this.getData();
      },
      selected() {
         if (this.selected) {
            this.pageData.queryParam.set('sort', this.selected)
            this.pushQueryParam()
         }
      },
      job_ads_id(current, prev) {
         console.log(current, prev);
         prev && this.getData()
      }
   },
   computed: {
      computedSelectedFilter() {
         let FilterArray = this.selectedFilter
            .filter(filterArr =>
               filterArr != 'active' && filterArr != 'rows' && filterArr != 'page' && filterArr != 'word' && filterArr != 'sort')
         return FilterArray
      },
      translateSelectedFilter() {
         return (filter) => {
            if (filter == 'age') {
               filter = 'the age'
            }
            if (filter == 'gender') {
               filter = 'type'
            }
            if (filter == 'university') {
               filter = 'Universities/institutes'
            }
            if (filter == 'college') {
               filter = 'colleges'
            }
            if (filter == 'expected_salary') {
               filter = 'Expected Salary'
            }
            if (filter == 'current_salary') {
               filter = 'Current salary'
            }
            if (filter == 'overall_rate') {
               filter = 'overall assessment'
            }
            if (filter == 'acceptance_stages') {
               filter = 'Acceptance stages'
            }
            if (filter == 'is_read') {
               filter = 'They were seen'
            }
            if (filter == 'experience_years') {
               filter = 'Years of experience'
            }
            return this.$i18n.t(filter)
         }
      },
      actionButton(){
         return (employee)=>{
            switch (employee?.status) {
               // case 1 is applicated
               case 2: return{name:'interview', style: 'orange12 gray2--text'}
               case 3: return{name:'suspension', style: 'yellow10 gray2--text'}
               case 4: return{name:'accepted', style: 'green9 gray2--text mx-2'}
               case 5: return{name:'reject', style: 'gray11 red2--text mx-2'}
               case 6: return{name:'blocked', style: 'gray11 red2--text mx-2'}
            }
         }
      }
   },
   mounted() {
      this.queryParams();
      this.pageMainData();
      this.getData()
      this.sort_list = [
         {
            id: 1,
            name: this.$i18n.t('alphabetic'),
            value: 'alphabetic'
         },
         {
            id: 2,
            name: this.$i18n.t('the most recent'),
            value: 'desc'
         },
         {
            id: 3,
            name: this.$i18n.t('Highest rated'),
            value: 'top_rate'
         },
         {
            id: 4,
            name: this.$i18n.t('Lowest rating'),
            value: 'low_rate'
         },
      ]
   },
   methods: {
      queryParams() {
         this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
         this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
         this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
         this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
         this.pageData.queryParam = new URLSearchParams(this.$store.state.activeScreen.requestParam || '');
      },
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      clearFilters(KEY) {
         this.pageData.queryParam.delete(KEY)
         this.pushQueryParam()
      },
      clearSearch() {
         this.pageData.queryParam.delete('word')
         this.search = null;
         this.pushQueryParam()
      },
      getSearch() {
         this.pageData.queryParam.set('page', 1)
         this.pageData.queryParam.set('word', (this.search || ''))
         this.pushQueryParam()
      },

      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.selectedFilter = []
               for (const [key, value] of this.pageData.queryParam) {
                  console.log('queryParam', key, value);
                  this.selectedFilter.push(key);
               }

               this.$api.GET_METHOD(`career/${this.job_ads_id || this.$route.params.id}?${this.pageData.queryParam.toString()}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.mainData = response.data
                     this.rowData = response.data.data
                     this.job_ads_id = response.data.data.job_ad_id
                     response.data.job_ads.forEach((job) => {
                        job.name = job.from_date + ' - ' + job.to_date
                     })
                     this.job_ads = response.data.job_ads
                     this.employees = response.data.applicants.data;
                     this.pageData.page = response.data.applicants.current_page;
                     this.pageData.pagination = {
                        page: response.data.applicants.current_page,
                        totalPages: response.data.applicants.last_page,
                        per_page: response.data.applicants.per_page,
                        totalRows: response.data.applicants.total,
                     };
                     // this.$refs.form.validate();
                  }
               });
            }
            else {
               this.$router.push('/');
            }
         }

      },
      getCvAndDetails(row) {
         this.employeeData = row
      },
      changePage(page, limit) {
         this.pageData.queryParam.set('page', page)
         this.pageData.queryParam.set('rows', limit)
         this.pushQueryParam()
      },
      dialogActions(row, action_name) {
         if (row.status == 2 && action_name !== 'ending') {
            this.actionStatus = 1;
            this.actionMethod(row)
         } else {
            row.action_name = action_name
            this.rowData = row
            this.actionDialog = true;
         }
      },
      confirmAction(row) {
         if (row.action_name === 'suspense') {
            this.actionStatus = 2;
         } else if (row.action_name === 'ending') {
            this.actionStatus = 3;
         }
         this.actionMethod(row)
      },
      actionMethod(row) {
         this.$api.POST_METHOD(`career_toggle_status/${row.job_ad_id}`, { status: this.actionStatus }).then((response) => {
            if (response.check) {
               console.log("response", response);
               this.$router.push('/careers/jobs')
            }
         });
      },
      pushQueryParam() {
         return this.$router.push(this.pageData.controlRoute + this.$route.params.id + '?' + (this.pageData.queryParam.toString() || ''))
      }
   },
};
</script>

<style lang="scss" scoped>
.row.employees_main_row {
   height: 100dvh;
   overflow-y: auto;
}

.employees_details_row {
   height: 100dvh;
   width: 100%;
}

.employees_main_row .header {
   position: sticky;
   top: 0;
   background: white;
   z-index: 5;
}

.v-btn {
   border-radius: 8px !important;
}

.v-list.v-sheet.theme--light.white {
   border: 1px solid #00000029 !important;
   border-radius: 7px !important;
}

#sort {
   position: relative;
}

#sort_list {
   display: inline-block;
   position: absolute;
   top: 106%;
   left: -100%;
}

.employee_card:hover {
   transition: all 0.2s ease-in-out;
   background: rgba(128, 128, 128, 0.103);
}

.pagination {
   position: sticky;
   background: white;
   bottom: 0;
}
</style>
