import Vue from "vue";
import VueLazyload from 'vue-lazyload'
const loadImage = require('@/assets/img/png/files/loading.gif')
const errorImage = require('@/assets/img/png/files/default.svg')
Vue.use(VueLazyload, {
  lazyComponent: true,
  preLoad: 1.3,
  error: errorImage,
  loading: loadImage,
  attempt: 1
});

export default ({
});