<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: "Requests",

  data: () => ({

  }),
  components: {

  },
  watch: {

  },
  computed: {

  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>

</style>