<template>
  <section>
    <v-container fluid>
      <!-- Header -->

      <v-card
        elevation="0"
        class="ma-0 pa-0 px-5 transparent rounded-lg"
        v-if="!isLoading"
      >
        <v-row justify="space-between" align="center">
          <v-col cols="12" sm="auto" class="d-flex justify-center align-center">
            <div class="subtitle-2 text--disabled">
              <strong
                >{{ headerData.breadcramb[0].text }} .
                {{ headerData.breadcramb[1].text }} .</strong
              >
            </div>
            <div class="text-h6 mx-2 text-capitalize">
              <strong>{{ headerData.breadcramb[2].text }}</strong>
            </div>
          </v-col>
          <v-col cols="12" sm="auto" class="pa-0">
            <v-row justify="end" align="center">
              <v-col cols="12" sm="auto">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$i18n.t('Search')"
                  @click="activeTab = 'الكل'"
                  single-line
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="auto">
                <v-btn
                  block
                  class="my-2 mx-2 shadow"
                  color="success"
                  @click="save"
                  :disabled="requestBody.length == 0"
                >
                  <v-icon dark left>mdi-check-circle</v-icon>
                  {{ $t("save") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <!-- End Of Header -->

        <v-col cols="12" sm="12" class="d-flex justify-center align-center pt-3 pa-0">
          <v-tabs
            active-class="indigo darken-2 white--text"
            color="indigo darken-2"
            v-model="tab"
            v-if="!isLoading"
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab
              v-for="item in items"
              :key="item"
              class="font-weight-bold"
              @click="activeTab = item"
              >{{ item }}</v-tab
            >
          </v-tabs>
        </v-col>

      <v-row justify="center" v-if="isLoading">
        <v-col cols="auto" class="my-15">
          <v-progress-circular
            :size="70"
            :width="5"
            style="margin: auto"
            class="d-block"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <p class="text-center">{{ $t("Loading data") }}</p>
        </v-col>
      </v-row>
      <!-- Table -->

      <v-card elevation="0"  v-if="!isLoading">
        <PermissionsDataTableVue
          :isLoading="isLoading"
          :options="options"
          :data="rows.filter((row) => row.main_title == activeTab)"
          :activeTab="activeTab"
          v-if="activeTab !== 'الكل'"
          :header="header"
          :backValueMethod="setChanges"
        ></PermissionsDataTableVue>
        <PermissionsDataTableVue
          :isLoading="isLoading"
          :options="options"
          :activeTab="activeTab"
          :data="rows"
          v-if="activeTab == 'الكل' && !search"
          :header="header"
          :backValueMethod="setChanges"
        ></PermissionsDataTableVue>
        <PermissionsDataTableVue
          :isLoading="isLoading"
          :options="options"
          :activeTab="activeTab"
          :data="
            rows.filter(
              (row) =>
                row.sub_title.toLowerCase().indexOf(search) === 0 ||
                row.cat_title.toLowerCase().indexOf(search) === 0
            )
          "
          v-if="activeTab == 'الكل' && search"
          :header="header"
          :backValueMethod="setChanges"
        ></PermissionsDataTableVue>
      </v-card>
    </v-container>
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import PermissionsDataTableVue from "./PermissionsDataTable.vue";
const { mapState, mapActions } = createNamespacedHelpers("Settings");

export default {
  name: "PermissionsControl",

  data: () => ({
    search: "",
    id: null,
    isLoading: true,
    isEdit: false,
    editIsLoading: false,
    modules: [],
    categories: [],
    headerData: {},
    header: [],
    rows: [],
    options: {},
    tab: null,
    activeTab: "الكل",
    items: ["الكل"],
    requestBody: [],
  }),
  components: {
    PermissionsDataTableVue,
  },
  computed: {
    ...mapState(["favourites"]),
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      if (this.$global.CheckAction("01-008", 3)) {
        this.$api.GET_METHOD(`user/${this.$route.params.id}`).then((response) => {
          if (response.check) {
            this.editIsLoading = false;
            this.isLoading = false;
            // console.log("all_permissions", response.data.all_permissions);

            this.headerData = {
              title: response.data.data.user_full_name,
              breadcramb: [
                {
                  text: this.$i18n.t("Settings"),
                  disabled: true,
                  href: "breadcrumbs_dashboard",
                },
                {
                  text: this.$i18n.t("users permissions"),
                  disabled: true,
                  href: "main/user",
                },
                {
                  text: response.data.data.user_full_name,
                  disabled: true,
                  href: "",
                },
              ],
            };
            response.data.all_permissions.forEach((row) => {
              row.actions = row.actions.split(",").map((i) => Number(i));
              row.id = row.sub_id;
              row.view = row.permissions.view == true ? true : null;
              row.add = row.permissions.add == true ? true : null;
              row.edit = row.permissions.edit == true ? true : null;
              row.delete = row.permissions.delete == true ? true : null;
              row.print = row.permissions.print == true ? true : null;
              row.forceDelete = row.permissions.forceDelete == true ? true : null;
              row.availableActions = Object.keys(row.permissions);
              Object.keys(row.permissions).every((action) => {
                if (row.permissions[action] == true) {
                  row.allSelected = true;
                  return true;
                } else {
                  row.allSelected = false;
                  return false;
                }
              });

              if (
                this.$global.FilterArrayOfObject(this.modules, "main_id", row.main_id)
                  .length == 0
              ) {
                this.items.push(row.main_title);
                this.modules.push({
                  main_id: row.main_id,
                  main_title: row.main_title,
                });
              }
              if (
                this.$global.FilterArrayOfObject(this.categories, "cat_id", row.cat_id)
                  .length == 0
              ) {
                this.categories.push({
                  main_id: row.main_id,
                  cat_id: row.cat_id,
                  cat_title: row.cat_title,
                  main_title: row.main_title,
                });
              }
              this.rows.push(row);
              // console.log("row.availableActions", row.allSelected);
            });
            // console.log("categories", this.categories);
            // console.log("sssssssss", this.rows);
          }
        });
      } else {
        this.$router.push("/");
      }
    }
  },
  methods: {
    ...mapActions(["addFavourites"]),
    addToFav() {
      const fav = {
        screen_code: "",
        name: "users permissions",
        link: `/main/permissions-control/${this.$route.params.id}`,
      };
      this.addFavourites(fav).finally(() => {});
    },
    setChanges(permissions) {
      this.allChanges = permissions;
      // console.log("--------------------------------------------------");
      console.log("allChanges", permissions);
      permissions.forEach((permission) => {
        let prem = {
          sub_id: permission.sub_id,
          permissions: [],
        };
        permission.view == true ? prem.permissions.push(1) : null;
        permission.add == true ? prem.permissions.push(2) : null;
        permission.edit == true ? prem.permissions.push(3) : null;
        permission.delete == true ? prem.permissions.push(4) : null;
        permission.print == true ? prem.permissions.push(5) : null;
        permission.forceDelete == true ? prem.permissions.push(6) : null;
        this.checkEditablePremissions(prem);
      });
    },
    checkEditablePremissions(permission) {
      if (!this.requestBody.some((prem) => prem.sub_id === permission.sub_id)) {
        // console.log('set');
        this.requestBody.push(permission);
      } else {
        // console.log('update');
        var objIndex = this.requestBody.findIndex(
          (obj) => obj.sub_id == permission.sub_id
        );
        this.requestBody[objIndex] = permission;
      }
    },
    save() {
      this.isLoading = true;
      console.log("Save", this.requestBody);
      this.$api
        .POST_METHOD(`update_permission/${this.$route.params.id}`, {
          data: this.requestBody,
        })
        .then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.$router.push("/main/user_permission");
          }
        });
    },
  },
};
</script>
