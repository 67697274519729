import SalaryIncreaseEntity from "@/views/HRModule/Transactions/SalaryIncrease/SalaryIncreaseEntity.vue";
import SalaryIncreaseControl from "@/views/HRModule/Transactions/SalaryIncrease/SalaryIncreaseControl.vue";

import DeductionTransactionEntity from "@/views/HRModule/Transactions/DeductionTransaction/DeductionTransactionEntity.vue";
import DeductionTransactionControl from "@/views/HRModule/Transactions/DeductionTransaction/DeductionTransactionControl.vue";

import BenefitsTransactionsEntity from "@/views/HRModule/Transactions/BenefitsTransactions/BenefitsTransactionsEntity.vue";
import BenefitsTransactionsControl from "@/views/HRModule/Transactions/BenefitsTransactions/BenefitsTransactionsControl.vue";

import VacationTransactionEntity from "@/views/HRModule/Transactions/VacationTransaction/VacationTransactionEntity.vue";
import VacationTransactionControl from "@/views/HRModule/Transactions/VacationTransaction/VacationTransactionControl.vue";

import LoanEntity from "@/views/HRModule/Transactions/Loan/LoanEntity.vue";
import LoanControl from "@/views/HRModule/Transactions/Loan/LoanControl.vue";

import CovenantEntity from "@/views/HRModule/Transactions/Covenant/CovenantEntity.vue";
import CovenantControl from "@/views/HRModule/Transactions/Covenant/CovenantControl.vue";

import SalaryCalculationEntity from "@/views/HRModule/Transactions/Salary Calculation/SalaryCalculationEntity.vue";
import SalaryCalculationControl from "@/views/HRModule/Transactions/Salary Calculation/SalaryCalculationControl.vue";

import DisbursementEntity from "@/views/HRModule/Transactions/Disbursement/DisbursementEntity.vue";
import DisbursementControl from "@/views/HRModule/Transactions/Disbursement/DisbursementControl.vue";

import AllowancesTransactionsEntity from "@/views/HRModule/Transactions/AllowancesTransactions/AllowancesTransactionsEntity.vue";
import AllowancesTransactionsControl from "@/views/HRModule/Transactions/AllowancesTransactions/AllowancesTransactionsControl.vue";



// Routes
export const TransactionsRoutes = [

  // SalaryIncrease >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: "/transactions/salary_increase",
    name: "SalaryIncreaseEntity",
    component: SalaryIncreaseEntity,
    meta: {
      screen_code: "09-012",
    },
  },
  {
    path: "/transactions/salary_increase-control",
    name: "SalaryIncreaseControlAdd",
    component: SalaryIncreaseControl,
    meta: {
      screen_code: "09-012",
    },
  },
  {
    path: "/transactions/salary_increase-control/:id",
    name: "SalaryIncreaseControlEdit",
    component: SalaryIncreaseControl,
    meta: {
      screen_code: "09-012",
    },
  },

  // Deduction Transaction >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: "/transactions/deduction_transaction",
    name: "DeductionTransactionEntity",
    component: DeductionTransactionEntity,
    meta: {
      screen_code: "09-013",
    },
  },
  {
    path: "/transactions/deduction_transaction-control",
    name: "DeductionTransactionControlAdd",
    component: DeductionTransactionControl,
    meta: {
      screen_code: "09-013",
    },
  },
  {
    path: "/transactions/deduction_transaction-control/:id",
    name: "DeductionTransactionControlEdit",
    component: DeductionTransactionControl,
    meta: {
      screen_code: "09-013",
    },
  },
  // Benefits Transactions >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: "/transactions/benefit_transaction",
    name: "BenefitsTransactionsEntity",
    component: BenefitsTransactionsEntity,
    meta: {
      screen_code: "09-014",
    },
  },
  {
    path: "/transactions/benefit_transaction-control",
    name: "BenefitsTransactionsControlAdd",
    component: BenefitsTransactionsControl,
    meta: {
      screen_code: "09-014",
    },
  },
  {
    path: "/transactions/benefit_transaction-control/:id",
    name: "BenefitsTransactionsControlEdit",
    component: BenefitsTransactionsControl,
    meta: {
      screen_code: "09-014",
    },
  },

  // Vacation Transaction >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: "/transactions/vacation_transaction",
    name: "VacationTransactionEntity",
    component: VacationTransactionEntity,
    meta: {
      screen_code: "09-015",
    },
  },
  {
    path: "/transactions/vacation_transaction-control",
    name: "VacationTransactionControlAdd",
    component: VacationTransactionControl,
    meta: {
      screen_code: "09-015",
    },
  },
  {
    path: "/transactions/vacation_transaction-control/:id",
    name: "VacationTransactionControlEdit",
    component: VacationTransactionControl,
    meta: {
      screen_code: "09-015",
    },
  },

  // Loan >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: "/transactions/loan_transaction",
    name: "LoanEntity",
    component: LoanEntity,
    meta: {
      screen_code: "09-016",
    },
  },
  {
    path: "/transactions/loan_transaction-control",
    name: "LoanControlAdd",
    component: LoanControl,
    meta: {
      screen_code: "09-016",
    },
  },
  {
    path: "/transactions/loan_transaction-control/:id",
    name: "LoanControlEdit",
    component: LoanControl,
    meta: {
      screen_code: "09-016",
    },
  },
  // Allowances Transactions >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: "/transactions/allowance_transaction",
    name: "AllowancesTransactionsEntity",
    component: AllowancesTransactionsEntity,
    meta: {
      screen_code: "09-017",
    },
  },
  {
    path: "/transactions/allowance_transaction-control",
    name: "AllowancesTransactionsControlAdd",
    component: AllowancesTransactionsControl,
    meta: {
      screen_code: "09-017",
    },
  },
  {
    path: "/transactions/allowance_transaction-control/:id",
    name: "AllowancesTransactionsControlEdit",
    component: AllowancesTransactionsControl,
    meta: {
      screen_code: "09-017",
    },
  },

  // Covenant >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: "/transactions/covenant_transaction",
    name: "CovenantEntity",
    component: CovenantEntity,
    meta: {
      screen_code: "09-018",
    },
  },
  {
    path: "/transactions/covenant_transaction-control",
    name: "CovenantAdd",
    component: CovenantControl,
    meta: {
      screen_code: "09-018",
    },
  },
  {
    path: "/transactions/covenant_transaction-control/:id",
    name: "LoanControlEdit",
    component: CovenantControl,
    meta: {
      screen_code: "09-018",
    },
  },
  // Salary Calculation >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: "/transactions/salary_calculator_transaction",
    name: "SalaryCalculationEntity",
    component: SalaryCalculationEntity,
    meta: {
      screen_code: "09-019",
    },
  },
  {
    path: "/transactions/salary_calculator_transaction-control",
    name: "SalaryCalculationControlAdd",
    component: SalaryCalculationControl,
    meta: {
      screen_code: "09-019",
    },
  },
  {
    path: "/transactions/salary_calculator_transaction-control/:id",
    name: "SalaryCalculationControlEdit",
    component: SalaryCalculationControl,
    meta: {
      screen_code: "09-019",
    },
  },
  // Disbursement >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: "/transactions/disbursement_transaction",
    name: "DisbursementEntity",
    component: DisbursementEntity,
    meta: {
      screen_code: "09-020",
    },
  },
  {
    path: "/transactions/disbursement_transaction-control",
    name: "DisbursementControlAdd",
    component: DisbursementControl,
    meta: {
      screen_code: "09-020",
    },
  },
  {
    path: "/transactions/disbursement_transaction-control/:id",
    name: "DisbursementControlEdit",
    component: DisbursementControl,
    meta: {
      screen_code: "09-020",
    },
  },

]