// Reports Routes:-


import SalaryReport from "@/views/HRModule/Reports/SalaryReport/SalaryReport.vue"
import EmployeeStatement from "@/views/HRModule/Reports/EmployeeStatement/EmployeeStatement.vue"
// Routes
export const ReportsRoutes = [


  // Salary Report
  {
    path: "/reports/salary_report",
    name: "SalaryReport",
    component: SalaryReport,
    meta: {
      screen_code: "09-021",
    },
  },
  // EmployeeStatement
  {
    path: "/reports/employee_statement",
    name: "EmployeeStatement",
    component: EmployeeStatement,
    meta: {
      screen_code: "09-025",
    },
  },
];
