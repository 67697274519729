<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader v-if="!employeesContract" :pageData="pageData" :form="form" :save="save"
            :valid="valid && form.contract_file !== null ? true : false" :deleteMethod="deleteMethod"
            :showDeleted="false" :editForm="flase" :viewForm="() => viewForm = true"
            :actionBtn="!viewForm" :actionBtnValid="true" actionBtnText="contract renewal" actionBtnClass="secondary--text"
            actionBtnColor="white" :actionBtnClick="renewalContract">
            <template v-slot:leftSide>
               <v-btn v-if="!viewForm" @click="termination_contract = true" depressed class="mx-2 rounded-lg white--text"
                  color="secondary" :height="40">
                  {{ $t("Termination of contract") }}
               </v-btn>
            </template>
         </ControlHeader>
         <!-- pageData.isEdit && !viewForm -->
         <!-- End Of Header -->

         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading"
            v-if="pageData.isEdit && !viewForm && !employeesContract">
            <template v-slot:summerySlot="{ item }">
               <span class="font-weight-bold text-capitalize">{{ $t(item.label) }} :</span>
               <span class="text-capitalize" v-if="!item.value.length">
                  {{ $t('no allowances for this employee') }}
               </span>
               <table class="text-capitalize" v-if="item.value.length">
                  <thead>
                     <tr>
                        <th>{{ $t("allowance name") }}</th>
                        <th>{{ $t("allowance value") }}</th>
                        <th>{{ $t("type of allowance") }}</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(data, i) in item.value" :key="i">
                        <td>
                           {{ $global.FilterArrayOfObjectElement(allowances, "id", data.salary_effect_id, "name") }}
                        </td>
                        <td>
                           {{
                              data.value_type == 2 ?
                              $global.ConvertToPercentage(data.value, form.salary_fixed) :
                              data.value | float
                           }}
                        </td>
                        <td>{{ $global.FilterArrayOfObjectElement(value_type, "id", data.value_type, "name") }}</td>
                     </tr>
                  </tbody>
               </table>
            </template>
         </ControlSummery>
         <!-- End of Summery -->

         <!-- Animation on Create  -->
         <AnimatedLoading v-if="!pageData.isEdit" classes="mt-16" :hideText="false"
            :isLoading="!loadingData && !employeesContract" />

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3 mt-5" v-if="loadingData && viewForm || employeesContract">
            <v-card class="backgroundW shadow overflow-hidden pa-0">
               <v-sheet class="pa-5">
                  <v-row align="center">
                     <GenericInput type="select" v-if="!employeesContract && !viewRenewalContract"
                        :value="form.employee_id" @input="form.employee_id = $event" label="employee name"
                        :lookups="employeesFiltered" selected_label="employee_name" :required="true" selected_prop="id"
                        :disabled="pageData.isEdit" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                        <template v-slot:append>
                           <v-icon>mdi-magnify</v-icon>
                        </template>
                     </GenericInput>

                     <GenericInput type="date" :value="form.start_date" @input="form.start_date = $event"
                        label="start date" :required="true" :isLoading="false"
                        :cols="!employeesContract && !viewRenewalContract ? [12, 4, 4] : [12, 6, 6]">
                     </GenericInput>

                     <GenericInput type="date" :value="form.end_date" @input="form.end_date = $event" label="end date"
                        :required="true" :minDate="form.start_date" :isLoading="false"
                        :cols="!employeesContract && !viewRenewalContract ? [12, 4, 4] : [12, 6, 6]">
                     </GenericInput>
                  </v-row>

                  <v-row align="center">
                     <v-col cols="12" class="px-4">
                        <v-divider></v-divider>
                     </v-col>

                     <GenericInput type="float" :value="Math.abs(form.salary_fixed)" @input="form.salary_fixed = $event"
                        label="fixed salary" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                     </GenericInput>

                     <GenericInput type="float" :value="Math.abs(form.housing_allowance)"
                        @input="form.housing_allowance = $event" label="housing allowance" :required="false"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                     </GenericInput>

                     <v-scroll-y-transition>
                        <v-col cols="12" v-if="form.salary_total">
                           <div class="d-flex pa-2 justify-space-between gray3 white--text rounded-lg">
                              <div>{{ $t('total salary') }}</div>
                              <div>{{ form.salary_total | float }}</div>
                           </div>
                        </v-col>
                     </v-scroll-y-transition>

                     <GenericInput type="file"
                        :class="valid && !form.contract_file ? 'upload_contract_warning' : 'upload_contract'"
                        :value="form.contract_file" @input="form.contract_file = $event" label="image" :multi="false"
                        :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-row>

                  <v-col cols="12" class="px-1 my-2">
                     <v-divider></v-divider>
                  </v-col>

                  <v-row align="center" v-for="(add, i) in allowancesFilter" :key="i" v-show="form.contract_d">
                     <GenericInput type="select" :value="form.contract_d[i].salary_effect_id"
                        @input="form.contract_d[i].salary_effect_id = $event" selected_prop="id" icon="mdi-chevron-down"
                        label="allowance name" :lookups="filterAllowances" disableItem="disable" selected_label="name"
                        :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                     </GenericInput>

                     <GenericInput :type="form.contract_d[i].value_type == 1 ? 'float' : 'percentage'"
                        @input="form.contract_d[i].previewValue = $event"
                        :value="Math.abs(form.contract_d[i].previewValue)" label="allowance value"
                        :isLoading="pageData.editIsLoading" :required="true" :cols="[12, 4, 4]">
                     </GenericInput>

                     <GenericInput type="radiogroup" :lookups="value_type" selected_label="name" selected_prop="id"
                        :value="form.contract_d[i].value_type" :required="true"
                        @input="form.contract_d[i].value_type = $event" label="" :isLoading="false"
                        :cols="[12, 'auto', 'auto']">
                     </GenericInput>

                     <v-col cols="auto">
                        <v-btn :append="false" icon elevation="0" @click="deleteRow(add, i)">
                           <v-icon color="error">mdi-close-circle-outline</v-icon>
                        </v-btn>
                     </v-col>
                  </v-row>

                  <v-col cols="12" class="px-0 py-2 d-flex mt-3">
                     <v-btn elevation="0" @click="addRow()" :disabled="form.contract_d.length == allowances.length"
                        class="green--text pa-10" width="100%">
                        <v-icon color="success" class="px-1">mdi-file-plus-outline</v-icon>
                        {{ $t('add new allowance') }}
                     </v-btn>
                  </v-col>


               </v-sheet>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>

      <TerminationContractDialog :openDialog="termination_contract" :closeDialog="() => (termination_contract = false)"
         :employees="employees" />

   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlSummery from "@/components/ui/ControlSummery.vue";
import AnimatedLoading from "@/components/ui/AnimatedLoading.vue";
import TerminationContractDialog from "./TerminationContractDialog.vue";

export default {
   name: "ContractsControl",

   data: () => ({
      pageData: {
         screen_code: "09-023",
         url: null,
         controlRoute: "/codes/contract-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      viewForm: false,
      summery: [],
      valid: false,
      send_valid: false,
      value_type: [],
      employees: [],
      allowances: [],
      changes: [],
      valueTypeName: null,
      add_allowances: [],
      add_allowances_counter: 1,
      resetCount: 0,
      loadingData: false,
      termination_contract: false,
      check_allowances_values: null,
      calc_allowances_values: null,
      validation_allowances_values: null,
      form: {
         employee_id: null,
         salary_total: null,
         salary_fixed: null,
         start_date: null,
         end_date: null,
         contract_file: null,
         housing_allowance: 0,
         contract_d: [],
      },
      form_after_change: {},
      form_without_changes: {},
      form_count: 0,
      viewRenewalContract: false
   }),
   components: {
      GenericInput,
      ControlHeader,
      ControlSummery,
      AnimatedLoading,
      TerminationContractDialog
   },
   computed: {
      filterAllowances() {
         return this.allowances.filter((allowance) => {
            if (this.form.contract_d.some(add_all => allowance.id === add_all.salary_effect_id)) {
               return allowance.disable = true
            } else {
               allowance.disable = false
               return allowance
            }
         })
      },
      allowancesFilter() {
         return this.form.contract_d.map((allowance) => {
            if (allowance.value_type === 2) {
               this.resetCount = 1
               allowance.present = allowance.previewValue
               allowance.previewValue = allowance.present
               allowance.value = Number(this.$global.ConvertFromPercentage(allowance.present, this.form.salary_fixed))
               this.form.salary_total = (this.form.salary_fixed + this.form.housing_allowance) + this.form.contract_d.reduce((prev, current) => prev + current.value, 0)
               return allowance
            } else if (allowance.value_type === 1) {
               allowance.present = null;
               allowance.value = allowance.previewValue
               this.form.salary_total = (this.form.salary_fixed + this.form.housing_allowance) + this.form.contract_d.reduce((prev, current) => prev + current.value, 0)
               console.log(this.resetCount);
               return allowance
            }

         })

      },
      employeesFiltered() {
         return this.employees.filter(employee => !employee.contract_m_id)
      }

   },
   props: {
      employeesContract: { type: Object },
      reciveFormData: { type: Function }
   },
   mounted() {
      this.pageMainData()
      !this.employeesContract ? this.getData() : this.getEmployeeData()
   },
   watch: {
      "form.salary_fixed"() {
         this.form.salary_total = (this.form.salary_fixed + this.form.housing_allowance) + this.form.contract_d.reduce((prev, current) => prev + current.value, 0)
      },
      "form.housing_allowance"() {
         this.form.salary_total = (this.form.salary_fixed + this.form.housing_allowance) + this.form.contract_d.reduce((prev, current) => prev + current.value, 0)
      },
      form: {
         handler(newValue) {
            for (let key in newValue) {
               if (newValue[key] !== this.form_without_changes[key]) {
                  this.form_after_change[key] = newValue[key];
               }
            }

            console.log("form_after_chenge: ", this.form_after_change);
         },
         deep: true,
      },
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            console.log(this.$route.params.id);
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`contract/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.loadingData = true;
                     this.employees = response.data.employees;
                     this.allowances = response.data.allowances;
                     this.form = { ...response.data.master, contract_d: [] };
                     if (response.data.details.length) {
                        // show allowance if exist 
                        response.data.details.forEach(details => {
                           details.previewValue = details.value
                           if (details.value_type == 2) {
                              details.previewValue = Number(this.$global.ConvertToPercentage(details.value, this.form.salary_fixed))
                           }

                        })
                        this.form.contract_d = response.data.details
                     }
                     console.log("contract form", this.form);
                     this.form_without_changes = { ...this.form }
                     this.form.contract_d = response.data.details
                     this.summery = [
                        {
                           label: 'name',
                           value: response.data.master.employee_name || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'fixed salary',
                           value: response.data.master.salary_fixed || '-',
                           type: 'float',
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'housing allowance',
                           value: response.data.master.housing_allowance || 0,
                           type: 'float',
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'total salary',
                           value: response.data.master.salary_total || '-',
                           type: 'float',
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'start date',
                           value: response.data.master.start_date || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'end date',
                           value: response.data.master.end_date || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'allowances names',
                           value: response.data.details || '-',
                           type: "slot",
                           class: '',
                           cols: null, sm: null, md: 12, lg: 12, xl: 12,
                        }
                     ]
                     this.viewSummery = true;
                     // this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`contract/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.loadingData = true;
                     this.form.contract_d = [
                        {
                           salary_effect_id: null,
                           value: null,
                           previewValue: null,
                           value_type: 1
                        }
                     ]
                     this.employees = response.data.employees;
                     response.data.allowances.forEach((element) => {
                        element.disable = false
                     });
                     this.allowances = response.data.allowances
                  }
               })
            } else {
               this.$router.push('/')
            }

         }
         this.value_type = [
            { id: 1, name: this.$i18n.t('amount') },
            { id: 2, name: this.$i18n.t('percent') }
         ]

      },
      getEmployeeData() {
         this.pageData.isLoading = true;
         this.form.employee_id = +this.$route.params.id
         this.allowances = this.employeesContract.allowances;
         this.value_type = [
            { id: 1, name: this.$i18n.t('amount') },
            { id: 2, name: this.$i18n.t('percent') }
         ]
         this.pageData.isLoading = false;
      },
      save() {
         if (this.$refs.form.validate()) {
            this.form.contract_d = this.form.contract_d.filter(e => e.value > 0)
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`contract/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  } else {
                     console.log("error", response);
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`contract`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`contract/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      ConvertFromPercentage(Percent, Total) {
         return ((Percent / 100) * Total).toFixed(0);
      },
      addRow() {
         if (this.form.contract_d.length <= this.allowances.length) {
            if (this.pageData.isEdit) {
               this.form.contract_d.push(
                  {
                     id: 0,
                     salary_effect_id: null,
                     value: null,
                     previewValue: null,
                     value_type: 1
                  }
               )
            } else {
               this.form.contract_d.push(
                  {
                     salary_effect_id: null,
                     value: null,
                     previewValue: null,
                     value_type: 1
                  }
               )
            }
         }
      },
      deleteRow(row, index) {
         this.form.contract_d.splice(index, 1)
         this.form.salary_total = this.form.salary_fixed + this.form.housing_allowance + this.form.contract_d.reduce((prev, current) => prev + current.value, 0)
         this.allowances.map((allowance) => allowance.id === row.salary_effect_id ? allowance.disable = false : allowance)
         this.form.contract_d.map(add_allowance => {
            this.allowances.map(allowances => {
               if (add_allowance.salary_effect_id !== allowances.id) {
                  allowances.disable = false
               }
            })
         })
      },
      renewalContract() {
         this.viewForm = true
         this.viewRenewalContract = true
         this.form_without_changes = {}
         this.form = {
            employee_id: null,
            salary_total: null,
            salary_fixed: null,
            start_date: null,
            end_date: null,
            contract_file: null,
            housing_allowance: 0,
            contract_d: [],
         }
         this.form.employee_id = this.employees[0].id
         this.form.id = +this.$route.params.id
         console.log('this.employees', this.employees);
         console.log(' this.form. ', this.form);
      }
   },
   updated() {
      if (this.reciveFormData) {
         this.send_valid = this.valid && this.form.contract_file !== null ? true : false
         return this.reciveFormData(this.form, this.send_valid)
      }
   }
};
</script>

<style lang="scss" scoped>
.dropzone_col {
   position: relative;
   overflow: hidden !important;

   .vue-dropzone.dropzone.dz-clickable {
      width: 150px !important;
   }

   .userUploadImage {
      position: absolute;
      top: -56px;
      left: 50%;
      transform: translateX(-50%);
      padding: 0;
      opacity: 0;
   }



   .upload_contract {
      cursor: pointer;


      span {
         overflow: hidden;
      }

      input {
         border-radius: 9px;
         height: 43px;
         width: 250px;
         position: absolute;
         left: -18px;
         opacity: 0;
         cursor: pointer;
      }
   }
}

.add_row.col.col-1 {
   position: relative;
   top: -48px;
   right: 930px;
}

.add_row_employee {
   position: relative;
   top: -48px;
   right: 1095px;
}

table {
   margin-top: 15px;
   font-family: arial, sans-serif;
   border-collapse: collapse;
   width: 100%;
}

td,
th {
   outline: 1px solid #dddddd;
   text-align: left;
   padding: 8px;
}
</style>
