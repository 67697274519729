<template>
  <v-container style="height: 100vh" class="d-flex align-center justify-center bgError">
    <div>
      <v-row align="center" justify="center">
        <v-col cols="12" class="mt-16 d-flex justify-center">
          <AnimatedLogo :height="150" />
        </v-col>
        <v-col cols="auto" class="mt-2">
          <h1 class="text-center">{{ $t('New update is downloading') }}</h1>
          <p class="my-3 text-center">
            {{ $t("It may take a few minutes, please wait") }}.
          </p>
          <!-- <img src="@/assets/img/png/files/loading.gif" class="mt-5" :height="50" alt=""> -->
          <AnimatedLoading :isLoading="true" :hideText="true" :height="70" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style scoped lang="scss">
.bgError {
  //background-image: url("../../assets/img/svg/errorBg.svg");
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  img {
    margin: auto;
    display: block;
    max-height: 200px;
  }
}
</style>

<script>
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import AnimatedLogo from '@/components/ui/AnimatedLogo.vue';
export default {
  name: "SystemUpdate",
  computed: {},
  data: () => ({
    registration: null,
    updateExists: false,
    refreshing: false,
  }),
  created() {
    document.addEventListener("swUpdated", this.swUpdated, { once: true });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing)
        return;
      this.refreshing = true;
      window.location.reload();
    });
    document.addEventListener("swReady", this.swReady, { once: true });
  },
  methods: {
    swUpdated(event) {
      console.log("event", event);
      this.registration = event.detail;
      // this.$global.ShowAlert('info', this.$i18n.t('New update is available, this page will refresh'));
      this.refreshApp();
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting)
        return;
      this.$router.push("/");
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    swReady() {
      console.log("hhhhhhhhhhhhhhhhh");
      console.log(this.$route.fullPath);
      if (this.$route.fullPath == "/system_update") {
        this.$router.push("/");
      }
    }
  },
  mounted() {
  },
  components: { AnimatedLogo, AnimatedLoading }
};
</script>
