<template>
   <v-row justify="center">
      <v-dialog v-model="pageData.controlDialog" max-width="656">
         <v-card relative class="pa-5">
            <v-form ref="form" v-model="valid">
               <div v-if="!getDataLoading">

                  <v-card-title class="text-h6 text-center font-weight-bold">
                     <h5 class="d-flex font-weight-bold text-h6 justify-end text-capitalize">
                        {{ pageData.controlID ? $t("edit deduction") : $t("create deduction") }}
                     </h5>
                  </v-card-title>

                  <v-col cols="12" class="py-2">

                     <v-row align="center" class="mt-6">
                        <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="arabic name"
                           :required="true" :isLoading="false" :cols="[6, 6, 6]" />
                        <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event"
                           label="deduction english name" :required="true" :isLoading="false" :cols="[6, 6, 6]" />

                        <GenericInput type="select" :lookups="account_trees" :value="form.account_tree_id"
                           @input="form.account_tree_id = $event" label="choose linked account" :multi="false"
                           :required="false" selected_label="name" selected_prop="id" :isLoading="false"
                           :cols="[12, 12, 12]">
                        </GenericInput>

                     </v-row>
                     <v-row align="center" justify="end">
                        <v-col cols="auto">
                           <v-btn depressed text @click="closeDialog" :width="120"
                              class="rounded-lg font-weight-bold white--text" color="error" :loading="saveDataLoading">
                              {{ $t('back') }}
                           </v-btn>
                        </v-col>

                        <v-col cols="auto">
                           <v-btn color="success" depressed :width="120" :disabled="!valid" @click="save"
                              class="rounded-lg font-weight-bold white--text" :loading="saveDataLoading">
                              {{ isEdit ? $t('save') : $t('create') }}
                           </v-btn>
                        </v-col>
                     </v-row>
                  </v-col>
               </div>
               <v-row justify="center" v-if="getDataLoading">
                  <v-col cols="auto" class="my-10">
                     <AnimatedLoading :height="100" :hideText="false" :isLoading="getDataLoading" />
                  </v-col>
               </v-row>
            </v-form>

            <v-card-actions>

            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-row>
</template>







<script>
import GenericInput from "@/components/ui/GenericInput.vue";
import AnimatedLoading from "@/components/ui/AnimatedLoading.vue";
export default {
   name: "DeductionControl",
   props: {
      backValueMethod: { type: Function },
      isLoading: { default: false },
      pageData: { default: {} },
   },
   computed: {
      isEdit() {
         return this.pageData.controlID ? true : false
      }
   },
   components: {
      GenericInput,
      AnimatedLoading
   },
   watch: {
      isEdit() {


      }
   },
   data: () => ({
      valid: false,
      getDataLoading: false,
      saveDataLoading: false,
      controlRoute: "/codes/deduction-control",
      radioEx: [{ id: 1, name: 'radio 1' }, { id: 2, name: 'radio 2' }],
      account_trees: [],
      form: {
         name_en: null,
         name_ar: null,
         account_tree_id: null,

      }
   }),
   methods: {
      closeDialog() {
         this.pageData.controlDialog = false;
         this.$refs.form.reset()
      },
      save() {
         if (this.$refs.form.validate()) {
            this.saveDataLoading = true;
            if (this.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`deduction/${this.pageData.controlID}`, this.form).then((response) => {
                  this.saveDataLoading = false;
                  if (response.check) {
                     this.pageData.controlID = null;
                     this.pageData.controlDialog = false
                     this.$refs.form.reset();
                     this.backValueMethod();
                  }
               })
            }
            else {
               this.form.type = 2
               this.$api.POST_METHOD(`deduction`, this.form).then((response) => {
                  this.saveDataLoading = false;
                  if (response.check) {
                     this.pageData.controlID = null;
                     this.pageData.controlDialog = false;
                     this.$refs.form.reset();
                     this.backValueMethod();
                  }
               })
            }
         }



      },
      getData() {
         switch (this.isEdit) {
            case true:
               if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
                  this.getDataLoading = true;

                  this.$api.GET_METHOD(`deduction/${this.pageData.controlID}`).then((response) => {
                     this.getDataLoading = false;
                     if (response.check) {
                        this.form = response.data.data;
                        this.account_trees = response.data.account_trees;
                        this.$refs.form.validate();
                     }
                  })
               } else {
                  this.pageData.controlDialog = false;
               }
               break;
            default:
               if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
                  this.getDataLoading = true;
                  this.$api.GET_METHOD(`deduction/create`).then((response) => {
                     this.getDataLoading = false;
                     if (response.check) {
                        this.account_trees = response.data.account_trees;
                     }
                  })
               } else {
                  this.pageData.controlDialog = false;
               }

               break;
         }
      }

   },
   mounted() {
      this.getData()
   },
};
</script>

<style scoped lang="scss"></style>