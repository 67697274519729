<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader v-if="!employeeId" :pageData="pageData" :getData="getData" :getSearch="getSearch" />
      <!-- End Of Header -->

      <!-- Employee Header -->
      <v-col cols="12" sm="12" v-if="employeeId">
        <div class="d-flex justify-space-between align-center">
          <span class="font-weight-bold text-h6 ma-0">{{ $t("deductions") }}</span>
          <v-btn depressed :min-width="90" @click="openForm = true" class="my-2 rounded-lg" color="primary" :height="37"
            v-if="!openForm">
            <v-icon left>mdi-plus</v-icon>
            {{ $t("add new deduction") }}
          </v-btn>

          <div v-if="openForm">
            <v-btn depressed @click="openForm = false" :min-width="90" :loading="pageData.isLoading" text
              class="my-2 mx-2 rounded-lg white--text" :height="40" color="red">
              {{ $t('back') }}</v-btn>
            <v-btn depressed @click="save_employee" :disabled="!valid" :min-width="90" :loading="loading"
              class="my-2 mx-2 rounded-lg white--text" :height="40" color="success">
              {{ $t('create') }}</v-btn>
          </div>
        </div>
      </v-col>

      <DeductionTransactionControl v-if="openForm" :openProfileForm="openForm" :saveEmployeeData="saveEmployeeData" />
      
      <!-- End Employee Header -->
      <v-row v-if="!openForm">
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
            :option="pageData.options" :editValueMethod="edit" :pageData="pageData" :deleteValueMethod="deleteMethod"
            :restoreValueMethod="restoreMethod" :footer_style="{ 'background-color': 'var(--blue10) !important' }"
            :printValueMethod="printMethod">
            <template v-slot:th="{ header }">
              {{ $t(header.text) }}
            </template>
            <template v-slot:td="{ row ,header}">
              <div v-if="header.key == 'employee_name'">
                <a @click="navigationToProfile(row)" class="text-decoration-underline">
                  {{ row.employee_name }}
                </a>
              </div>
              <div v-if="header.key == 'one_time'">
                {{ $global.FilterArrayOfObjectElement(deduction_type, "id", row.one_time, 'name') }}
              </div>
            </template>
            <template v-slot:afterTable>
              <v-row v-if="!pageData.isLoading && pageData.rows.length && employeeId" justify="space-between" class="font-weight-bold text-center text-body-2 mt-2">
                <v-col cols="4">{{ $t('total number of deductions') }}</v-col>
                <v-col cols="2">{{ deductionData_length | float }}</v-col>
                <v-col cols="4">{{ $t('total amount deductions') }}</v-col>
                <v-col cols="2">{{ total_deduction_amount | float }}</v-col>
              </v-row>
            </template>
          </DynamicTable>
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12" v-if="!employeeId">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeductionTransactionPrint :printAction="() => print = false" :print="print" :printData="printData"
      :printStaticData="printStaticData" />

  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import DeductionTransactionControl from "./DeductionTransactionControl.vue";
import DeductionTransactionPrint from "./DeductionTransactionPrint.vue";
export default {
  name: "DeductionTransactionEntity",

  data: () => ({
    pageData: {
      screen_code: "09-013",
      url: null,
      controlRoute: "transactions/deduction_transaction-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      deduction_type: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?',
      printLoading: {
        loading: false,
        id: null,
        key: 'id'
      },
    },
    value_type: [],
    openForm: false,
    valid: false,
    loading: false,
    form: {},
    deductionData_length: 0,
    printData: [],
    printStaticData: Object,
    print: false,
  }),
  components: {
    EntityHeader,
    Pagination,
    DynamicTable,
    DeductionTransactionControl,
    DeductionTransactionPrint
  },
  computed: {
    newTotalRow() {
      return {
        id: this.$i18n.t("total number of deductions"),
        add_date: this.deductionData_length,
        amount: this.$i18n.t("total amount deductions"),
        notes: this.total_deduction_amount.toFixed(2),
      }
    }
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.employeeId ? this.getEmployeeData() : this.getData();
  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.employeeId ? this.getEmployeeData() : this.getData();
    },
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      !this.employeeId ? this.pageData.tableHeader = [
        { text: "serial", key: "id", type: 'text', classes: "" },
        { text: "created at", key: "add_date", type: 'text', classes: "" },
        { text: "date of starting", key: "action_month", type: 'text', classes: "" },
        { text: "employee name", key: "employee_name", type: 'slot', classes: "" },
        { text: "discount type", key: "value_type", type: 'text', classes: "" },
        { text: "deduction type", key: "one_time", type: 'slot', classes: "" },
        { text: "the amount", key: "amount", type: 'float', classes: "" },
        { text: "actions", key: "id", type: 'actions', classes: "" },
      ] :
        this.pageData.tableHeader = [
          { text: "withholding number", key: "id", type: 'text', classes: "" },
          { text: "withholding date", key: "add_date", type: 'text', classes: "" },
          { text: "the amount", key: "amount", type: 'text', classes: "" },
          { text: "notes", key: "notes", type: 'text', classes: "" },
        ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${1}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`deduction_actions${this.pageData.queryParam}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              // rewrite deduction type
              response.data.items.data.forEach(row => {
                switch (row.value_type) {
                  case 1:
                    row.value_type = this.$i18n.t('hour')
                    break;
                  case 2:
                    row.value_type = this.$i18n.t('work days')
                    break;
                  case 3:
                    row.value_type = this.$i18n.t('value')
                    break;
                }
              });
              // End rewrite deduction type
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
      this.value_type = [
            {
               id: 1,
               name: this.$i18n.t('hour')
            },
            {
               id: 2,
               name: this.$i18n.t('work days')
            },
            {
               id: 3,
               name: this.$i18n.t('value')
            },
         ]
      this.deduction_type = [
            {
               id: 0,
               name: this.$i18n.t('monthly')
            },
            {
               id: 1,
               name: this.$i18n.t('one time')
            },
         ]
    },
    getEmployeeData() {
      this.pageData.isLoading = true;
      this.$api
        .GET_METHOD(`get_emp_deduction/${this.employeeId}${this.pageData.queryParam}`)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.pageData.rows = response.data;
            this.deductionData_length = response.data.length;
            this.total_deduction_amount = response.data.reduce((prev, current) => prev + +current.amount, 0);
            response.data.forEach(e => {
              if (typeof e.amount !== Number) {
                e.amount = (e.amount).toFixed(2)
              }
            })
          }
        });
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`deduction_actions/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`deduction_action_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/purchases_vendor/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
    saveEmployeeData(form, valid) {
      this.form = form
      this.valid = valid
    },
    save_employee() {
      if (this.valid) {
        this.loading = true;
        this.form.employee_id = +this.employeeId
        this.$api.POST_METHOD(`deduction_actions`, this.form).then((response) => {
          this.loading = false;
          if (response.check) {
            this.openForm = false
            this.getEmployeeData()
          }
        })
      }
    },
    navigationToProfile(row) {
      this.$router.push(`/codes/employee-control/${row.employee_id}`)
    },
    printMethod(row) {
      this.pageData.printLoading.loading = true;
      this.pageData.printLoading.id = row.id;
      this.$api
        .GET_METHOD(`deduction_actions/${row.id}`).then((response) => {
          this.pageData.printLoading.loading = false;
          if (response.check) {
            this.printData = [response.data.data];
            this.printStaticData.master = response.data.data;
            this.printStaticData.deductions = this.value_type;
            this.print = true;
            setTimeout(() => {
              this.printData = [];
              this.printStaticData = Object;
              this.print = false;
              this.pageData.printLoading.loading = false;
            }, 1000);
          }
        });
    },
  },
  props: {
    employeeId: { type: String }
  }
};
</script>
