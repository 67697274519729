<template>
    <v-container fluid>
        <div v-if="employeeDocs">
            <v-row class="id_number align-center" v-for="(doc, i) in reciveData" :key="doc.id">
                <v-col cols="3" class="font-weight-bold">{{ doc.document_name }}</v-col>
                <v-col cols="3">{{ doc.number }}</v-col>
                <v-col cols="3" class="d-flex" @click="downloadAttachment(doc)">
                    <a class="px-3">{{ doc.doc_file.slice(doc.doc_file.length - 10) }}</a>
                    <img src="@/assets/img/svg/doc.svg" alt="" srcset="">
                </v-col>
                <v-col cols="auto">
                    <v-btn @click="$global.CallBackMethod(doc, i, reciveData), renewalContract = true"
                        class=" secondary--text" elevation="0" color="blue12">
                        {{ $t("renewal") }} </v-btn>
                </v-col>
            </v-row>
        </div>

        <!-- No data -->
        <div v-if="!reciveData?.length" class="my-3">
            <img src="@/assets/img/svg_icons/no_data.svg" height="150" class="d-block my-3 mx-auto" alt="kader">
            <div class="text-h6 text-center  gray8--text">{{ $t("there are no data") | capitalize }}</div>
        </div>

        <RenewalDocumentsDialog :openRenewalContract="renewalContract" :pushNewData="pushNewData"
            :closeRenewalContract="() => renewalContract = false" :rowData="rowData" />
        <AnimatedLoading :isLoading="!employeeDocs" />
    </v-container>
</template>

<script>

import AnimatedLoading from "@/components/ui/AnimatedLoading.vue";
import RenewalDocumentsDialog from "./RenewalDocumentsDialog.vue";
export default {
    name: "EmployeesDocuments",
    data: () => ({
        reciveData: null,
        renewalContract: false,
        rowData: null,
        file_name: null,
        global: null,
        update_index: null
    }),

    methods: {
        downloadAttachment(row) {
            let link = row.doc_file;
            if (link.slice(0, 4) === "data") {
                const anchor = document.createElement('a');
                anchor.href = link;
                // const dotIndex = link.lastIndexOf(".");
                // const extension = link.substring(dotIndex - 1 + 1);
                anchor.download = "file"
                anchor.click();
            } else {
                this.$api.DOWNLOAD_METHOD_GET(`download_employee_document/${row.id}`, link).then(() => {
                    // this.printLoading = false;
                })
            }
        },
        handelRenewal(row) {
            this.renewalContract = true
            this.rowData = row
        }
        ,
        pushNewData(data) {
            this.reciveData = data?.data
        }
    },
    props: {
        employeeDocs: { type: Array },
    },
    mounted() {
        console.log("mounted");
        this.reciveData = [...this.employeeDocs]

    },

    components: { AnimatedLoading, RenewalDocumentsDialog }
}
</script>


<style lang="scss" scoped></style>