import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "../store";
import i18n from "./i18n";
let row_data = null;
let row_index = null;
let data_array = null;
export default {
  install(Vue) {
    Vue.prototype.$global = {
      requiredRule: [(v) => !!v || i18n.t("required")],
      notRequiredRule: [],
      requiredNumberRule: [
        (v) =>
          Number(v.replace(/[^0-9.-]+/g, "")) > 0 ||
          i18n.t("please enter valid number"),
      ],
      emailRule: [
        (v) => !!(v || "").match(/@/) || i18n.t("Please enter email address"),
      ],
      requiredNumberAcceptZeroRule: [
        (v) => v.toString().length >= 1 || i18n.t("please enter valid number"),
      ],
      mobileRule: [
        (v) =>
          !!(v || "").match(/^(?:[+0]9)?[0-9]{10,12}$/) ||
          i18n.t("Please enter mobile number"),
      ],
      passwordRule: [
        (v) => !!v || i18n.t("Password is required"),
        (v) =>
          (v && v.length >= 5) ||
          i18n.t("Password must be greater than 5 characters"),
      ],
      percentageRule: [
        (v) =>
          (v.toString().length >= 1 && v <= 100) ||
          i18n.t("percentage not valid"),
      ],
      /////////  Array /////////
      CallBackMethod(data, index, arr) {
        // 1- return data in function if pass data and index 
        if (typeof index === "number" && data) {
          row_data = { ...data }
          row_index = index
          data_array = arr
          return data
        }
        // 2- no augment pass in function
        else if (!data && !index) {
          return row_data
        }
        //3- update array  without response
        else {
          data_array[row_index] = { ...data };
          row_data = null;
          row_index = null;
          data_array = null;
        }
      },
      FilterPermissions(Screen_Code) {
        if (store.state.permissions.length > 0) {
          var find = store.state.permissions.filter(
            (obj) => obj["screen_code"] === Screen_Code
          );
          return find[0];
        }
      },
      CheckAction(Screen_Code, Action) {
        var find = store.state.permissions.filter(
          (obj) =>
            obj["screen_code"] === Screen_Code &&
            (obj["permission"].includes(Action.toString()) ||
              obj["permission"].includes(Number(+Action)))
        );
        return find.length > 0 ? true : false;
      },
      FilterArray(ARRAY, filterBy) {
        return ARRAY.filter((find) => find === filterBy);
      },
      FilterArrayOfObject(ARRAY, SearchIn, filterBy) {
        return ARRAY.filter((obj) => obj[SearchIn] === filterBy);
      },
      FilterArrayOfObjectElement(ARRAY, SearchIn, filterBy, ReturnElement = null) {
        const filter = ARRAY.filter((obj) => obj[SearchIn] === filterBy);
        return filter.length ? filter.at(0)[ReturnElement] : null;
      },
      FilterObjectByArray(OBJECT, ARRAY) {
        return ARRAY.reduce((obj, key) => ({ ...obj, [key]: OBJECT[key] }), {});
      },
      RemoveFromArray(ARRAY, SearchIn, VALUE) {
        const removeIndex = ARRAY.findIndex((find) => find[SearchIn] === VALUE);
        return ARRAY.splice(removeIndex, 1);
      },
      SelectFromArray() {
        let original_array = arguments.length ? arguments[0] : [];
        let required_object =
          arguments.length > 1 ? Object.keys(arguments).slice(1) : [];
        let filtered_array = [];
        original_array.forEach((object) => {
          let new_object = required_object.reduce(
            (prevKey, nextKey) =>
              object[arguments[nextKey]]
                ? {
                  ...prevKey,
                  [arguments[nextKey]]: object[arguments[nextKey]],
                }
                : { ...prevKey },
            {}
          );
          filtered_array.push(new_object);
        });
        return filtered_array;
      },
      SelectAsFromArray(original_array = [], required_object = Object) {
        let filtered_array = [];
        original_array.forEach((object) => {
          let new_object = Object;
          Object.keys(required_object).forEach((key) => {
            if (object[required_object[key]])
              new_object[key] = object[required_object[key]];
          });
          filtered_array.push({ ...new_object });
        });
        return filtered_array || [];
      },
      SelectFromObject() {
        let original_object = arguments.length ? arguments[0] : Object;
        let required_keys =
          arguments.length > 1 ? Object.keys(arguments).slice(1) : [];
        let filtered_object = required_keys.reduce(
          (prevKey, nextKey) =>
            original_object[arguments[nextKey]]
              ? {
                ...prevKey,
                [arguments[nextKey]]: original_object[arguments[nextKey]],
              }
              : { ...prevKey },
          {}
        );
        return filtered_object;
      },
      removeNullKeys(obj) {
        for (let key in obj) {
          if (obj[key] === null) {
            delete obj[key];
          }
        }
        return obj;
      },
      GroupBy(ARRAY, KEY = 'category_name') {
        const groupedData = ARRAY.reduce((groups, Obj) => {
          if (!groups[Obj[KEY]]) {
            groups[Obj[KEY]] = [];
          }
          groups[Obj[KEY]].push(Obj);

          return groups;
        }, {});
        return groupedData
      },
      /////////   Calculate pagenation  /////////
      CalculateNumberOfPages(TOTAL, LIMIT) {
        return Math.ceil(TOTAL / LIMIT);
      },
      // Dates
      GetCurrentDate() {
        var d = new Date(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;
        return [year, month, day].join('-');
      },
      MonthYearArFormat(dateString) {
        // Split the date string into year and month parts
        var dateParts = dateString.split('-');

        // Convert the year part
        var year = parseInt(dateParts[0]);

        // Convert the month part
        var month = parseInt(dateParts[1]) - 1; // Month is zero-based

        // Create a new Date object with the converted year and month
        var date = new Date(year, month);

        // Format the date using toLocaleString with Arabic language
        var formattedDate = date.toLocaleString('ar', { month: 'long', year: 'numeric' });

        // Insert hyphen between year and month
        formattedDate = formattedDate.replace(/\s+/g, '-');

        // Return the formatted date
        return formattedDate;
      },
      ChangeTimeFormate(TIME, FORMAT) {
        const [hours, minutes, seconds] = TIME.split(':');
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(seconds);

        let formattedTime;
        if (FORMAT === 24) {
          formattedTime = date.toLocaleString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
          })
        } else {
          formattedTime = date.toLocaleString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          })
        }

        return formattedTime;
      },
      PaginateArray(ARRAY, PAGE_SIZE, PAGE_NUMBER) {
        return ARRAY.slice((PAGE_NUMBER - 1) * PAGE_SIZE, PAGE_NUMBER * PAGE_SIZE);
      },
      GetFirstDayOfMonth() {
        var d = new Date(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;
        const firstDay = new Date(d.getFullYear(), d.getMonth(), 2);
        return firstDay.toISOString().split('T')[0];
      },
      SubtractDates(FIRST_DATE, SECOND_DATE) {
        const dateOne = new Date(FIRST_DATE);
        const dateTwo = new Date(SECOND_DATE);
        return dateTwo.getDate() - dateOne.getDate();
      },
      SubtractFromDate(DATE, VALUE) {
        const date = new Date(DATE);
        date.setDate(date.getDate() - Number(VALUE));
        return date.toISOString().split('T')[0];
      },
      AddToDate(DATE, VALUE) {
        const date = new Date(DATE);
        date.setDate(date.getDate() + Number(VALUE));
        return date.toISOString().split('T')[0];
      },
      CalculateAge(Birth_Date) {
        const birthdate = new Date(Birth_Date);
        const currentDate = new Date();
        let age = currentDate.getFullYear() - birthdate.getFullYear();
        if (currentDate.getMonth() < birthdate.getMonth() ||
          (currentDate.getMonth() === birthdate.getMonth() && currentDate.getDate() < birthdate.getDate())) {
          age--;
        }
        return age;
      },
      /////////  Numbers /////////
      NumberWithSpaces(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },

      ConvertToPercentage(PartialValue, TotalValue) {
        return ((100 * PartialValue) / TotalValue).toFixed(2);
      },
      ConvertFromPercentage(Percent, Total) {
        return ((Percent / 100) * Total).toFixed(2);
      },

      CalculatePriceTax(Price, Tax) {
        return {
          price: Number(+Price),
          tax: Number(+Tax),
          priceAfterTax: Number(+Price) + Number(+Price) * Number(+Tax),
          pricePercentage: 100 - 100 * Number(+Tax),
          taxPercentage: 100 * Number(+Tax),
        };
      },
      RyalFormatter(NUMBER) {
        var formatter = new Intl.NumberFormat("ar", {
          style: "currency",
          currency: "SAR",
        });

        return formatter.format(NUMBER);
      },
      FILE_TYPE(FILE) {
        // Base64
        console.log(FILE);
        if (String(FILE).substring(0, 5) == "data:") {
          console.log(
            "FILE TYPE =>",
            FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
          );
          switch (FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              return "excel";
            case "application/vnd.ms-excel":
              return "excel";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "word";
            case "application/octet-stream":
              return "rar";
            case "application/x-zip-compressed":
              return "zip";
            case "application/json":
              return "json";
            case "application/pdf":
              return "pdf";
            case "image/png":
              return "png";
            case "image/jpg":
              return "jpg";
            case "image/jpeg":
              return "jpeg";
            case "text/plain":
              return "txt";
            case "image/svg+xml":
              return "svg";
            case "image/gif":
              return "gif";
            case "video/mp4":
              return "mp4";
            case "video/ogg":
              return "mp4";
            case "video/quicktime":
              return "quicktime";
            case "video/mov":
              return "mov";
            default:
              return "notFound";
          }
        }
        // Normal
        else {
          switch (FILE.type) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              return "excel";
            case "application/vnd.ms-excel":
              return "excel";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "word";
            case "application/octet-stream":
              return "rar";
            case "application/x-zip-compressed":
              return "zip";
            case "application/json":
              return "json";
            case "application/pdf":
              return "pdf";
            case "image/png":
              return "png";
            case "image/jpg":
              return "jpg";
            case "image/jpeg":
              return "jpeg";
            case "text/plain":
              return "txt";
            case "image/svg+xml":
              return "svg";
            case "image/gif":
              return "gif";
            case "video/mp4":
              return "mp4";
            case "video/ogg":
              return "mp4";
            case "video/quicktime":
              return "quicktime";
            case "video/mov":
              return "mov";
            default:
              var n = FILE.lastIndexOf(".");
              var result = FILE.substring(n + 1);
              return result;
          }
        }
      },
      Currency(NUMBER) {
        return NUMBER.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      },
      NumberWithCommas(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      IncrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            find[ATTRIBUTE] = find[ATTRIBUTE] + 1;
          }
        });
      },
      DecrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            if (find[ATTRIBUTE] > 1) {
              find[ATTRIBUTE] = find[ATTRIBUTE] - 1;
            }
          }
        });
      },
      DecimalFormatter(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      ///////// Decryption / Encryption /////////
      CRYPT(KEY, TEXT) {
        // 1
        let textEncrypt = Vue.prototype.$CryptoJS.enc.Base64.stringify(
          Vue.prototype.$CryptoJS.enc.Utf8.parse(TEXT)
        );

        return textEncrypt

        // 2
        // var x  = Vue.prototype.$CryptoJS.enc.Hex.parse(KEY);
        // var iv   = Vue.prototype.$CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
        // var encrypted = Vue.prototype.$CryptoJS.AES.encrypt(TEXT, x, {iv: iv});
        // return encrypted.toString();

      },
      DECRYPT(KEY, ENCODED) {
        // 1
        let textDecrypt = Vue.prototype.$CryptoJS.enc.Base64.parse(ENCODED).toString(
          Vue.prototype.$CryptoJS.enc.Utf8
        );
        return textDecrypt
        // 2
        // var x  = Vue.prototype.$CryptoJS.enc.Hex.parse(key);
        // var iv   = Vue.prototype.$CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
        // var decrypted = Vue.prototype.$CryptoJS.AES.decrypt(encryptedString, x,{iv:iv});
        // return decrypted.toString();

      },

      ///////// Alert Toast /////////
      ShowAlert(TYPE, TITLE, TOAST = true, MSG, TIMER = 3000) {
        Swal.fire({
          icon: TYPE,
          title: TITLE,
          text: MSG,
          toast: TOAST,
          timer: TIMER,
          position:
            TOAST == true
              ? store.state.Settings.translation.rtl == true
                ? "top-start"
                : "top-end"
              : "center",
          showConfirmButton: false,
        });
      },
    };
  },
};
